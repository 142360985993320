import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Dialog, FormControlLabel, Switch } from "@mui/material";
import CheckoutForm from "./components/CheckoutForm";
import ContractSummary from "./components/ContractSummary";
import StripeForm from "./components/StripeForm";

import { BrandingHeader, Loader } from "@components";
import { calculateNetPrice, getFormattedDate, numberWithCommas } from "@helpers/utils";
import { useSearchParams } from "react-router-dom";
import {
  getCollaboratorByCode,
  getCollaboratorCodes,
  resetCode,
} from "@store/code/CodeActions";
import { useTheme } from "@mui/styles";
import { getMunicipals, getUser } from "@store/user/UserActions";
import ViewProductPdfsCard from "./components/ViewProductPdfsCard";
import { PRODUCT_PROVIDERS, ValidateRFC } from "@helpers/data";
import LoginCollaborator from "./components/LoginCollaborator";
import PurchaseConditionsForm from "./components/PurchaseConditionsForm";
import { differenceInYears, format } from "date-fns";
import { useIrisEldersExtra } from "@hooks/useIrisEldersExtra";
import { getFilteredProductPrices } from "@store/product/ProductActions";
import { HEALTH_INSURANCE_TITLE, PRODUCT_CURRENCY_DEFAULT, TYPE_CODE } from "@constants/constants";
import usePriceAndAddiontalFeesCalculatorIris from '@hooks/usePriceAndAddiontalFeesCalculatorIris';
import usePriceAndAdditionalFeesCalculator from "@hooks/usePriceAndAddiontalFeesCalculator";

interface CheckoutProps { }

const defaultValues = {
  name: "",
  surname: "",
  secondSurname: "",
  dob: new Date(),
  documentType: "DNI",
  documentNumber: "",
  gender: "",
  address: "",
  postalCode: "",
  city: "",
  email: "",
  phoneNo: "",
  country: "España",
  province: "",
  isInsuredPolicyHolder: false,
  confirmations: {
    acceptDataProtection: false,
    acceptCommercialInfo: false,
    acceptTermConditions: false,
  },
  travellers: [
    {
      name: "",
      surname: "",
      secondSurname: "",
      dob: new Date(),
      documentType: "DNI",
      documentNumber: "",
      gender: "",
    },
  ],
  collaborator: {
    email: "",
    password: "",
  },
  emergencyFirstName: '',
  emergencyLastName: '',
  emergencyPhone: ''
};

const Checkout: React.FC<CheckoutProps> = (props) => {
  const { state }: { state: any } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    type,
    price,
    precio_Neto,
    filter,
    productId,
    productOverCosts,
    productProvider,
    coveragePdfFile,
    emailPdfFiles,
    budgetId,
    irisExtraSelected,
    variantAllowBulkXlsxUpload,
    variantAgeLimit,
    irisExtraAll,
    variants,
    productIdType,
    currency,
    axaId,
    infoTerrawind = null,
    pricesState,
    pricesCurrenciesNetoIris
  }: any = state ?? {};

  useEffect(() => {
    if (filter) {
      if (filter.country) {
        setValue('country', filter.country.label);
      }
    }
  }, [filter]);
  useEffect(() => {
    if (!type || !filter) {
      navigate("/?code=" + searchParams.get("code"));
    }
  }, [navigate, searchParams, type, filter]);

  useEffect(() => {
    if ((irisExtraAll?.length > 0)) {
      dispatch(
        getFilteredProductPrices({
          productId: productIdType,
          productKey: productId ?? null,
          codeKey: codeKey ?? null,
          extras: productOverCosts,
          filter: {
            origin: filter.origin,
            destination: filter.destination,
            departureDate: format(new Date(filter.departureDate), "yyyy-MM-dd"),
            returnDate: format(new Date(filter.returnDate), "yyyy-MM-dd"),
            totalTravellers: filter?.totalTravellers,
          },
          variant: type,
          variants: variants,
          irisExtraSelected: irisExtraSelected,
          irisExtraAll: irisExtraAll ?? [],
          currency: currency.divisa || currency.value
        })
      );
    }
  }, [irisExtraAll]);

  const dispatch = useDispatch();

  const codeKey = searchParams.get("code");
  const userTypeParams = searchParams.get("userType");

  const isLoggedIn = localStorage.getItem("tr_access_token");

  const stripeKey: any = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

  const stripePromise = loadStripe(stripeKey, {
    locale: "es",
  });

  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [validatedPhone, onValidated] = useState(false)
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [takePayment, setTakePayment] = useState(false);

  const [citiesList, setCitiesList] = useState([]);

  const { loading }: any = useSelector<any>(({ Travelfine }) => Travelfine.app);

  const theme: any = useTheme();
  const { code, collaborator }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.code
  );
  const { user, loading: userLoading }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.user
  );

  const { irisExtraAll: irisExtraAllProduct, product }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );

  const isIRISProvider = productProvider === PRODUCT_PROVIDERS.IRIS;
  const isAXAProvider = productProvider === PRODUCT_PROVIDERS.AXA;
  const isTERRAWINDProvider = productProvider === PRODUCT_PROVIDERS.TERRAWIND;
  const isIMAProvider = productProvider === PRODUCT_PROVIDERS.IMA;

  const isCASERProvider = productProvider === PRODUCT_PROVIDERS.CASER;

  const isCollaborator = userTypeParams === "collaborator";

  let percentage = code?.percentage ?? 0;

  if (filter) {
    if (code?.groupType === TYPE_CODE.category) {
      const categoryValues = Array.isArray(code?.groupTypeValue)
        ? code.groupTypeValue
        : [code.groupTypeValue];

      const isCategoryCode = categoryValues.includes(filter?.modality?.value);

      percentage = isCategoryCode ? percentage : 0;
    }
  }

  const {
    irisMaxAge,
    usingIrisEldersExtra
  } = useIrisEldersExtra(irisExtraSelected, irisExtraAllProduct, filter?.departureDate);
  const isExistHealth = (filter?.modality?.label?.toUpperCase()?.includes(HEALTH_INSURANCE_TITLE.toUpperCase()));

  const validateTravellers = yup.object().shape({
    name: yup.string().required("Nombre es obligatorio"),
    surname: yup.string().required("Apellidos obligatorio"),
    travellers: yup.number(),
    address: isExistHealth ? yup.string().required("Dirección es obligatorio") : yup
      .string().nullable().notRequired(),
    postalCode: isExistHealth ? yup.string().required("Codigo postal es obligatorio") : yup
      .string().nullable().notRequired(),
    city: isExistHealth ? yup.string().required("Ciudad es obligatorio") : yup
      .string().nullable().notRequired(),
    email: isExistHealth ? yup
      .string()
      .email("Debe introducir un mail válido")
      .required("Email es obligatorio") : yup
        .string().nullable().notRequired(),
    country: isExistHealth
      ? yup.mixed().when(["isInsuredPolicyHolder"], (isInsuredPolicyHolder) => {
        return yup.mixed().test(
          "invalid-country",
          "Debes elegir España como país",
          function () {
            const { country } = this.parent;
            if (country !== "España") {
              return false;
            }
            return true;
          }
        );
      }) : yup.string().nullable().notRequired(),
    secondSurname: yup.string().when([], {
      is: () => isCASERProvider || isAXAProvider,
      then: yup.string().required("Segundo apellido obligatorio"),
      otherwise: yup.string().nullable(),
    }),
    dob: isIRISProvider
      ? yup
        .date()
        .required("Fecha Nacimiento obligatorio")
        .max(new Date(), "La fecha de nacimiento debe ser en el pasado.")
        .typeError("Fecha Nacimiento obligatorio")
        .test(
          "age",
          `La edad debe ser inferior a ${irisMaxAge} años`,
          function (value: any) {
            const birthDate = new Date(value);
            const age = differenceInYears((usingIrisEldersExtra && filter.departureDate) ? filter?.departureDate : new Date(), birthDate);
            if (irisMaxAge === 70) {
              return age < irisMaxAge;
            } else {
              return age <= irisMaxAge;
            }
          }
        )
      : isAXAProvider ? yup
        .date()
        .required("Fecha Nacimiento obligatorio")
        .max(new Date(), "La fecha de nacimiento debe ser en el pasado.")
        .typeError("Fecha Nacimiento obligatorio")
        .test(
          "age",
          `La edad debe ser inferior a 74 años`,
          function (value: any) {
            const birthDate = new Date(value);
            const age = differenceInYears(new Date(), birthDate);
            return age < 74;
          }
        ) : isTERRAWINDProvider ? yup
          .date()
          .required("Fecha Nacimiento obligatorio")
          .max(new Date(), "La fecha de nacimiento debe ser en el pasado.")
          .typeError("Fecha Nacimiento obligatorio")
          .test(
            "age",
            `La edad debe ser inferior a ${infoTerrawind.maxAge} años`,
            function (value: any) {
              const birthDate = new Date(value);
              const age = differenceInYears(new Date(), birthDate);
              return age <= infoTerrawind.maxAge;
            }
          ) : yup
            .date()
            .required("Fecha Nacimiento obligatorio")
            .max(new Date(), "La fecha de nacimiento debe ser en el pasado.")
            .typeError("Fecha Nacimiento obligatorio"),
    documentType:
      isIMAProvider && isCollaborator
        ? yup.string()
        : yup.string().required("Tipo documento es obligatorio"),
    documentNumber:
      isIMAProvider && isCollaborator
        ? yup.string().when("documentType", (documentType) => {
          if (documentType === "DNI") {
            return yup
              .string()
              .test(
                "empty-check",
                "DNI inválido",
                (documentNumber) =>
                  !documentNumber ||
                  !!documentNumber?.match(/^([0-9]{8}[A-Z]{1})$/)
              );
          } else if (documentType === "INE") {
            return yup.string()
              .matches(/^[A-Z]{6}[0-9]{8}[A-Z0-9]{4}$/, 'Formato de INE inválido')
              .required('El INE es requerido')
          } else if (documentType === "Pasaporte") {
            return yup.string()
              .matches(/^[A-Za-z0-9]+$/, "DNI o pasaporte es obligatorio")
              .min(6, "Debe tener al menos 6 caracteres")
              .required("No debe estar vacío");
          } else if (documentType === "RFC") {
            return yup.string()
              .test('valid-rfc', 'RFC inválido', value => ValidateRFC(value))
              .required('El RFC es requerido');
          }
          return yup.string().required("No debe estar vacío");
        })
        : yup.string().when("documentType", (documentType) => {
          if (documentType === "DNI") {
            return yup
              .string()
              .required("DNI o pasaporte es obligatorio")
              .matches(/^([0-9]{8}[A-Z]{1})$/, "DNI inválido");
          } else if (documentType === "INE") {
            return yup.string()
              .matches(/^[A-Z]{6}[0-9]{8}[A-Z0-9]{4}$/, 'Formato de INE inválido')
              .required('El INE es requerido')
          } else if (documentType === "Pasaporte") {
            return yup.string()
              .matches(/^[A-Za-z0-9]+$/, "DNI o pasaporte es obligatorio")
              .min(6, "Debe tener al menos 6 caracteres")
              .required("No debe estar vacío");
          } else if (documentType === "RFC") {
            return yup.string()
              .test('valid-rfc', 'RFC inválido', value => ValidateRFC(value))
              .required('El RFC es requerido');
          } else if (documentType === "CURP") {
            return yup.string()
              .matches(/^[A-Z]{4}[0-9]{6}[HM]{1}[A-Z]{5}[0-9]{2}$/, "CURP inválido")
              .required("El CURP es obligatorio");
          } else if (documentType === "Cédula") {
            return yup.string()
              .matches(/^[0-9A-Za-z.-]+$/, "Formato de Cédula inválido")
              .min(6, "Debe tener al menos 6 caracteres")
              .required("La Cédula es obligatoria");
          }
          return yup.string().required("No debe estar vacío");
        }),
  });

  useEffect(() => {
    let isNew = true;
    if (code?.code) isNew = !isNew;
    if (codeKey) {
      dispatch(getCollaboratorCodes({ codeKey, modality: filter?.modality?.value ?? null, isNew: isNew }));

    } else {
      dispatch(resetCode({}));
    }
    if (isCollaborator) {
      dispatch(getCollaboratorByCode({ codeKey, modality: filter?.modality?.value ?? null, isNew: isNew }));
    }

  }, [dispatch, isCollaborator, codeKey]);

  useEffect(() => {
    if (isLoggedIn && !isCollaborator) {
      dispatch(getUser());
    }
  }, [dispatch, isLoggedIn, isCollaborator]);

  const schema = yup.object().shape({
    name: yup.string().required("Nombre es obligatorio"),
    surname: yup.string().required("Apellido obligatorio"),
    secondSurname: yup.string().when([], {
      is: () => isCASERProvider || isAXAProvider,
      then: yup.string().required("Segundo apellido obligatorio"),
      otherwise: yup.string().nullable(),
    }),
    dob: isIRISProvider
      ? yup
        .date()
        .required("Fecha Nacimiento obligatorio")
        .when("isInsuredPolicyHolder", {
          is: true,
          then: (schema) =>
            schema
              .test(
                "age",
                `La edad debe ser inferior a ${irisMaxAge} años`,
                function (value: any) {
                  const birthDate = new Date(value);
                  const age = differenceInYears((usingIrisEldersExtra && filter.departureDate) ? filter?.departureDate : new Date(), birthDate);
                  if (irisMaxAge === 70) {
                    return age < irisMaxAge;
                  } else {
                    return age <= irisMaxAge;
                  }
                }
              )
        }
        )
      : isAXAProvider ? yup
        .date()
        .required("Fecha Nacimiento obligatorio")
        .max(new Date(), "La fecha de nacimiento debe ser en el pasado.")
        .typeError("Fecha Nacimiento obligatorio")
        .when("isInsuredPolicyHolder", {
          is: true,
          then: (schema) =>
            schema
              .test(
                "age",
                `La edad debe ser inferior a 74 años`,
                function (value: any) {
                  const birthDate = new Date(value);
                  const age = differenceInYears(new Date(), birthDate);
                  return age < 74;
                }
              )
        }
        ) : isTERRAWINDProvider ? yup
          .date()
          .required("Fecha Nacimiento obligatorio")
          .max(new Date(), "La fecha de nacimiento debe ser en el pasado.")
          .typeError("Fecha Nacimiento obligatorio")
          .when("isInsuredPolicyHolder", {
            is: true,
            then: (schema) =>
              schema
                .test(
                  "age",
                  `La edad debe ser inferior a ${infoTerrawind.maxAge} años`,
                  function (value: any) {
                    const birthDate = new Date(value);
                    const age = differenceInYears(new Date(), birthDate);
                    return age <= infoTerrawind.maxAge;
                  }
                )
          }
          )
        : yup.date().required("Fecha Nacimiento obligatorio"),
    gender: yup.string().when([], () => {
      if (PRODUCT_PROVIDERS.AXA === productProvider) {
        return yup
          .string()
          .required("El genero es obligatorio");
      }
      return yup.string();
    }),
    documentType: yup.string().required("Tipo documento es obligatorio"),
    documentNumber: yup.string().when("documentType", (documentType) => {
      if (documentType === "DNI") {
        return yup
          .string()
          .required("DNI o pasaporte es obligatorio")
          .matches(/^([0-9]{8}[A-Z]{1})$/, "DNI inválido");
      } else if (documentType === "INE") {
        return yup.string()
          .matches(/^[A-Z]{6}[0-9]{8}[A-Z0-9]{4}$/, 'Formato de INE inválido')
          .required('El INE es requerido')
      } else if (documentType === "Pasaporte") {
        return yup.string()
          .matches(/^[A-Za-z0-9]+$/, "DNI o pasaporte es obligatorio")
          .min(6, "Debe tener al menos 6 caracteres")
          .required("No debe estar vacío");
      } else if (documentType === "RFC") {
        return yup.string()
          .test('valid-rfc', 'RFC inválido', value => ValidateRFC(value))
          .required('El RFC es requerido');
      } else if (documentType === "CURP") {
        return yup.string()
          .matches(/^[A-Z]{4}[0-9]{6}[HM]{1}[A-Z]{5}[0-9]{2}$/, "CURP inválido")
          .required("El CURP es obligatorio");
      } else if (documentType === "Cédula") {
        return yup.string()
          .matches(/^[0-9A-Za-z.-]+$/, "Formato de Cédula inválido")
          .min(6, "Debe tener al menos 6 caracteres")
          .required("La Cédula es obligatoria");
      }
      return yup.string().required("No debe estar vacío");
    }),
    address: yup.string().required("Dirección es obligatorio"),
    postalCode: yup.string().required("Codigo postal es obligatorio"),
    city: isIRISProvider
      ? yup.mixed().when("country", (country) => {
        if (country === "España") {
          return yup
            .object()
            .shape({
              label: yup.string().required(),
              value: yup.string().required(),
            })
            .typeError("Ciudad es obligatorio");
        }
        return yup.string().required("Ciudad es obligatorio");
      })
      : yup.string().required("Ciudad es obligatorio"),
    email: yup
      .string()
      .email("Debe introducir un mail válido")
      .required("Email es obligatorio"),
    phoneNo: validatedPhone ?
      yup.string().required("Telefono es obligatorio") :
      yup.string().required("Telefono es obligatorio")
        .test("phoneNo", "Telefono no es valido", () => validatedPhone),
    country: isExistHealth
      ? yup.mixed().when(["isInsuredPolicyHolder"], (isInsuredPolicyHolder, schema) => {
        if (isInsuredPolicyHolder) {
          return yup.mixed().test(
            "invalid-country",
            "Debes elegir España como país",
            function () {
              const { country } = this.parent;
              if (country !== "España") {
                return false;
              }
              return true;
            }
          );
        }
        return yup.string().required("País es obligatoria");
      })
      : yup.string().required("País es obligatoria"),
    province: isIRISProvider
      ? yup.mixed().when("country", (country) => {
        if (country === "España") {
          return yup
            .object()
            .shape({
              label: yup.string().required(),
              value: yup.string().required(),
            })
            .typeError("Provincia es obligatorio");
        }
        return yup.string().required("Provincia es obligatorio");
      })
      : yup.string().required("Provincia es obligatorio"),
    confirmations: yup.object().shape({
      acceptDataProtection: yup.boolean().oneOf([true], "debe ser verificado"),
      acceptTermConditions: yup.boolean().oneOf([true], "debe ser verificado"),
    }),
    travellers: yup.array().of(validateTravellers)
      .test("unique", "No pueden haber documentos repetidos", (travellers, context) => {
        const dniSet = new Set();
        if (!travellers) return true;
        if (travellers) {
          for (let index = 0; index < travellers.length; index++) {
            const traveller = travellers[index];
            if (dniSet.has(traveller.documentNumber)) {
              return context?.createError({
                path: `travellers[${index}].documentNumber`,
                message: "Este documento ya está registrado en otro viajero.",
              });
            }
            dniSet.add(traveller.documentNumber);
          }
        }
        return true;
      }).test("no-duplicate-with-policy-holder", "El Documento del tomador no puede estar en la lista de viajeros", function (travellers, context) {
        const policyHolderDocument = this.parent.documentNumber;
        if (isIRISProvider) {
          if (!travellers) return true;
          for (let index = 0; index < travellers.length; index++) {
            const traveller = travellers[index];
            if (traveller.documentNumber === policyHolderDocument && !isInsuredPolicyHolder) {
              return context?.createError({
                path: `travellers[${index}].documentNumber`,
                message: "El documento del tomador no puede estar en la lista de viajeros.",
              });
            }
          }
        }
        return true;
      }),
    cardHolderName:
      !code || !code.isDeferredPayment
        ? yup.string().required("Nombre es obligatorio")
        : yup.string(),
    collaborator: yup.object().shape({
      email:
        code?.isDeferredPayment && !takePayment
          ? yup
            .string()
            .email("Debe introducir un mail válido")
            .required("Debe introducir un mail")
          : yup.string(),
      password:
        code?.isDeferredPayment && !takePayment
          ? yup
            .string()
            .required("Por favor, introduzca sucontraseña")
            .min(
              6,
              "La contraseña es demasiado corta, debe tener al menos 6 caracteres"
            )
          : yup.string(),
    }),
    emergencyFirstName: isTERRAWINDProvider ? yup
      .string()
      .when(["isInsuredPolicyHolder"], {
        is: true,
        then: yup.string().required("El nombre de emergencia es obligatorio"),
      }) : yup.string(),
    emergencyLastName: isTERRAWINDProvider ? yup
      .string()
      .when("isInsuredPolicyHolder", {
        is: true,
        then: yup.string().required("Los apellidos de emergencia son obligatorios"),
      }) : yup.string(),
    emergencyPhone: isTERRAWINDProvider ? yup
      .string()
      .when("isInsuredPolicyHolder", {
        is: true,
        then: yup
          .string()
          .required("El teléfono de emergencia es obligatorio")
          .matches(/^\+?[0-9]+$/, "Número de teléfono inválido"),
      }) : yup.string(),
  });

  const methods = useForm({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    control,
    handleSubmit,
    formState,
    watch,
    setValue,
    reset,
    getValues,
  } = methods;
  const { dirtyFields, errors, isValid } = formState;

  const isInsuredPolicyHolder = watch("isInsuredPolicyHolder");
  const discountPercentage = percentage;

  const eldersHaveAdditionalFees = variantAgeLimit?.type === "additionalFee";
  const elderAdditionalFeeStartingAge = variantAgeLimit?.age;
  const elderAdditionalFeePercentage = variantAgeLimit?.additionalFeePercentage;
  const priceWithoutAdditionalFees = Number(Number(price).toFixed(2));

  const {
    elderCount,
    additionalFeeDueElders,
    totalPriceBeforeDiscount,
    totalPriceAfterDiscount,
    totalPriceBeforeDiscountOriginal,
    totalPriceAfterDiscountOriginal
  } = usePriceAndAdditionalFeesCalculator({
    discountPercentage,
    eldersHaveAdditionalFees: eldersHaveAdditionalFees,
    isInsuredPolicyHolder,
    priceWithoutAdditionalFees,
    totalTravellers: filter?.totalTravellers,
    watch,
    additionalFeeStartingAge: elderAdditionalFeeStartingAge,
    elderAdditionalFeePercentage: elderAdditionalFeePercentage,
    productPriceOriginal: pricesState?.priceOriginal,
    currencyProductOriginal: pricesState?.currencyOrigin?.value || PRODUCT_CURRENCY_DEFAULT.value
  });

  const {
    elderCount: elderCountIris,
    totalPriceBeforeDiscount: totalPriceBeforeDiscountIris,
    totalPriceAfterDiscount: totalPriceAfterDiscountIris,
    additionalFeeDueElders: additionalFeeDueEldersIris,
    infoExtra,
    sobrePrimaExtraIris,
    priceNetoExtra,
    priceNetoExtraOriginal,
    totalPriceBeforeDiscountOriginal: totalPriceBeforeDiscountIrisOriginal,
    totalPriceAfterDiscountOriginal: totalPriceAfterDiscountIrisOriginal
  } = usePriceAndAddiontalFeesCalculatorIris({
    discountPercentage,
    isInsuredPolicyHolder,
    totalTravellers: filter?.totalTravellers,
    variant: type,
    currency,
    priceWithoutAdditionalFees,
    watch,
    departureDate: filter?.departureDate,
    productPriceOriginal: pricesState?.priceOriginal,
    currencyProductOriginal: pricesState?.currencyOrigin?.value || PRODUCT_CURRENCY_DEFAULT.value
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!type) {
      navigate("/?code=" + searchParams.get("code"));
    }
  }, []);

  useEffect(() => {
    if (!isCollaborator && isLoggedIn && user) {
      const data: any = {
        ...defaultValues,
        name: user.name,
        surname: user.surname,
        secondSurname: user.secondSurname ?? getValues('secondSurname'),
        dob: user.dob,
        documentType: user.documentType,
        documentNumber: user.documentNumber,
        address: user.address,
        email: user.email,
        phoneNo: user.phoneNo,
        country: user.country,
        gender: user.gender || ''
      };

      if (!isIRISProvider) {
        data[`postalCode`] = user.postalCode;
        data[`city`] = user.city;
        data[`province`] = user.province;
      }

      if (
        isIRISProvider &&
        data[`country`] === "España" &&
        user.postalCode.toString().length <= 5
      ) {
        data[`postalCode`] = user.postalCode;

        setCitiesList([]);
        data[`city`] = "";
        data[`province`] = "";
        if (data[`postalCode`].toString().length === 5) {
          dispatch(getMunicipals(data[`postalCode`]));
        }
      }

      reset(data);
    }
  }, [isLoggedIn, user, isIRISProvider, isCollaborator, reset, dispatch]);

  useEffect(() => {
    if (isCollaborator && collaborator?.personalInfo) {
      const data: any = {
        ...defaultValues,
        name: collaborator?.personalInfo?.name,
        surname: collaborator?.personalInfo?.surname,
        dob: collaborator?.createdAt,
        documentType: collaborator?.companyInfo?.documentType,
        documentNumber: collaborator?.companyInfo?.documentNo,
        address: collaborator?.companyInfo?.address,
        email: collaborator?.companyInfo?.email,
        phoneNo: collaborator?.companyInfo?.phoneNo,
        country: collaborator?.companyInfo?.country,
      };

      if (!isIRISProvider) {
        data[`postalCode`] = collaborator?.companyInfo?.postalCode;
        data[`city`] = collaborator?.companyInfo?.population;
        data[`province`] = collaborator?.companyInfo?.province;
      }

      if (
        isIRISProvider &&
        data[`country`] === "España" &&
        collaborator?.companyInfo?.postalCode.toString().length <= 5
      ) {
        data[`postalCode`] = collaborator?.companyInfo?.postalCode;
        setCitiesList([]);
        data[`city`] = "";
        data[`province`] = "";
        if (data[`postalCode`].toString().length === 5) {
          dispatch(getMunicipals(data[`postalCode`]));
        }
      }
      reset(data);
    }
  }, [isCollaborator, collaborator, isIRISProvider, reset, dispatch]);
  return (
    <>
      <div className="flex flex-col overflow-hidden">
        <BrandingHeader />
        <div className="relative flex flex-col lg:flex-row">
          <div
            className="sticky top-0 z-10 flex flex-col w-full px-3 py-3 font-medium cursor-pointer lg:hidden"
            style={{ backgroundColor: theme.palette.secondary.main }}
            onClick={handleOpen}
          >
            <p>{filter?.origin?.value + "-" + filter?.destination?.value}</p>
            <div className="flex items-center justify-between space-x-2">
              <p>
                {getFormattedDate(filter?.departureDate) +
                  "-" +
                  getFormattedDate(filter?.returnDate)}
              </p>
              <p>
                {filter?.totalTravellers}
                <span className="ml-1">
                  <PersonOutlineIcon />
                </span>
              </p>
              {discountPercentage ? (
                <p className="font-bold">
                  <span className="mr-2 line-through">
                    {`${Number.parseFloat(productProvider === PRODUCT_PROVIDERS.IRIS ? totalPriceBeforeDiscountIris.toString() : totalPriceBeforeDiscount.toString()).toFixed(2)} ${currency?.symbol ?? '€'}`}
                  </span>
                  {numberWithCommas(productProvider === PRODUCT_PROVIDERS.IRIS ? totalPriceAfterDiscountIris : totalPriceAfterDiscount)} {currency?.symbol ?? '€'}
                </p>
              ) : (
                <p className="font-bold">
                  {numberWithCommas(productProvider === PRODUCT_PROVIDERS.IRIS ? totalPriceAfterDiscountIris : totalPriceAfterDiscount)} {currency?.symbol ?? '€'}
                </p>
              )}
            </div>
          </div>
          <Loader loading={isProcessing || loading || userLoading} />
          <div className="relative flex flex-col justify-between px-3 py-2 lg:flex-row lg:px-10 xl:px-36 lg:py-6 bg-gray-50">
            <ArrowBackIcon
              className="absolute cursor-pointer left-4 lg:left-8 top-4 lg:-top-0 xl:top-8"
              onClick={() => navigate(-1)}
            />
            <Elements stripe={stripePromise}>
              <FormProvider {...methods}>
                <div className="flex flex-col lg:w-8/12">
                  <CheckoutForm
                    productProvider={productProvider}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    isInsuredPolicyHolder={isInsuredPolicyHolder}
                    totalTravellers={filter?.totalTravellers}
                    watch={watch}
                    getValues={getValues}
                    citiesList={citiesList}
                    setCitiesList={setCitiesList}
                    isCollaborator={isCollaborator}
                    variantAllowBulkXlsxUpload={variantAllowBulkXlsxUpload}
                    validateTravellers={validateTravellers}
                    onValidated={onValidated}
                    filter={filter}
                  />
                  {code?.isDeferredPayment && (
                    <div className="flex justify-center mt-8">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!takePayment}
                            onChange={() => setTakePayment(!takePayment)}
                          />
                        }
                        label="Pago Diferido"
                      />
                    </div>
                  )}
                  <div className="mt-8">
                    {code?.isDeferredPayment && !takePayment ? (
                      <LoginCollaborator errors={errors} control={control} />
                    ) : (
                      <StripeForm errors={errors} control={control} />
                    )}
                  </div>

                  <PurchaseConditionsForm control={control} errors={errors} />
                </div>
              </FormProvider>
              <div className="sticky top-0 mt-6 space-y-4 lg:ml-16 lg:self-start lg:w-4/12">
                <ContractSummary
                  isValid={isValid}
                  dirtyFields={dirtyFields}
                  filter={filter}
                  type={type}
                  price={price}
                  precio_Neto={precio_Neto ? calculateNetPrice(precio_Neto, filter?.totalTravellers, elderCountIris, priceNetoExtra, productProvider, PRODUCT_PROVIDERS, pricesCurrenciesNetoIris, currency) : precio_Neto}
                  productId={productId}
                  productOverCosts={productOverCosts}
                  setProcessingTo={setProcessingTo}
                  setCheckoutError={setCheckoutError}
                  handleSubmit={handleSubmit}
                  checkoutError={checkoutError}
                  takePayment={takePayment}
                  budgetId={budgetId}
                  productProvider={productProvider}
                  irisExtraSelected={irisExtraSelected}
                  watch={watch}
                  isInsuredPolicyHolder={isInsuredPolicyHolder}
                  additionalFeeDueElders={productProvider === PRODUCT_PROVIDERS.IRIS ? additionalFeeDueEldersIris : additionalFeeDueElders}
                  elderCount={productProvider === PRODUCT_PROVIDERS.IRIS ? elderCountIris : elderCount}
                  elderAdditionalFeeStartingAge={
                    elderAdditionalFeeStartingAge
                  }
                  infoExtra={infoExtra ?? null}
                  totalPriceAfterDiscount={productProvider === PRODUCT_PROVIDERS.IRIS ? totalPriceAfterDiscountIris : totalPriceAfterDiscount}
                  totalPriceBeforeDiscount={productProvider === PRODUCT_PROVIDERS.IRIS ? totalPriceBeforeDiscountIris : totalPriceBeforeDiscount}
                  totalPriceBeforeDiscountOriginal={productProvider === PRODUCT_PROVIDERS.IRIS ? totalPriceBeforeDiscountIrisOriginal : totalPriceBeforeDiscountOriginal}
                  totalPriceAfterDiscountOriginal={productProvider === PRODUCT_PROVIDERS.IRIS ? totalPriceAfterDiscountIrisOriginal : totalPriceAfterDiscountOriginal}
                  precio_Neto_Original={priceNetoExtraOriginal ? calculateNetPrice(priceNetoExtraOriginal, filter?.totalTravellers, elderCountIris, priceNetoExtra, productProvider, PRODUCT_PROVIDERS, pricesCurrenciesNetoIris, pricesState?.currencyOrigin?.value) : precio_Neto}
                  sobrePrimaExtraIris={sobrePrimaExtraIris}
                  currency={currency}
                  currencyOriginal={pricesState?.currencyOrigin}
                  axaId={axaId}
                />
                <ViewProductPdfsCard
                  coveragePdfFile={coveragePdfFile}
                  emailPdfFiles={emailPdfFiles}
                />
              </div>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="filter-Dialog"
              >
                <ContractSummary
                  isValid={isValid}
                  dirtyFields={dirtyFields}
                  filter={filter}
                  type={type}
                  price={price}
                  precio_Neto={precio_Neto ? calculateNetPrice(precio_Neto, filter?.totalTravellers, elderCountIris, priceNetoExtra, productProvider, PRODUCT_PROVIDERS, pricesCurrenciesNetoIris, currency) : precio_Neto}
                  productId={productId}
                  productOverCosts={productOverCosts}
                  setProcessingTo={setProcessingTo}
                  setCheckoutError={setCheckoutError}
                  handleSubmit={handleSubmit}
                  checkoutError={checkoutError}
                  isDialog={true}
                  takePayment={takePayment}
                  budgetId={budgetId}
                  productProvider={productProvider}
                  irisExtraSelected={irisExtraSelected}
                  watch={watch}
                  isInsuredPolicyHolder={isInsuredPolicyHolder}
                  additionalFeeDueElders={productProvider === PRODUCT_PROVIDERS.IRIS ? additionalFeeDueEldersIris : additionalFeeDueElders}
                  elderCount={productProvider === PRODUCT_PROVIDERS.IRIS ? elderCountIris : elderCount}
                  elderAdditionalFeeStartingAge={
                    elderAdditionalFeeStartingAge
                  }
                  infoExtra={infoExtra ?? null}
                  totalPriceAfterDiscount={productProvider === PRODUCT_PROVIDERS.IRIS ? totalPriceAfterDiscountIris : totalPriceAfterDiscount}
                  totalPriceBeforeDiscount={productProvider === PRODUCT_PROVIDERS.IRIS ? totalPriceBeforeDiscountIris : totalPriceBeforeDiscount}
                  sobrePrimaExtraIris={sobrePrimaExtraIris}
                  currency={currency}
                  axaId={axaId}
                  totalPriceBeforeDiscountOriginal={productProvider === PRODUCT_PROVIDERS.IRIS ? totalPriceBeforeDiscountIrisOriginal : totalPriceBeforeDiscountOriginal}
                  totalPriceAfterDiscountOriginal={productProvider === PRODUCT_PROVIDERS.IRIS ? totalPriceAfterDiscountIrisOriginal : totalPriceAfterDiscountOriginal}
                  precio_Neto_Original={priceNetoExtraOriginal ? calculateNetPrice(priceNetoExtraOriginal, filter?.totalTravellers, elderCountIris, priceNetoExtra, productProvider, PRODUCT_PROVIDERS, pricesCurrenciesNetoIris, pricesState?.currencyOrigin?.value) : precio_Neto}
                  currencyOriginal={pricesState?.currencyOrigin}
                />
              </Dialog>
            </Elements>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
