import { differenceInYears } from "date-fns";
import { useCallback } from "react";

const usePriceAndAdditionalFeesCalculator = ({
  priceWithoutAdditionalFees,
  totalTravellers,
  watch,
  isInsuredPolicyHolder,
  discountPercentage,
  eldersHaveAdditionalFees,
  elderAdditionalFeePercentage,
  additionalFeeStartingAge,
  productPriceOriginal,
  currencyProductOriginal
}: {
  priceWithoutAdditionalFees: number;
  totalTravellers: number;
  isInsuredPolicyHolder: boolean;
  watch: Function;
  discountPercentage: number;
  eldersHaveAdditionalFees: boolean;
  elderAdditionalFeePercentage: number;
  additionalFeeStartingAge: number;
  productPriceOriginal?: any,
  currencyProductOriginal?: any
}) => {
  const travellers = watch("travellers");
  const policyHolderBirthdate = watch("dob");
  const nowDate = new Date();
  
  const elderCount:any = useCallback(() => getNumberOfElders(), [travellers, policyHolderBirthdate, isInsuredPolicyHolder]); // Dependency array updated for react-hooks/exhaustive-deps

  const pricePerPerson = priceWithoutAdditionalFees / totalTravellers;
  const priceOriginalPerPerson = productPriceOriginal / totalTravellers;

  const additionalFeeDueElders = eldersHaveAdditionalFees ? ((pricePerPerson * elderAdditionalFeePercentage) / 100) * elderCount() : 0;
  const additionalFeeDueEldersOriginal = eldersHaveAdditionalFees ? ((priceOriginalPerPerson * elderAdditionalFeePercentage) / 100) * elderCount() : 0;

  function getNumberOfElders() {
    let output = 0;
    if (isInsuredPolicyHolder) {
      const birthdate = new Date(policyHolderBirthdate);
      if (differenceInYears(nowDate, birthdate) >= additionalFeeStartingAge) {
        output += 1;
      }
    }

    travellers.forEach((traveller: any) => {
      const travellerDate = new Date(traveller.dob);
      if (differenceInYears(nowDate, travellerDate) >= additionalFeeStartingAge) {
        output += 1;
      }
    });

    return output;
  }

  function getTotalPrice(basePrice:any, additionalFees:any) {
    return basePrice + additionalFees;
  }

  const totalPriceBeforeDiscount = Number(getTotalPrice(priceWithoutAdditionalFees, additionalFeeDueElders).toFixed(2));
  const totalPriceBeforeDiscountOriginal = Number(getTotalPrice(productPriceOriginal, additionalFeeDueEldersOriginal).toFixed(2));

  function getFinalPriceAfterDiscount(totalPrice:any, isWithoutDiscount:any = false) {
    if (discountPercentage && !isWithoutDiscount) {
      return totalPrice - (totalPrice * discountPercentage / 100);
    }
    return totalPrice;
  }

  const totalPriceAfterDiscount = Number(getFinalPriceAfterDiscount(totalPriceBeforeDiscount).toFixed(2));
  const totalPriceAfterDiscountOriginal = Number(getFinalPriceAfterDiscount(totalPriceBeforeDiscountOriginal).toFixed(2));

  return {
    elderCount: elderCount(),
    additionalFeeDueElders,
    additionalFeeDueEldersOriginal,
    totalPriceBeforeDiscount,
    totalPriceAfterDiscount,
    totalPriceBeforeDiscountOriginal,
    totalPriceAfterDiscountOriginal
  };
};

export default usePriceAndAdditionalFeesCalculator ;
