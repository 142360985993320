import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, useTheme } from "@mui/material";

interface RedirectModalProps {
  open: boolean;
  onClose: () => void;
  country?: any;
  url?: string;
  setOpenModal?: any;
}

const RedirectModal: React.FC<RedirectModalProps> = ({ open, onClose, country, url, setOpenModal }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          fontSize: "1.5rem",
          color: theme.palette.primary.main,
        }}
      >
        ¡Hola!
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center", padding: "20px" }}>
        {url ? (<Typography>
          Te vamos a redirigir a la web de <b>{country?.label}</b> para proceder con la contratación.
        </Typography>) : (<Typography>
          Para brindarte la mejor experiencia, te mostraremos los productos disponibles para tu país de residencia: <b>{country?.label}</b>.
        </Typography>)}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", paddingBottom: "20px" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.neutral?.contrastText || "white",
            fontWeight: "bold",
            padding: "10px 30px",
            fontSize: "1rem",
            "&:hover": { backgroundColor: theme.palette.secondary.dark || "#e69500" },
          }}
          onClick={() => {
            if (url) {
              window.location.href = url;
            } else {
              setOpenModal(false);
            }
          }}
        >
          ACEPTAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RedirectModal;
