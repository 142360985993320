import { call, put, takeLatest } from "redux-saga/effects";

import { axiosInstance } from "@api/axios";
import {
  getFilteredProductUrl,
  getFilteredProductPricesUrl,
  getFilteredProductPricesExtraUrl,
} from "@api/Endpoint";
import { ProductActionTypes } from "../redux/actionTypes";
import {
  getFilteredProductPricesSuccess,
  getFilteredProductSuccess,
  setProductLoader,
} from "./ProductActions";
import { toast } from "react-toastify";
import { addDays } from "date-fns";

function* getFilteredProductSaga(action: Action) {
  try {
    const requestPayload = action.payload.form;

    const url = getFilteredProductUrl(requestPayload);

    yield put(setProductLoader(true));

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    yield put(setProductLoader(false));

    if (response.status === 200) {
      let payload = response.data.data;
      if (payload?.maximumEndRange) {
        toast.error(
          "Para el periodo seleccionado este producto no tiene cobertura, rogamos contacto con nosotros para darle una solución personalizada. Muchas gracias"
        );
        payload = {
          ...payload,
          newEndDay: addDays(
            new Date(requestPayload.departureDate),
            payload.maximumEndRange
          ),
        };
        action.payload.setValue("returnDate", new Date(payload.newEndDay));
      }

      if (payload?.minEndRange) {
        toast.error(
          "Para el periodo seleccionado, la fecha debe ser al mínimo"
        );
        payload = {
          ...payload,
          newEndDay: addDays(
            new Date(requestPayload.departureDate),
            payload.minEndRange
          ),
        };
        action.payload.setValue("returnDate", new Date(payload.newEndDay));
      }
      yield put(getFilteredProductSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    yield put(setProductLoader(false));
    console.log("err: ", err);
  }
}

function* getFilteredProductPricesSaga(action: Action) {
  try {
    const requestPayload = action.payload;

    const url = getFilteredProductPricesUrl();

    const response: ResponseGenerator = yield call(
      axiosInstance.post,
      url,
      requestPayload
    );

    if (response.status === 200) {
      const payload = response.data.data;
      yield put(getFilteredProductPricesSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    console.log("err: ", err);
  }
}

function* getFilteredProductPricesExtraSaga(action: Action) {
  try {
    const requestPayload = action.payload;

    const url = getFilteredProductPricesExtraUrl();

    const response: ResponseGenerator = yield call(
      axiosInstance.post,
      url,
      requestPayload
    );

    if (response.status === 200) {
      const payload = response.data.data;
      yield put(getFilteredProductPricesSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    console.log("err: ", err);
  }
}

export function* productSagas() {
  yield takeLatest(
    ProductActionTypes.GET_FILTERED_PRODUCT,
    getFilteredProductSaga
  );
  yield takeLatest(
    ProductActionTypes.GET_FILTERED_PRODUCT_PRICES,
    getFilteredProductPricesSaga
  );

  yield takeLatest(
    ProductActionTypes.GET_FILTERED_PRODUCT_PRICES_EXTRA,
    getFilteredProductPricesExtraSaga
  );
}
