import { call, fork, put, takeLatest } from "redux-saga/effects";
import ReactGA from "react-ga4";
import { axiosInstance } from "@api/axios";
import {
  policyUrl,
  getPoliciesUrl,
  policyByIdUrl,
  getGeneratedPolicyPDFUrl,
  // createClientSecretUrl,
  policyByIdUrlSend,
} from "@api/Endpoint";
import { PolicyActionTypes } from "../redux/actionTypes";
import { toast } from "react-toastify";
import {
  getPoliciesSuccess,
  getPolicyByIdSuccess,
  setUpdatePasswordModal,
} from "./PolicyActions";
import { PAYMENT_STATUS } from "@helpers/data";

// interface StripeConfirmResponse {
//   error?: {
//     message: string;
//     type?: string;
//     code?: string;
//   };
//   paymentIntent?: {
//     status: string;
//     id: string;
//   };
// }

//TODO: OLD LOGIC CREATE POLICY 
// function* createPolicy(action: any) {
//   try {
//     const requestPayload = action.payload;
//     const navigate = action.navigate;

//     const {
//       travelInfo,
//       paymentStatus,
//       paymentMethod,
//       productVariant,
//       codeKey,
//       productProvider,
//       productId,
//       userType,
//     } = requestPayload;

//     const url = policyUrl();

//     const response: ResponseGenerator = yield call(
//       axiosInstance.post,
//       url,
//       requestPayload
//     );

//     if (response.status === 200) {
//       const successMessage = response.data.message;
//       const data = response.data.data;
//       if (paymentStatus === PAYMENT_STATUS.ERROR_PAYMENT) {
//         toast.error(PAYMENT_STATUS.ERROR_PAYMENT);
//         throw PAYMENT_STATUS.ERROR_PAYMENT;
//       }
//       if (userType !== "collaborator") {
//         localStorage.setItem("tr_access_token", data.accessToken);
//         localStorage.setItem("userId", data.userId);
//       }
//       if (data.newUser && userType !== "collaborator") {
//         yield put(setUpdatePasswordModal(true));
//       } else if (paymentStatus !== PAYMENT_STATUS.ERROR_PAYMENT) {
//         toast.success(successMessage);

//         let url = "/dashboard/success";

//         if (userType === "collaborator") {
//           url = `${url}/?userType=${userType}&code=${codeKey}`;
//         }
//         navigate(url, {
//           state: {
//             ...requestPayload,
//             travelInfo: {
//               ...travelInfo,
//               paymentMethod,
//               variant: productVariant,
//               code: codeKey,
//               provider: productProvider,
//               productId,
//             },
//           },
//         });
//       }
//     } else {
//       const errorMessage = response.response.data.message;
//       toast.error(errorMessage);
//       console.log("error: ", errorMessage);
//     }
//   } catch (err: any) {
//     console.log("err: ", err.response);
//     const errorMessage = err?.response?.data?.data?.irisApiResponseData
//       ?.Des_Error
//       ? err?.response?.data?.data?.irisApiResponseData?.Des_Error
//       : err?.response?.data?.message;
//     toast.error(errorMessage);
//   }
// }

function* createPolicy(action: any) {
  const requestPayload = action.payload;
  const navigate = action.navigate;
  const formStripe = action.formStripe;

  const {
    travelInfo,
    paymentMethod,
    productVariant,
    codeKey,
    productProvider,
    productId,
    userType,
    totalPrice,
    // code,
    // currency,
    collaboratorCreds,
    stripePId,
    paymentStatus
  } = requestPayload;

  const {
    // takePayment,
    currency,
    // isDeferredPayment,
    // stripe,
    // paymentMethodReq,
    // setCheckoutError,
    setProcessingTo,
    setPaymentStatus,
    setPolicy,
    policy,
  } = formStripe;
  try {

    let url = policyUrl();
    let paymentStatusToSend = paymentStatus;
    // let paymentIntentId;
    // let client_secret;
    let successMessage = 'Poliza creada correctamente';
    let data = policy;
    let policyId = policy ? policy?.policyId : null;

    if (data === null) {
      const response: ResponseGenerator = yield call(
        axiosInstance.post,
        url,
        requestPayload
      );

      if (response.status !== 200) {
        throw new Error(response.response?.data?.message || "Error al crear la póliza");
      }

      successMessage = response.data.message;
      successMessage = response.data.message;
      data = response.data.data;
      policyId = data?.policyId;
    }

    if (!policyId) {
      throw new Error("No se recibió un policyId válido.");
    }

    if (!policy && setPolicy) {
      yield call(setPolicy, data);
    }

    const updatedPolicyData = {
      ...data,
      paymentStatus: paymentStatusToSend,
      stripeId: stripePId,
      collaboratorCreds: collaboratorCreds
    };

    const updatePolicyResponse: ResponseGenerator = yield call(
      axiosInstance.put,
      policyByIdUrl(policyId),
      updatedPolicyData
    );

    let dataUpdatedPolicy = null;
    if (updatePolicyResponse.status === 200) {
      dataUpdatedPolicy = updatePolicyResponse.data.data;
      paymentStatusToSend = dataUpdatedPolicy?.paymentStatus;
    }

    if (paymentStatusToSend === PAYMENT_STATUS.ERROR_PAYMENT) {
      toast.error(PAYMENT_STATUS.ERROR_PAYMENT);
      throw PAYMENT_STATUS.ERROR_PAYMENT;
    }

    if (dataUpdatedPolicy?.paymentStatus === PAYMENT_STATUS.COMPLETED || dataUpdatedPolicy?.paymentStatus === PAYMENT_STATUS.PAYMENT_DEFFERED) {
      const sendEmailToPdfPolicyData = {
        ...data,
        paymentStatus: paymentStatusToSend,
      };

      yield fork(function* () {
        try {
          yield call(axiosInstance.post, policyByIdUrlSend(policyId), sendEmailToPdfPolicyData);
        } catch (err) {
          console.error("Error enviando el correo:", err);
          toast.error("No se pudo enviar el email con la política generada");
        }
      });
    }

    if (userType !== "collaborator") {
      localStorage.setItem("tr_access_token", data.accessToken);
      localStorage.setItem("userId", data.userId);
    }
    if (data.newUser && userType !== "collaborator") {
      if (process.env.REACT_APP_NODE_ENV === "production") {
        ReactGA.event('purchase', {
          transaction_id: stripePId || policyId,
          value: parseFloat(totalPrice),
          payment_type: paymentMethod,
          origin: travelInfo?.origin?.value,
          destiny: travelInfo?.destination?.value,
          departureDate: travelInfo?.departureDate,
          returnDate: travelInfo?.returnDate,
          totalTravellers: travelInfo?.totalTravellers,
          modalidad: travelInfo?.category?.label || travelInfo?.category,
          provider: productProvider,
          price: parseFloat(totalPrice),
          currency: currency?.value || "EUR",
          code: codeKey ?? '',
          items: [
            {
              item_name: productVariant,
              item_id: productId,
              price: parseFloat(totalPrice),
              value: parseFloat(totalPrice),
              currency: currency?.value || "EUR",
              quantity: 1,
            },
          ],
        });
      }
      if (!policy) {
        yield put(setUpdatePasswordModal(true));
      }
    } else if (paymentStatusToSend !== PAYMENT_STATUS.ERROR_PAYMENT) {
      if (process.env.REACT_APP_NODE_ENV === "production") {
        ReactGA.event('purchase', {
          transaction_id: stripePId || policyId,
          value: parseFloat(totalPrice),
          payment_type: paymentMethod,
          origin: travelInfo?.origin?.value,
          destiny: travelInfo?.destination?.value,
          departureDate: travelInfo?.departureDate,
          returnDate: travelInfo?.returnDate,
          totalTravellers: travelInfo?.totalTravellers,
          modalidad: travelInfo?.category?.label || travelInfo?.category,
          provider: productProvider,
          price: parseFloat(totalPrice),
          currency: currency?.value || "EUR",
          code: codeKey ?? '',
          items: [
            {
              item_name: productVariant,
              item_id: productId,
              price: parseFloat(totalPrice),
              value: parseFloat(totalPrice),
              currency: currency?.value || "EUR",
              quantity: 1,
            },
          ],
        });
      }
      toast.success(successMessage);

      let url = "/dashboard/success";
      if (userType === "collaborator") {
        url = `${url}/?userType=${userType}&code=${codeKey}`;
      }
      navigate(url, {
        state: {
          ...requestPayload,
          policyId: policyId ?? null,
          amount: totalPrice,
          currency,
          travelInfo: {
            ...travelInfo,
            paymentMethod,
            variant: productVariant,
            code: codeKey,
            provider: productProvider,
            productId,
          },
        },
      });
    }

  } catch (err: any) {
    console.log("err: ", err.response);
    yield call(setProcessingTo, false);
    yield call(setPaymentStatus, PAYMENT_STATUS.ERROR_PAYMENT);
    const errorMessage = err?.response?.data?.data?.irisApiResponseData
      ?.Des_Error
      ? err?.response?.data?.data?.irisApiResponseData?.Des_Error
      : err?.response?.data?.message;
    toast.error(errorMessage);
  }
}

function* getPoliciesSaga(action: Action) {
  try {
    const requestPayload = action.payload;

    const url = getPoliciesUrl(requestPayload);

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response.status === 200) {
      const payload = response.data.data;
      yield put(getPoliciesSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    console.log("err: ", err);
  }
}

function* getPolicyByIdSaga(action: Action) {
  try {
    const id = action.payload;
    const url = policyByIdUrl(id);

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response.status === 200) {
      const payload = response.data.data;
      yield put(getPolicyByIdSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    console.log("err: ", err);
  }
}

function* getGeneratedPolicyPDFSaga(action: Action) {
  try {
    const id = action.payload;
    const url = getGeneratedPolicyPDFUrl(id);

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response.status === 200) {
      const { url } = response.data.data;

      window.open(url, "_blank");
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err: any) {
    if (err.response.status === 429) {
      const errorMessage = err.response.data;
      console.log("error: ", errorMessage);
      toast.error(errorMessage);
    }
    console.log("err: ", err);
  }
}

export function* policySagas() {
  yield takeLatest(PolicyActionTypes.CREATE_POLICY, createPolicy);
  yield takeLatest(PolicyActionTypes.GET_POLICIES, getPoliciesSaga);
  yield takeLatest(PolicyActionTypes.GET_POLICY_BY_ID, getPolicyByIdSaga);
  yield takeLatest(
    PolicyActionTypes.GET_GENERATED_POLICY_PDF,
    getGeneratedPolicyPDFSaga
  );
}
