import React from "react";
import { Paper } from "@mui/material";
import TravellerForm from "./TravellerForm";

const RenderTravellerFormItem = React.memo(({ index, style, filter }: any) => {
  return (
    <div style={style}>
      <Paper elevation={2} key={index} className="mb-2 rounded-lg">
        <TravellerForm index={index} filter={filter} />
      </Paper>
    </div>
  );
});

export default RenderTravellerFormItem;
