import { PolicyActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: PolicyState = {
  loading: false,
  showPasswordModal: false,
  policies: [],
  totalPolicies: 0,
};

const PolicyReducer = (
  state: PolicyState = INITIAL_STATE,
  action: Action
): PolicyState => {
  switch (action.type) {
    case PolicyActionTypes.SET_POLICY_LOADER:
      return {
        ...state,
        loading: action.payload,
      };

    case PolicyActionTypes.GET_POLICIES:
      if (action.payload.page === 1) {
        return {
          ...state,
          policies: [],
          totalPolicies: 0,
          loading: true,
        };
      }
      return { ...state, loading: true };

    case PolicyActionTypes.GET_POLICIES_SUCCESS:
      if (action.payload.page === 1) {
        return {
          ...state,
          policies: action.payload.policies,
          totalPolicies: action.payload.totalPolicies,
        };
      }
      return {
        ...state,
        policies: [...state.policies, ...action.payload.policies],
        totalPolicies: action.payload.totalPolicies,
      };

    case PolicyActionTypes.GET_POLICY_BY_ID_SUCCESS:
      const { policy } = action.payload;
      return {
        ...state,
        policy,
      };

    case PolicyActionTypes.SET_UPDATE_PASSWORD_MODAL:
      return {
        ...state,
        showPasswordModal: action.payload,
      };

    default:
      return state;
  }
};

export default PolicyReducer;
