import React from "react";
import { Paper } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { Input } from "@components";
import PhoneInputComponent from "@components/PhoneInput";

interface PassengerEmergencyFormProps {
  filter?: any;
}
const PassengerEmergencyForm: React.FC<PassengerEmergencyFormProps> = (props) => {
  const { control, formState, setValue } = useFormContext();
  const { errors } = formState;
  const { filter } = props;
  return (
    <Paper elevation={2} className="px-4 py-4 mt-4 rounded-lg">
      <p className="mb-2 text-lg font-bold">Contacto de emergencia</p>

      <div className="flex flex-wrap items-start space-y-3 lg:space-y-0">
        <div className="w-full p-2 sm:w-1/2 md:w-1/3">
          <Input
            label="Nombre"
            name="emergencyFirstName"
            control={control}
            fullWidth
            error={!!errors?.emergencyFirstName}
            errorMessage={errors?.emergencyFirstName?.message}
            className=""
            size="small"
            required
            labelOutside
          />
        </div>

        <div className="w-full p-2 sm:w-1/2 md:w-1/3">
          <Input
            label="Apellidos"
            name="emergencyLastName"
            control={control}
            fullWidth
            error={!!errors?.emergencyLastName}
            errorMessage={errors?.emergencyLastName?.message}
            className="w-full"
            size="small"
            required
            labelOutside
          />
        </div>

        <div className="w-full p-2 sm:w-1/2 md:w-1/3">
          <Controller
            name="emergencyPhone"
            control={control}
            render={({ field }) => (
              <PhoneInputComponent
                label="Teléfono de emergencia"
                error={!!errors?.emergencyPhone}
                errorMessage={errors?.emergencyPhone?.message}
                required
                {...field}
                setValue={setValue}
                country={filter?.country?.label || 'España'}
                />
            )}
          />
        </div>
      </div>
    </Paper>
  );
};

export default PassengerEmergencyForm;