import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, ExternalLink } from "@components";
import { RequestBudget } from "@components/modals";
import { ShareBudget } from "@components/SharedBugget";
import { getPrice } from "@helpers/utils";
import { Paper, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import { GLOBAL_FINE, PRODUCT_CURRENCY_DEFAULT, TYPE_CODE } from "@constants/constants";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Extra from "./Extra";
import Guarantee from "./Guarantee";
import CurrencySelector from "@components/CurrencySelectorProps";

interface DesktopPlanTableProps {
  product: any;
  buyClickHandler: any;
  totalTravellers: number;
  filter: any;
  pricesState?: any;
  variantParams?: any;

}

const DesktopPlanTable: React.FC<DesktopPlanTableProps> = (props) => {
  const { product, buyClickHandler, totalTravellers, filter, variantParams, pricesState } = props;
  const { prices, loading: productLoading }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );
  const { loading }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.app
  );
  const theme: any = useTheme();
  const { numberOfTravellersPerPrice } = product;
  const { code }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);
  const { badges, currency }: any = code;
  const isExistCode = code?.codeKey?.toUpperCase().includes(GLOBAL_FINE.code?.toUpperCase()) ?? false;
  const colorSelect = isExistCode ? theme.palette.secondary.main : '#fdc413';
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState<any>({});
  const [selectedCurrencies, setSelectedCurrencies] = useState<{ [variant: string]: string }>({});
  const [convertedPrices, setConvertedPrices] = useState<{ [variant: string]: number }>({});
  const [notPrice, seIsNotPrice] = useState(false);

  let percentage = code?.percentage ?? 0;

  if (product) {
    if (code?.groupType === TYPE_CODE.category) {
      const categoryIds = Array.isArray(code?.groupTypeValue)
        ? code.groupTypeValue
        : [code.groupTypeValue];
      const isCategoryCode = product?.category?.some((pr: any) => categoryIds.includes(pr._id));
      percentage = isCategoryCode ? percentage : 0;
    }
  }

  const handleOpen = async (variant: string) => {
    setOpen(true);
    const productId = product?._id;
    const productVariant = variant;

    const price = await getPrice({
      price: prices[variant],
      totalTravellers,
      numberOfTravellersPerPrice,
    });

    setFilterData({
      ...filter,
      productId,
      productVariant,
      price,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setFilterData({});
  };

  const useStyles = makeStyles((theme: any) => ({
    hover: {
      "&:hover": {
        color: theme.palette.secondary.main,
      },
    },
  }));

  const classes = useStyles();

  const handleCurrencyChange = (currency: string, variant: string) => {
    setSelectedCurrencies((prevCurrencies) => ({
      ...prevCurrencies,
      [variant]: currency,
    }));
  };

  const handleBuyClick = (variant: string) => {
    const originalPrices = convertedPrices ? Object.keys(convertedPrices).reduce((acc, key) => {
      const priceWithDiscount = convertedPrices[key];
      acc[key] = percentage > 0
        ? (priceWithDiscount / (1 - percentage / 100)) / totalTravellers
        : (priceWithDiscount / totalTravellers);
      return acc;
    }, {} as Record<string, number>) : Number.parseFloat(pricesState[variant]);
    const price: any = Object.keys(originalPrices)?.length > 0 ? originalPrices : null;

    let currency = pricesState[variant]?.currencyAbout;
    if (selectedCurrencies && Object.keys(selectedCurrencies).length > 0) {
      if (filter.country && selectedCurrencies[variant] === filter.country.currency) {
        currency = filter.country;
      } else {
        currency = (code?.badges?.find((current: any) => current?.value === selectedCurrencies[variant]) || code?.currency);
      }
    }

    const selectedCurrency = {
      label: currency?.divisa ?? currency?.label ?? PRODUCT_CURRENCY_DEFAULT.label,
      rate: currency?.conversionRate ?? currency?.rate ?? PRODUCT_CURRENCY_DEFAULT.rate,
      symbol: currency?.symbol ?? PRODUCT_CURRENCY_DEFAULT.symbol,
      value: currency?.currency
        ?? currency?.value
        ?? product?.variantsCurrencies?.[variant]?.value
        ?? product?.currency?.value
        ?? PRODUCT_CURRENCY_DEFAULT.value
    };

    buyClickHandler(variant, price ? Number.parseFloat(price[variant]) : pricesState[variant].perPersonWithoutDiscount, selectedCurrency, convertedPrices);
  };

  useEffect(() => {
    if (!loading && !productLoading) {

      if (product?.price && product?.variants?.length === 0 ) {
        seIsNotPrice(true);
      } else {
        seIsNotPrice(false);
      }
    }
  }, [loading, product, productLoading, pricesState]);

  if (notPrice) {
    return (
      <Paper elevation={3} className="w-2/3 mx-auto">
        <div className="table-container">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
            <SentimentVeryDissatisfiedIcon className="mx-auto my-2 text-6xl "
              style={{ color: theme.palette.secondary.main }}
            />
            <h2 className="text-2xl font-black">Actualmente no hay precios disponibles.</h2>
            <p className="p-4 mx-auto text-lg text-center">Para el periodo seleccionado este producto no tiene cobertura, rogamos contacto con nosotros para darle una solución personalizada. Muchas gracias</p>
          </div>
        </div>
      </Paper>
    );
  }

  return (
    <>
      {Object.keys(pricesState).length > 0 && (
        <>
          <Paper elevation={3} className="rounded-md">
            <div className="table-container">
              <table className="relative w-full max-w-full border border-collapse border-gray-100 rounded-md">
                <thead className="z-10 bg-white top-32">
                  <tr>
                    <th className="p-2 border rounded-md"></th>
                    {product?.variants?.length > 0 &&
                      product?.variants.map((variant: any) => (
                        <th
                          className={`items-center p-2 bg-opacity-50  border-2 ${variantParams === variant ? 'variant-select bg-opacity-50' : ''}`}
                          key={variant}
                          style={{
                            borderColor: variantParams === variant ? colorSelect : '',
                            backgroundColor: variantParams === variant ? colorSelect : '',
                          }}
                        >
                          <h1 className="text-xl font-bold" style={{
                            color: variantParams === variant ? theme.palette.secondary.contrastText : ''
                          }} >{product?.title}</h1>
                          <h1 className="text-xl font-medium " style={{
                            color: variantParams === variant ? theme.palette.secondary.contrastText : ''
                          }}>
                            {variant}
                          </h1>
                          <h2 className="text-2xl font-black">
                            {pricesState[variant]?.label}
                          </h2>
                          {!!percentage && (
                            <h3 className="text-base font-semibold line-through text-priceThrough">
                              {pricesState[variant]?.labelWithoutPorcentage}
                            </h3>
                          )}
                          {totalTravellers > 1 && (
                            <h3 className="text-sm font-bold">
                              {pricesState[variant]?.perPerson}
                              <span className="ml-1 text-xs font-normal text-gray-500">
                                /
                                {numberOfTravellersPerPrice
                                  ? `${numberOfTravellersPerPrice} persona`
                                  : "persona"}
                              </span>
                            </h3>
                          )}
                          {(badges?.length > 1) && (
                            <CurrencySelector
                              price={pricesState[variant]?.totalPrice}
                              priceLabel={pricesState[variant]?.label}
                              variant={variant}
                              className={'w-20 h-10 text-sm'}
                              currencies={badges}
                              selectedCurrency={selectedCurrencies[variant] || (filter?.country?.currency || currency?.value)}
                              onChange={(newCurrency, variant) => handleCurrencyChange(newCurrency, variant)}
                              setSelectedCurrencies={handleCurrencyChange}
                              setConvertedPrices={setConvertedPrices}
                              filter={filter}
                              currency={pricesState[variant]?.currencyAbout}
                            />
                          )}
                          <Button
                            color="primary"
                            className="mt-2 font-bold cursor-pointer lg:mt-4"
                            onClick={() => handleBuyClick(variant)}
                          >
                            Contratar
                          </Button>
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {product?.guarantees &&
                    product?.guarantees.map((guarantee: any) => (
                      <React.Fragment key={guarantee.title}>
                        <tr key={guarantee.title}>
                          <td className="py-2 bg-gray-50">
                            <p className="ml-4 font-bold sm:text-md">{guarantee.title}</p>
                          </td>
                          {product?.variants?.length ? product?.variants?.map((variant: any) => (
                            <td
                              className={`${variantParams === variant ? 'border-2' : 'border'} text-center border-t-0 border-b-0 bg-gray-50 text-xs font-bold md:text-base sm:text-sm`} key={variant}
                              style={{
                                borderColor: `${variantParams === variant ? colorSelect : ''}`,
                              }}></td>
                          )) : ''}
                        </tr>
                        {guarantee.items.map(
                          (item: any) =>
                            !item?.isHidden?.onWeb && (
                              <Guarantee key={item.id} item={item} />
                            )
                        )}
                      </React.Fragment>
                    ))}
                  {product?.extras?.length > 0 && (
                    <tr>
                      <td className="py-2 border bg-gray-50">
                        <p className="ml-4 font-bold sm:text-md">GARANTIAS OPTATIVAS</p>
                      </td>
                      {product?.variants?.length ? product?.variants?.map((variant: any) => (
                        <td
                          className={` ${variantParams === variant ? 'border-2' : 'border'} py-2 text-center px-2  border-t-0 border-b-0 bg-gray-50`} key={variant}
                          style={{
                            borderColor: `${variantParams === variant ? colorSelect : ''}`,
                          }}></td>
                      )) : ''}
                    </tr>
                  )}
                  {product?.extras &&
                    product?.extras.map((item: any) => (
                      <Extra key={item.id} item={item} />
                    ))}
                  {product?.irisExtras &&
                    product?.irisExtras.filter((extra: any) => extra?.is_hidden ? extra?.is_hidden === false : true).map((item: any, index: number) => (
                      <Tooltip
                        placement="top"
                        title="Tomador del Seguro La persona que celebra el contrato  y que es responsable del pago de la prima."
                      >
                        <Extra key={item.id} item={item} isIRISExtra />
                      </Tooltip>
                    ))}
                  <tr>
                    <td className="py-4 pl-2 border"></td>
                    {product?.variants?.length > 0 &&
                      product?.variants.map((variant: any) => (
                        <td
                          className={` ${variantParams === variant ? 'border-2' : 'border'} border-t-0 py-2 text-center px-2`} key={variant}
                          style={{
                            borderColor: `${variantParams === variant ? colorSelect : ''}`,
                          }}
                        >
                          <div className="flex flex-col items-center">
                            <Button onClick={() => handleOpen(variant)} className="cursor-pointer">
                              Guardar Presupuesto
                            </Button>
                            {product?.coveragePdfFiles &&
                              product?.coveragePdfFiles[variant] && (
                                <ExternalLink
                                  href={product?.coveragePdfFiles[variant]}
                                >
                                  <p
                                    className={`hover:underline font-bold transition duration-300 ease-in-out cursor-pointer mt-2 ${classes.hover}`}
                                  >
                                    Más coberturas
                                  </p>
                                </ExternalLink>
                              )}
                            <ShareBudget
                              className="flex justify-center w-full m-1"
                              data={{
                                ...filter,
                                productId: product?._id,
                                productVariant: variant,
                                productProvider: product?.providers[variant] ?? ''
                              }}
                              productId={product?._id}
                            />
                          </div>
                        </td>
                      ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          <RequestBudget open={open} onClose={handleClose} filter={filterData} />
        </>
      )}
    </>
  );
};

export default DesktopPlanTable;