import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import { getCountryCallingCode, parsePhoneNumberFromString, CountryCode } from "libphonenumber-js";
import { originList, PRODUCT_PROVIDERS, userCountriesListSpanish } from "@helpers/data";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from "@mui/styles";
import { FormHelperText } from "@mui/material";
import countries from "iso-3166-1/dist/iso-3166";

interface PhoneInputProps {
    name: string;
    error?: boolean;
    errorMessage?: string;
    required?: boolean;
    fullWidth?: boolean;
    className?: string;
    label?: string;
    variant?: "outlined" | "standard" | "filled" | undefined;
    size?: "small" | "medium" | undefined;
    disabled?: boolean;
    value?: string;
    onChange?: any;
    country?: string;
    setValue?: any;
    productProvider?: any;
    onValidated?: any;
}

const useStyles: any = makeStyles(() => ({
    root: {
        "& .MuiFilledInput-root": {
            backgroundColor: "white !important",
        },
    },
}));

const PhoneInputComponent: React.FC<PhoneInputProps> = ({
    name,
    error,
    errorMessage,
    required,
    label,
    disabled,
    value = "",
    productProvider,
    onValidated,
    onChange,
    country = "esp",
}) => {
    const [defaultCountry, setDefaultCountry] = useState<CountryCode>("ES");
    const [phone, setPhone] = useState(value || "");
    const [valueChange, setValueChange] = useState(false);

    const classes = useStyles();

    const validatePhoneNumber = (inputValue: string, data: any) => {
        let isValid = true;
        let isCaserValid = true;

        const phoneNumber = parsePhoneNumberFromString(inputValue, data.iso2?.toUpperCase() ?? defaultCountry);

        if (productProvider === PRODUCT_PROVIDERS.CASER) {
            const dataIso = data.iso2?.toUpperCase() ?? defaultCountry;
            isCaserValid = dataIso === userCountriesListSpanish.find((dataCountry) => dataCountry.label.includes('España'))?.isoCode?.toUpperCase();
        }

        if (phoneNumber) {
            isValid = phoneNumber.isValid();
        }

        onValidated && onValidated(isValid && isCaserValid);
        return isValid && isCaserValid;
    };

    const handleOnChange = (value: string, data: any, onChange: any) => {    
        const countryIso2 = data.iso2?.toUpperCase() as CountryCode;
        setDefaultCountry(countryIso2);
        setValueChange(true);
    
        try {
            const countryCode = data?.dialCode
            let strippedValue = value.startsWith(`${countryCode}`)
                ? value.replace(`${countryCode}`, "").trim()
                : value;
    
            validatePhoneNumber(strippedValue, data);
            setPhone(value);
            onChange(strippedValue);
        } catch (error) {
            console.error("Error obteniendo código de país", error, countryIso2);
        }
    };
    

    useEffect(() => {
        if (!value) return; 

        const countryCode = countries.find(c =>
            c.country?.toUpperCase() === (country?.toUpperCase() === 'ESPAÑA' ? 'SPAIN' : country?.toUpperCase())
        )?.alpha2;

        if (!countryCode) return;

        const dialCode = getCountryCallingCode(countryCode as CountryCode);
        const hasDialCode = value.startsWith(`+${dialCode}`);

        if (!valueChange && !hasDialCode && value?.length !== 0) {
            setPhone(`+${dialCode}${value}`);
        }
    }, [country, value, defaultCountry]);

    return (
        <div className={`${classes.root} flex flex-col w-full`}>
            {label && (
                <label className="text-sm font-medium whitespace-nowrap">
                    {label}
                    {required && "*"}
                </label>
            )}
            <PhoneInput
                value={phone}
                country={originList.find(count => count?.label.includes(country))?.code?.toLowerCase() ?? defaultCountry?.toLowerCase()}
                localization={es}
                isValid={validatePhoneNumber}
                onChange={(value: string, data: any) => handleOnChange(value, data, onChange)}
                inputProps={{
                    name,
                    required,
                    disabled,
                    autoFocus: false,
                }}
                inputStyle={{
                    borderRadius: "4px",
                    width: "100%",
                    height: "40px",
                }}
                enableSearch
            />
            {error && <FormHelperText error>{errorMessage}</FormHelperText>}
        </div>
    );
};

export default PhoneInputComponent;
