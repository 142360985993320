import { ANNUAL_MEX, HEALTH_INSURANCE_CODE, HEALTH_INSURANCE_MJ_CODE, HEALTH_INSURANCE_TITLE, SINGLE_TRIP_MEX, STUDENT_MEX } from "@constants/constants";

export const travellerList = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "6",
    value: 6,
  },
  {
    label: "7",
    value: 7,
  },
  {
    label: "8",
    value: 8,
  },
  {
    label: "9",
    value: 9,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "11",
    value: 11,
  },
  {
    label: "12",
    value: 12,
  },
  {
    label: "13",
    value: 13,
  },
  {
    label: "14",
    value: 14,
  },
  {
    label: "15",
    value: 15,
  },
  {
    label: "16",
    value: 16,
  },
  {
    label: "17",
    value: 17,
  },
  {
    label: "18",
    value: 18,
  },
  {
    label: "19",
    value: 19,
  },
  {
    label: "20",
    value: 20,
  },
  {
    label: "21",
    value: 21,
  },
  {
    label: "22",
    value: 22,
  },
  {
    label: "23",
    value: 23,
  },
  {
    label: "24",
    value: 24,
  },
  {
    label: "25",
    value: 25,
  },
  {
    label: "26",
    value: 26,
  },
  {
    label: "27",
    value: 27,
  },
  {
    label: "28",
    value: 28,
  },
  {
    label: "29",
    value: 29,
  },
  {
    label: "30",
    value: 30,
  },
  {
    label: "31",
    value: 31,
  },
  {
    label: "32",
    value: 32,
  },
  {
    label: "33",
    value: 33,
  },
  {
    label: "34",
    value: 34,
  },
  {
    label: "35",
    value: 35,
  },
  {
    label: "36",
    value: 36,
  },
  {
    label: "37",
    value: 37,
  },
  {
    label: "38",
    value: 38,
  },
  {
    label: "39",
    value: 39,
  },
  {
    label: "40",
    value: 40,
  },
  {
    label: "41",
    value: 41,
  },
  {
    label: "42",
    value: 42,
  },
  {
    label: "43",
    value: 43,
  },
  {
    label: "44",
    value: 44,
  },
  {
    label: "45",
    value: 45,
  },
  {
    label: "46",
    value: 46,
  },
  {
    label: "47",
    value: 47,
  },
  {
    label: "48",
    value: 48,
  },
  {
    label: "49",
    value: 49,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "51",
    value: 51,
  },
  {
    label: "52",
    value: 52,
  },
  {
    label: "53",
    value: 53,
  },
  {
    label: "54",
    value: 54,
  },
  {
    label: "55",
    value: 55,
  },
  {
    label: "56",
    value: 56,
  },
  {
    label: "57",
    value: 57,
  },
  {
    label: "58",
    value: 58,
  },
  {
    label: "59",
    value: 59,
  },
  {
    label: "60",
    value: 60,
  },
  {
    label: "61",
    value: 61,
  },
  {
    label: "62",
    value: 62,
  },
  {
    label: "63",
    value: 63,
  },
  {
    label: "64",
    value: 64,
  },
  {
    label: "65",
    value: 65,
  },
  {
    label: "66",
    value: 66,
  },
  {
    label: "67",
    value: 67,
  },
  {
    label: "68",
    value: 68,
  },
  {
    label: "69",
    value: 69,
  },
  {
    label: "70",
    value: 70,
  },
  {
    label: "71",
    value: 71,
  },
  {
    label: "72",
    value: 72,
  },
  {
    label: "73",
    value: 73,
  },
  {
    label: "74",
    value: 74,
  },
  {
    label: "75",
    value: 75,
  },
  {
    label: "76",
    value: 76,
  },
  {
    label: "77",
    value: 77,
  },
  {
    label: "78",
    value: 78,
  },
  {
    label: "79",
    value: 79,
  },
  {
    label: "80",
    value: 80,
  },
  {
    label: "81",
    value: 81,
  },
  {
    label: "82",
    value: 82,
  },
  {
    label: "83",
    value: 83,
  },
  {
    label: "84",
    value: 84,
  },
  {
    label: "85",
    value: 85,
  },
  {
    label: "86",
    value: 86,
  },
  {
    label: "87",
    value: 87,
  },
  {
    label: "88",
    value: 88,
  },
  {
    label: "89",
    value: 89,
  },
  {
    label: "90",
    value: 90,
  },
  {
    label: "91",
    value: 91,
  },
  {
    label: "92",
    value: 92,
  },
  {
    label: "93",
    value: 93,
  },
  {
    label: "94",
    value: 94,
  },
  {
    label: "95",
    value: 95,
  },
  {
    label: "96",
    value: 96,
  },
  {
    label: "97",
    value: 97,
  },
  {
    label: "98",
    value: 98,
  },
  {
    label: "99",
    value: 99,
  },
  {
    label: "100",
    value: 100,
  },
  {
    label: "101",
    value: 101,
  },
  {
    label: "102",
    value: 102,
  },
  {
    label: "103",
    value: 103,
  },
  {
    label: "104",
    value: 104,
  },
  {
    label: "105",
    value: 105,
  },
  {
    label: "106",
    value: 106,
  },
  {
    label: "107",
    value: 107,
  },
  {
    label: "108",
    value: 108,
  },
  {
    label: "109",
    value: 109,
  },
  {
    label: "110",
    value: 110,
  },
  {
    label: "111",
    value: 111,
  },
  {
    label: "112",
    value: 112,
  },
  {
    label: "113",
    value: 113,
  },
  {
    label: "114",
    value: 114,
  },
  {
    label: "115",
    value: 115,
  },
  {
    label: "116",
    value: 116,
  },
  {
    label: "117",
    value: 117,
  },
  {
    label: "118",
    value: 118,
  },
  {
    label: "119",
    value: 119,
  },
  {
    label: "120",
    value: 120,
  },
  {
    label: "121",
    value: 121,
  },
  {
    label: "122",
    value: 122,
  },
  {
    label: "123",
    value: 123,
  },
  {
    label: "124",
    value: 124,
  },
  {
    label: "125",
    value: 125,
  },
  {
    label: "126",
    value: 126,
  },
  {
    label: "127",
    value: 127,
  },
  {
    label: "128",
    value: 128,
  },
  {
    label: "129",
    value: 129,
  },
  {
    label: "130",
    value: 130,
  },
  {
    label: "131",
    value: 131,
  },
  {
    label: "132",
    value: 132,
  },
  {
    label: "133",
    value: 133,
  },
  {
    label: "134",
    value: 134,
  },
  {
    label: "135",
    value: 135,
  },
  {
    label: "136",
    value: 136,
  },
  {
    label: "137",
    value: 137,
  },
  {
    label: "138",
    value: 138,
  },
  {
    label: "139",
    value: 139,
  },
  {
    label: "140",
    value: 140,
  },
  {
    label: "141",
    value: 141,
  },
  {
    label: "142",
    value: 142,
  },
  {
    label: "143",
    value: 143,
  },
  {
    label: "144",
    value: 144,
  },
  {
    label: "145",
    value: 145,
  },
  {
    label: "146",
    value: 146,
  },
  {
    label: "147",
    value: 147,
  },
  {
    label: "148",
    value: 148,
  },
  {
    label: "149",
    value: 149,
  },
  {
    label: "150",
    value: 150,
  },
  {
    label: "151",
    value: 151,
  },
  {
    label: "152",
    value: 152,
  },
  {
    label: "153",
    value: 153,
  },
  {
    label: "154",
    value: 154,
  },
  {
    label: "155",
    value: 155,
  },
  {
    label: "156",
    value: 156,
  },
  {
    label: "157",
    value: 157,
  },
  {
    label: "158",
    value: 158,
  },
  {
    label: "159",
    value: 159,
  },
  {
    label: "160",
    value: 160,
  },
  {
    label: "161",
    value: 161,
  },
  {
    label: "162",
    value: 162,
  },
  {
    label: "163",
    value: 163,
  },
  {
    label: "164",
    value: 164,
  },
  {
    label: "165",
    value: 165,
  },
  {
    label: "166",
    value: 166,
  },
  {
    label: "167",
    value: 167,
  },
  {
    label: "168",
    value: 168,
  },
  {
    label: "169",
    value: 169,
  },
  {
    label: "170",
    value: 170,
  },
  {
    label: "171",
    value: 171,
  },
  {
    label: "172",
    value: 172,
  },
  {
    label: "173",
    value: 173,
  },
  {
    label: "174",
    value: 174,
  },
  {
    label: "175",
    value: 175,
  },
  {
    label: "176",
    value: 176,
  },
  {
    label: "177",
    value: 177,
  },
  {
    label: "178",
    value: 178,
  },
  {
    label: "179",
    value: 179,
  },
  {
    label: "180",
    value: 180,
  },
  {
    label: "181",
    value: 181,
  },
  {
    label: "182",
    value: 182,
  },
  {
    label: "183",
    value: 183,
  },
  {
    label: "184",
    value: 184,
  },
  {
    label: "185",
    value: 185,
  },
  {
    label: "186",
    value: 186,
  },
  {
    label: "187",
    value: 187,
  },
  {
    label: "188",
    value: 188,
  },
  {
    label: "189",
    value: 189,
  },
  {
    label: "190",
    value: 190,
  },
  {
    label: "191",
    value: 191,
  },
  {
    label: "192",
    value: 192,
  },
  {
    label: "193",
    value: 193,
  },
  {
    label: "194",
    value: 194,
  },
  {
    label: "195",
    value: 195,
  },
  {
    label: "196",
    value: 196,
  },
  {
    label: "197",
    value: 197,
  },
  {
    label: "198",
    value: 198,
  },
  {
    label: "199",
    value: 199,
  },
  {
    label: "200",
    value: 200,
  },
];

export const modalityList = [
  {
    label: "Plus",
    value: "Plus",
  },
  {
    label: "Premium",
    value: "Premium",
  },
  {
    label: "Excelent",
    value: "Excelent",
  },
  {
    label: "Vip",
    value: "Vip",
  },
];

export const PRODUCT_PROVIDERS = {
  IMA: "IMA",
  IRIS: "IRIS GLOBAL",
  AIG: "AIG",
  CASER: "CASER",
  ADESLAS: "ADESLAS",
  ASISA: "ASISA",
  ASSISTNED: "ASSISTNED",
  ARAG: "ARAG",
  AXA: "AXA",
  TERRAWIND: "TERRAWIND"
};

export const originList = [
  {
    label: "España",
    value: "España y Andorra",
    region: "europa",
    code: "ES",
    groupTitle: "Ámbito Global",
  },
  {
    label: "Europa",
    value: "Europa",
    region: "europa",
    code: "EU",
    groupTitle: "Ámbito Global",
  },
  {
    label: "Mundo",
    value: "Mundo",
    code: "WR",
    groupTitle: "Ámbito Global",
  },
  {
    label: "Afganistán",
    value: "Afganistán",
    code: "AF",
    groupTitle: "Todos los países",
  },
  {
    label: "Albania",
    value: "Albania",
    region: "europa",
    code: "AL",
    groupTitle: "Todos los países",
  },
  {
    label: "Alemania",
    value: "Alemania",
    region: "europa",
    code: "DE",
    groupTitle: "Todos los países",
  },
  {
    label: "Andorra",
    value: "Andorra",
    region: "europa",
    code: "AD",
    groupTitle: "Todos los países",
  },
  {
    label: "Angola",
    value: "Angola",
    code: "AO",
    groupTitle: "Todos los países",
  },
  {
    label: "Anguila",
    value: "Anguila",
    code: "AI",
    groupTitle: "Todos los países",
  },
  {
    label: "Antigua y Barbuda",
    value: "Antigua y Barbuda",
    code: "AG",
    groupTitle: "Todos los países",
  },
  {
    label: "Antártida",
    value: "Antártida",
    code: "AQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Arabia Saudita",
    value: "Arabia Saudita",
    code: "SA",
    groupTitle: "Todos los países",
  },
  {
    label: "Argelia",
    value: "Argelia",
    region: "europa",
    code: "DZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Argentina",
    value: "Argentina",
    code: "AR",
    groupTitle: "Todos los países",
  },
  {
    label: "Armenia",
    value: "Armenia",
    code: "AM",
    groupTitle: "Todos los países",
  },
  {
    label: "Aruba",
    value: "Aruba",
    code: "AW",
    groupTitle: "Todos los países",
  },
  {
    label: "Australia",
    value: "Australia",
    code: "AU",
    groupTitle: "Todos los países",
  },
  {
    label: "Austria",
    value: "Austria",
    region: "europa",
    code: "AT",
    groupTitle: "Todos los países",
  },
  {
    label: "Azerbaiyán",
    value: "Azerbaiyán",
    code: "AZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Bahamas",
    value: "Bahamas",
    code: "BS",
    groupTitle: "Todos los países",
  },
  {
    label: "Bahrein",
    value: "Bahrein",
    code: "BH",
    groupTitle: "Todos los países",
  },
  {
    label: "Bangladesh",
    value: "Bangladesh",
    code: "BD",
    groupTitle: "Todos los países",
  },
  {
    label: "Barbados",
    value: "Barbados",
    code: "BB",
    groupTitle: "Todos los países",
  },
  {
    label: "Belice",
    value: "Belice",
    code: "BZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Benín",
    value: "Benín",
    code: "BJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Bhután",
    value: "Bhután",
    code: "BT",
    groupTitle: "Todos los países",
  },
  {
    label: "Bielorrusia",
    value: "Bielorrusia",
    region: "europa",
    code: "BY",
    groupTitle: "Todos los países",
  },
  {
    label: "Birmania",
    value: "Birmania",
    code: "MM",
    groupTitle: "Todos los países",
  },
  {
    label: "Bolivia",
    value: "Bolivia",
    code: "BO",
    groupTitle: "Todos los países",
  },
  {
    label: "Bonaire, San Eustaquio y Saba",
    value: "Bonaire, San Eustaquio y Saba",
    code: "BQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Bosnia y Herzegovina",
    value: "Bosnia y Herzegovina",
    region: "europa",
    code: "BA",
    groupTitle: "Todos los países",
  },
  {
    label: "Botsuana",
    value: "Botsuana",
    code: "BW",
    groupTitle: "Todos los países",
  },
  {
    label: "Brasil",
    value: "Brasil",
    code: "BR",
    groupTitle: "Todos los países",
  },
  {
    label: "Brunéi",
    value: "Brunéi",
    code: "BN",
    groupTitle: "Todos los países",
  },
  {
    label: "Bulgaria",
    value: "Bulgaria",
    region: "europa",
    code: "BG",
    groupTitle: "Todos los países",
  },
  {
    label: "Burkina Faso",
    value: "Burkina Faso",
    code: "BF",
    groupTitle: "Todos los países",
  },
  {
    label: "Burundi",
    value: "Burundi",
    code: "BI",
    groupTitle: "Todos los países",
  },
  {
    label: "Bélgica",
    value: "Bélgica",
    region: "europa",
    code: "BE",
    groupTitle: "Todos los países",
  },
  {
    label: "Cabo Verde",
    value: "Cabo Verde",
    code: "CV",
    groupTitle: "Todos los países",
  },
  {
    label: "Camboya",
    value: "Camboya",
    code: "KH",
    groupTitle: "Todos los países",
  },
  {
    label: "Camerún",
    value: "Camerún",
    code: "CM",
    groupTitle: "Todos los países",
  },
  {
    label: "Canadá",
    value: "Canadá",
    code: "CA",
    groupTitle: "Todos los países",
  },
  {
    label: "Ceuta y Melilla",
    value: "Ceuta y Melilla",
    code: "EA",
    groupTitle: "Todos los países",
  },
  {
    label: "Chad",
    value: "Chad",
    code: "TD",
    groupTitle: "Todos los países",
  },
  {
    label: "Chile",
    value: "Chile",
    code: "CL",
    groupTitle: "Todos los países",
  },
  {
    label: "China",
    value: "China",
    code: "CN",
    groupTitle: "Todos los países",
  },
  {
    label: "Chipre",
    value: "Chipre",
    code: "CY",
    groupTitle: "Todos los países",
  },
  {
    label: "Ciudad del Vaticano",
    value: "Ciudad del Vaticano",
    code: "VA",
    groupTitle: "Todos los países",
  },
  {
    label: "Colombia",
    value: "Colombia",
    code: "CO",
    groupTitle: "Todos los países",
  },
  {
    label: "Comoras",
    value: "Comoras",
    code: "KM",
    groupTitle: "Todos los países",
  },
  {
    label: "Corea del Norte",
    value: "Corea del Norte",
    code: "KP",
    groupTitle: "Todos los países",
  },
  {
    label: "Corea del Sur",
    value: "Corea del Sur",
    code: "KR",
    groupTitle: "Todos los países",
  },
  {
    label: "Costa de Marfil",
    value: "Costa de Marfil",
    code: "CI",
    groupTitle: "Todos los países",
  },
  {
    label: "Costa Rica",
    value: "Costa Rica",
    code: "CR",
    groupTitle: "Todos los países",
  },
  {
    label: "Croacia",
    value: "Croacia",
    region: "europa",
    code: "HR",
    groupTitle: "Todos los países",
  },
  {
    label: "Cuba",
    value: "Cuba",
    code: "CU",
    groupTitle: "Todos los países",
  },
  {
    label: "Curazao",
    value: "Curazao",
    code: "CW",
    groupTitle: "Todos los países",
  },
  {
    label: "Diego García",
    value: "Diego García",
    code: "DG",
    groupTitle: "Todos los países",
  },
  {
    label: "Dinamarca",
    value: "Dinamarca",
    region: "europa",
    code: "DK",
    groupTitle: "Todos los países",
  },
  {
    label: "Dominica",
    value: "Dominica",
    code: "DM",
    groupTitle: "Todos los países",
  },
  {
    label: "Ecuador",
    value: "Ecuador",
    code: "EC",
    groupTitle: "Todos los países",
  },
  {
    label: "Egipto",
    value: "Egipto",
    region: "europa",
    code: "EG",
    groupTitle: "Todos los países",
  },
  {
    label: "El Salvador",
    value: "El Salvador",
    code: "SV",
    groupTitle: "Todos los países",
  },
  {
    label: "Emiratos Árabes Unidos",
    value: "Emiratos Árabes Unidos",
    code: "AE",
    groupTitle: "Todos los países",
  },
  {
    label: "Eritrea",
    value: "Eritrea",
    code: "ER",
    groupTitle: "Todos los países",
  },
  {
    label: "Eslovaquia",
    value: "Eslovaquia",
    region: "europa",
    code: "SK",
    groupTitle: "Todos los países",
  },
  {
    label: "Eslovenia",
    value: "Eslovenia",
    region: "europa",
    code: "SI",
    groupTitle: "Todos los países",
  },
  {
    label: "Estados Unidos de América",
    value: "Estados Unidos de América",
    code: "US",
    groupTitle: "Todos los países",
  },
  {
    label: "Estonia",
    value: "Estonia",
    region: "europa",
    code: "EE",
    groupTitle: "Todos los países",
  },
  {
    label: "Etiopía",
    value: "Etiopía",
    code: "ET",
    groupTitle: "Todos los países",
  },
  {
    label: "Filipinas",
    value: "Filipinas",
    code: "PH",
    groupTitle: "Todos los países",
  },
  {
    label: "Finlandia",
    value: "Finlandia",
    region: "europa",
    code: "FI",
    groupTitle: "Todos los países",
  },
  {
    label: "Fiyi",
    value: "Fiyi",
    code: "FJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Francia",
    value: "Francia",
    region: "europa",
    code: "FR",
    groupTitle: "Todos los países",
  },
  {
    label: "Gabón",
    value: "Gabón",
    code: "GA",
    groupTitle: "Todos los países",
  },
  {
    label: "Gambia",
    value: "Gambia",
    code: "GM",
    groupTitle: "Todos los países",
  },
  {
    label: "Georgia",
    value: "Georgia",
    code: "GE",
    groupTitle: "Todos los países",
  },
  {
    label: "Ghana",
    value: "Ghana",
    code: "GH",
    groupTitle: "Todos los países",
  },
  {
    label: "Gibraltar",
    value: "Gibraltar",
    region: "europa",
    code: "GI",
    groupTitle: "Todos los países",
  },
  {
    label: "Granada",
    value: "Granada",
    code: "GD",
    groupTitle: "Todos los países",
  },
  {
    label: "Grecia",
    value: "Grecia",
    region: "europa",
    code: "GR",
    groupTitle: "Todos los países",
  },
  {
    label: "Groenlandia",
    value: "Groenlandia",
    code: "GL",
    groupTitle: "Todos los países",
  },
  {
    label: "Guadalupe",
    value: "Guadalupe",
    code: "GP",
    groupTitle: "Todos los países",
  },
  {
    label: "Guam",
    value: "Guam",
    code: "GU",
    groupTitle: "Todos los países",
  },
  {
    label: "Guatemala",
    value: "Guatemala",
    code: "GT",
    groupTitle: "Todos los países",
  },
  {
    label: "Guayana Francesa",
    value: "Guayana Francesa",
    code: "GF",
    groupTitle: "Todos los países",
  },
  {
    label: "Guernsey",
    value: "Guernsey",
    code: "GG",
    groupTitle: "Todos los países",
  },
  {
    label: "Guinea",
    value: "Guinea",
    code: "GN",
    groupTitle: "Todos los países",
  },
  {
    label: "Guinea Ecuatorial",
    value: "Guinea Ecuatorial",
    code: "GQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Guinea-Bissau",
    value: "Guinea-Bissau",
    code: "GW",
    groupTitle: "Todos los países",
  },
  {
    label: "Guyana",
    value: "Guyana",
    code: "GY",
    groupTitle: "Todos los países",
  },
  {
    label: "Haití",
    value: "Haití",
    code: "HT",
    groupTitle: "Todos los países",
  },
  {
    label: "Honduras",
    value: "Honduras",
    code: "HN",
    groupTitle: "Todos los países",
  },
  {
    label: "Hong kong",
    value: "Hong kong",
    code: "HK",
    groupTitle: "Todos los países",
  },
  {
    label: "Hungría",
    value: "Hungría",
    region: "europa",
    code: "HU",
    groupTitle: "Todos los países",
  },
  {
    label: "India",
    value: "India",
    code: "IN",
    groupTitle: "Todos los países",
  },
  {
    label: "Indonesia",
    value: "Indonesia",
    code: "ID",
    groupTitle: "Todos los países",
  },
  {
    label: "Irak",
    value: "Irak",
    code: "IQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Irlanda",
    value: "Irlanda",
    region: "europa",
    code: "IE",
    groupTitle: "Todos los países",
  },
  {
    label: "Irán",
    value: "Irán",
    code: "IR",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Ascensión",
    value: "Isla Ascensión",
    code: "AC",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Bouvet",
    value: "Isla Bouvet",
    code: "BV",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Clipperton",
    value: "Isla Clipperton",
    code: "CP",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla de Man",
    value: "Isla de Man",
    code: "IM",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla de Navidad",
    value: "Isla de Navidad",
    code: "CX",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Norfolk",
    value: "Isla Norfolk",
    code: "NF",
    groupTitle: "Todos los países",
  },
  {
    label: "Islandia",
    value: "Islandia",
    region: "europa",
    code: "IS",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Bermudas",
    value: "Islas Bermudas",
    code: "BM",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Caimán",
    value: "Islas Caimán",
    code: "KY",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Canarias",
    value: "Islas Canarias",
    code: "IC",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Cocos (Keeling)",
    value: "Islas Cocos (Keeling)",
    code: "CC",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Cook",
    value: "Islas Cook",
    code: "CK",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas de Åland",
    value: "Islas de Åland",
    code: "AX",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Feroe",
    value: "Islas Feroe",
    code: "FO",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Georgias del Sur y Sandwich del Sur",
    value: "Islas Georgias del Sur y Sandwich del Sur",
    code: "GS",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Heard y McDonald",
    value: "Islas Heard y McDonald",
    code: "HM",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Maldivas",
    value: "Islas Maldivas",
    code: "MV",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Malvinas",
    value: "Islas Malvinas",
    code: "FK",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Marianas del Norte",
    value: "Islas Marianas del Norte",
    code: "MP",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Marshall",
    value: "Islas Marshall",
    code: "MH",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Pitcairn",
    value: "Islas Pitcairn",
    code: "PN",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Salomón",
    value: "Islas Salomón",
    code: "SB",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Turcas y Caicos",
    value: "Islas Turcas y Caicos",
    code: "TC",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Ultramarinas Menores de Estados Unidos",
    value: "Islas Ultramarinas Menores de Estados Unidos",
    code: "UM",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Vírgenes Británicas",
    value: "Islas Vírgenes Británicas",
    code: "VG",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Vírgenes de los Estados Unidos",
    value: "Islas Vírgenes de los Estados Unidos",
    code: "VI",
    groupTitle: "Todos los países",
  },
  {
    label: "Israel",
    value: "Israel",
    region: "europa",
    code: "IL",
    groupTitle: "Todos los países",
  },
  {
    label: "Italia",
    value: "Italia",
    region: "europa",
    code: "IT",
    groupTitle: "Todos los países",
  },
  {
    label: "Jamaica",
    value: "Jamaica",
    code: "JM",
    groupTitle: "Todos los países",
  },
  {
    label: "Japón",
    value: "Japón",
    code: "JP",
    groupTitle: "Todos los países",
  },
  {
    label: "Jersey",
    value: "Jersey",
    code: "JE",
    groupTitle: "Todos los países",
  },
  {
    label: "Jordania",
    value: "Jordania",
    code: "JO",
    groupTitle: "Todos los países",
  },
  {
    label: "Kazajistán",
    value: "Kazajistán",
    code: "KZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Kenia",
    value: "Kenia",
    code: "KE",
    groupTitle: "Todos los países",
  },
  {
    label: "Kirguistán",
    value: "Kirguistán",
    code: "KG",
    groupTitle: "Todos los países",
  },
  {
    label: "Kiribati",
    value: "Kiribati",
    code: "KI",
    groupTitle: "Todos los países",
  },
  {
    label: "Kosovo",
    value: "Kosovo",
    code: "XK",
    groupTitle: "Todos los países",
  },
  {
    label: "Kuwait",
    value: "Kuwait",
    code: "KW",
    groupTitle: "Todos los países",
  },
  {
    label: "Laos",
    value: "Laos",
    code: "LA",
    groupTitle: "Todos los países",
  },
  {
    label: "Lesoto",
    value: "Lesoto",
    code: "LS",
    groupTitle: "Todos los países",
  },
  {
    label: "Letonia",
    value: "Letonia",
    region: "europa",
    code: "LV",
    groupTitle: "Todos los países",
  },
  {
    label: "Liberia",
    value: "Liberia",
    code: "LR",
    groupTitle: "Todos los países",
  },
  {
    label: "Libia",
    value: "Libia",
    region: "europa",
    code: "LY",
    groupTitle: "Todos los países",
  },
  {
    label: "Liechtenstein",
    value: "Liechtenstein",
    region: "europa",
    code: "LI",
    groupTitle: "Todos los países",
  },
  {
    label: "Lituania",
    value: "Lituania",
    region: "europa",
    code: "LT",
    groupTitle: "Todos los países",
  },
  {
    label: "Luxemburgo",
    value: "Luxemburgo",
    region: "europa",
    code: "LU",
    groupTitle: "Todos los países",
  },
  {
    label: "Líbano",
    value: "Líbano",
    region: "europa",
    code: "LB",
    groupTitle: "Todos los países",
  },
  {
    label: "Macao",
    value: "Macao",
    code: "MO",
    groupTitle: "Todos los países",
  },
  {
    label: "Macedônia",
    value: "Macedônia",
    code: "MK",
    groupTitle: "Todos los países",
  },
  {
    label: "Madagascar",
    value: "Madagascar",
    code: "MG",
    groupTitle: "Todos los países",
  },
  {
    label: "Malasia",
    value: "Malasia",
    code: "MY",
    groupTitle: "Todos los países",
  },
  {
    label: "Malawi",
    value: "Malawi",
    code: "MW",
    groupTitle: "Todos los países",
  },
  {
    label: "Mali",
    value: "Mali",
    code: "ML",
    groupTitle: "Todos los países",
  },
  {
    label: "Malta",
    value: "Malta",
    region: "europa",
    code: "MT",
    groupTitle: "Todos los países",
  },
  {
    label: "Marruecos",
    value: "Marruecos",
    region: "europa",
    code: "MA",
    groupTitle: "Todos los países",
  },
  {
    label: "Martinica",
    value: "Martinica",
    code: "MQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Mauricio",
    value: "Mauricio",
    code: "MU",
    groupTitle: "Todos los países",
  },
  {
    label: "Mauritania",
    value: "Mauritania",
    code: "MR",
    groupTitle: "Todos los países",
  },
  {
    label: "Mayotte",
    value: "Mayotte",
    code: "YT",
    groupTitle: "Todos los países",
  },
  {
    label: "Micronesia",
    value: "Micronesia",
    code: "FM",
    groupTitle: "Todos los países",
  },
  {
    label: "Moldavia",
    value: "Moldavia",
    region: "europa",
    code: "MD",
    groupTitle: "Todos los países",
  },
  {
    label: "Mongolia",
    value: "Mongolia",
    code: "MN",
    groupTitle: "Todos los países",
  },
  {
    label: "Montenegro",
    value: "Montenegro",
    region: "europa",
    code: "ME",
    groupTitle: "Todos los países",
  },
  {
    label: "Montserrat",
    value: "Montserrat",
    code: "MS",
    groupTitle: "Todos los países",
  },
  {
    label: "Mozambique",
    value: "Mozambique",
    code: "MZ",
    groupTitle: "Todos los países",
  },
  {
    label: "México",
    value: "México",
    code: "MX",
    groupTitle: "Todos los países",
  },
  {
    label: "Mónaco",
    value: "Mónaco",
    region: "europa",
    code: "MC",
    groupTitle: "Todos los países",
  },
  {
    label: "Naciones Unidas",
    value: "Naciones Unidas",
    code: "UN",
    groupTitle: "Todos los países",
  },
  {
    label: "Namibia",
    value: "Namibia",
    code: "NA",
    groupTitle: "Todos los países",
  },
  {
    label: "Nauru",
    value: "Nauru",
    code: "NR",
    groupTitle: "Todos los países",
  },
  {
    label: "Nepal",
    value: "Nepal",
    code: "NP",
    groupTitle: "Todos los países",
  },
  {
    label: "Nicaragua",
    value: "Nicaragua",
    code: "NI",
    groupTitle: "Todos los países",
  },
  {
    label: "Niger",
    value: "Niger",
    code: "NE",
    groupTitle: "Todos los países",
  },
  {
    label: "Nigeria",
    value: "Nigeria",
    code: "NG",
    groupTitle: "Todos los países",
  },
  {
    label: "Niue",
    value: "Niue",
    code: "NU",
    groupTitle: "Todos los países",
  },
  {
    label: "Noruega",
    value: "Noruega",
    region: "europa",
    code: "NO",
    groupTitle: "Todos los países",
  },
  {
    label: "Nueva Caledonia",
    value: "Nueva Caledonia",
    code: "NC",
    groupTitle: "Todos los países",
  },
  {
    label: "Nueva Zelanda",
    value: "Nueva Zelanda",
    code: "NZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Omán",
    value: "Omán",
    code: "OM",
    groupTitle: "Todos los países",
  },
  {
    label: "Pakistán",
    value: "Pakistán",
    code: "PK",
    groupTitle: "Todos los países",
  },
  {
    label: "Palau",
    value: "Palau",
    code: "PW",
    groupTitle: "Todos los países",
  },
  {
    label: "Palestina",
    value: "Palestina",
    code: "PS",
    groupTitle: "Todos los países",
  },
  {
    label: "Panamá",
    value: "Panamá",
    code: "PA",
    groupTitle: "Todos los países",
  },
  {
    label: "Papúa Nueva Guinea",
    value: "Papúa Nueva Guinea",
    code: "PG",
    groupTitle: "Todos los países",
  },
  {
    label: "Paraguay",
    value: "Paraguay",
    code: "PY",
    groupTitle: "Todos los países",
  },
  {
    label: "Países Bajos",
    value: "Países Bajos",
    region: "europa",
    code: "NL",
    groupTitle: "Todos los países",
  },
  {
    label: "Perú",
    value: "Perú",
    code: "PE",
    groupTitle: "Todos los países",
  },
  {
    label: "Polinesia Francesa",
    value: "Polinesia Francesa",
    code: "PF",
    groupTitle: "Todos los países",
  },
  {
    label: "Polonia",
    value: "Polonia",
    region: "europa",
    code: "PL",
    groupTitle: "Todos los países",
  },
  {
    label: "Portugal",
    value: "Portugal",
    region: "europa",
    code: "PT",
    groupTitle: "Todos los países",
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
    code: "PR",
    groupTitle: "Todos los países",
  },
  {
    label: "Qatar",
    value: "Qatar",
    code: "QA",
    groupTitle: "Todos los países",
  },
  {
    label: "Reino Unido",
    value: "Reino Unido",
    region: "europa",
    code: "UK",
    groupTitle: "Todos los países",
  },
  {
    label: "Reino Unido de Gran Bretaña e Irlanda del Norte",
    value: "Reino Unido de Gran Bretaña e Irlanda del Norte",
    region: "europa",
    code: "GB",
    groupTitle: "Todos los países",
  },
  {
    label: "República Centroafricana",
    value: "República Centroafricana",
    code: "CF",
    groupTitle: "Todos los países",
  },
  {
    label: "República Checa",
    value: "República Checa",
    region: "europa",
    code: "CZ",
    groupTitle: "Todos los países",
  },
  {
    label: "República de Sudán del Sur",
    value: "República de Sudán del Sur",
    code: "SS",
    groupTitle: "Todos los países",
  },
  {
    label: "República del Congo",
    value: "República del Congo",
    code: "CG",
    groupTitle: "Todos los países",
  },
  {
    label: "República Democrática del Congo",
    value: "República Democrática del Congo",
    code: "CD",
    groupTitle: "Todos los países",
  },
  {
    label: "República Dominicana",
    value: "República Dominicana",
    code: "DO",
    groupTitle: "Todos los países",
  },
  {
    label: "Reunión",
    value: "Reunión",
    code: "RE",
    groupTitle: "Todos los países",
  },
  {
    label: "Ruanda",
    value: "Ruanda",
    code: "RW",
    groupTitle: "Todos los países",
  },
  {
    label: "Rumanía",
    value: "Rumanía",
    code: "RO",
    groupTitle: "Todos los países",
  },
  {
    label: "Rusia",
    value: "Rusia",
    region: "europa",
    code: "RU",
    groupTitle: "Todos los países",
  },
  {
    label: "Sahara Occidental",
    value: "Sahara Occidental",
    code: "EH",
    groupTitle: "Todos los países",
  },
  {
    label: "Samoa",
    value: "Samoa",
    code: "WS",
    groupTitle: "Todos los países",
  },
  {
    label: "Samoa Americana",
    value: "Samoa Americana",
    code: "AS",
    groupTitle: "Todos los países",
  },
  {
    label: "San Bartolomé",
    value: "San Bartolomé",
    code: "BL",
    groupTitle: "Todos los países",
  },
  {
    label: "San Cristóbal y Nieves",
    value: "San Cristóbal y Nieves",
    code: "KN",
    groupTitle: "Todos los países",
  },
  {
    label: "San Marino",
    value: "San Marino",
    region: "europa",
    code: "SM",
    groupTitle: "Todos los países",
  },
  {
    label: "San Martín (Francia)",
    value: "San Martín (Francia)",
    region: "europa",
    code: "MF",
    groupTitle: "Todos los países",
  },
  {
    label: "San Pedro y Miquelón",
    value: "San Pedro y Miquelón",
    code: "PM",
    groupTitle: "Todos los países",
  },
  {
    label: "San Vicente y las Granadinas",
    value: "San Vicente y las Granadinas",
    code: "VC",
    groupTitle: "Todos los países",
  },
  {
    label: "Santa Elena",
    value: "Santa Elena",
    code: "SH",
    groupTitle: "Todos los países",
  },
  {
    label: "Santa Lucía",
    value: "Santa Lucía",
    code: "LC",
    groupTitle: "Todos los países",
  },
  {
    label: "Santo Tomé y Príncipe",
    value: "Santo Tomé y Príncipe",
    code: "ST",
    groupTitle: "Todos los países",
  },
  {
    label: "Senegal",
    value: "Senegal",
    code: "SN",
    groupTitle: "Todos los países",
  },
  {
    label: "Serbia",
    value: "Serbia",
    region: "europa",
    code: "RS",
    groupTitle: "Todos los países",
  },
  {
    label: "Seychelles",
    value: "Seychelles",
    code: "SC",
    groupTitle: "Todos los países",
  },
  {
    label: "Sierra Leona",
    value: "Sierra Leona",
    code: "SL",
    groupTitle: "Todos los países",
  },
  {
    label: "Singapur",
    value: "Singapur",
    code: "SG",
    groupTitle: "Todos los países",
  },
  {
    label: "Sint Maarten",
    value: "Sint Maarten",
    code: "SX",
    groupTitle: "Todos los países",
  },
  {
    label: "Siria",
    value: "Siria",
    code: "SY",
    groupTitle: "Todos los países",
  },
  {
    label: "Somalia",
    value: "Somalia",
    code: "SO",
    groupTitle: "Todos los países",
  },
  {
    label: "Sri lanka",
    value: "Sri lanka",
    code: "LK",
    groupTitle: "Todos los países",
  },
  {
    label: "Sudáfrica",
    value: "Sudáfrica",
    code: "ZA",
    groupTitle: "Todos los países",
  },
  {
    label: "Sudán",
    value: "Sudán",
    code: "SD",
    groupTitle: "Todos los países",
  },
  {
    label: "Suecia",
    value: "Suecia",
    region: "europa",
    code: "SE",
    groupTitle: "Todos los países",
  },
  {
    label: "Suiza",
    value: "Suiza",
    region: "europa",
    code: "CH",
    groupTitle: "Todos los países",
  },
  {
    label: "Surinám",
    value: "Surinám",
    code: "SR",
    groupTitle: "Todos los países",
  },
  {
    label: "Svalbard y Jan Mayen",
    value: "Svalbard y Jan Mayen",
    code: "SJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Swazilandia",
    value: "Swazilandia",
    code: "SZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Tailandia",
    value: "Tailandia",
    code: "TH",
    groupTitle: "Todos los países",
  },
  {
    label: "Taiwán",
    value: "Taiwán",
    code: "TW",
    groupTitle: "Todos los países",
  },
  {
    label: "Tanzania",
    value: "Tanzania",
    code: "TZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Tayikistán",
    value: "Tayikistán",
    code: "TJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Territorio Británico del Océano Índico",
    value: "Territorio Británico del Océano Índico",
    code: "IO",
    groupTitle: "Todos los países",
  },
  {
    label: "Territorios Australes y Antárticas Franceses",
    value: "Territorios Australes y Antárticas Franceses",
    code: "TF",
    groupTitle: "Todos los países",
  },
  {
    label: "Timor Oriental",
    value: "Timor Oriental",
    code: "TL",
    groupTitle: "Todos los países",
  },
  {
    label: "Togo",
    value: "Togo",
    code: "TG",
    groupTitle: "Todos los países",
  },
  {
    label: "Tokelau",
    value: "Tokelau",
    code: "TK",
    groupTitle: "Todos los países",
  },
  {
    label: "Tonga",
    value: "Tonga",
    code: "TO",
    groupTitle: "Todos los países",
  },
  {
    label: "Trinidad y Tobago",
    value: "Trinidad y Tobago",
    code: "TT",
    groupTitle: "Todos los países",
  },
  {
    label: "Tristán de Acuña",
    value: "Tristán de Acuña",
    code: "TA",
    groupTitle: "Todos los países",
  },
  {
    label: "Tunez",
    value: "Tunez",
    region: "europa",
    code: "TN",
    groupTitle: "Todos los países",
  },
  {
    label: "Turkmenistán",
    value: "Turkmenistán",
    code: "TM",
    groupTitle: "Todos los países",
  },
  {
    label: "Turquía",
    value: "Turquía",
    code: "TR",
    groupTitle: "Todos los países",
  },
  {
    label: "Tuvalu",
    value: "Tuvalu",
    code: "TV",
    groupTitle: "Todos los países",
  },
  {
    label: "Ucrania",
    value: "Ucrania",
    region: "europa",
    code: "UA",
    groupTitle: "Todos los países",
  },
  {
    label: "Uganda",
    value: "Uganda",
    code: "UG",
    groupTitle: "Todos los países",
  },
  {
    label: "Unión Soviética",
    value: "Unión Soviética",
    code: "SU",
    groupTitle: "Todos los países",
  },
  {
    label: "Uruguay",
    value: "Uruguay",
    code: "UY",
    groupTitle: "Todos los países",
  },
  {
    label: "Uzbekistán",
    value: "Uzbekistán",
    code: "UZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Vanuatu",
    value: "Vanuatu",
    code: "VU",
    groupTitle: "Todos los países",
  },
  {
    label: "Venezuela",
    value: "Venezuela",
    code: "VE",
    groupTitle: "Todos los países",
  },
  {
    label: "Vietnam",
    value: "Vietnam",
    code: "VN",
    groupTitle: "Todos los países",
  },
  {
    label: "Wallis y Futuna",
    value: "Wallis y Futuna",
    code: "WF",
    groupTitle: "Todos los países",
  },
  {
    label: "Yemen",
    value: "Yemen",
    code: "YE",
    groupTitle: "Todos los países",
  },
  {
    label: "Yibuti",
    value: "Yibuti",
    code: "DJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Zambia",
    value: "Zambia",
    code: "ZM",
    groupTitle: "Todos los países",
  },
  {
    label: "Zimbabue",
    value: "Zimbabue",
    code: "ZW",
    groupTitle: "Todos los países",
  },
];

export const originListMex = [
  {
    label: "México",
    value: "México",
    region: 'mexico',
    code: "MX",
    groupTitle: "Ámbito Global",
  },
];

export const getCountriesListSpanish = (categoryKey?: string, destination?: any, setValue?: any, filterTitle: any = null) => {
  if (categoryKey === "ESTUDIANTES" || categoryKey === "WORKANDHOLIDAY") {
    return _countriesListSpanish.map((country) => {
      if (country.code === "WR_EX_USA")
        return {
          ...country,
          label: "Mundo (Excluido EEUU, Canadá, Australia)",
          value: "Mundo (Excluido EEUU, Canadá, Australia)",
        };

      return country;
    });
  }

  const isExistHealth = (filterTitle?.toUpperCase()?.includes(HEALTH_INSURANCE_TITLE.toUpperCase()) || (categoryKey === HEALTH_INSURANCE_CODE ||
    categoryKey === HEALTH_INSURANCE_MJ_CODE));

  if (isExistHealth && destination) {
    const destinationList = _countriesListSpanish.filter((country: any) => country?.value.includes("España"));
    setValue('destination', destinationList.find((destiny: any) => destination.value === destiny.value
    ) ?? destinationList[0])
    return destinationList;
  }

  const isExistMexico = ((categoryKey === ANNUAL_MEX ||
    categoryKey === SINGLE_TRIP_MEX || categoryKey === STUDENT_MEX));

  if (isExistMexico && destination) {
    let countriesList = [];
    if (categoryKey === STUDENT_MEX) {
      countriesList = _countriesListSpanish.filter((country) => country.value !== "México");
    } else {
      countriesList = _countriesListSpanish.map((country) => {
        if (country.value === "México") {
          return {
            ...originListMex[0],
            groupTitle: "Si viajas a diferentes países"
          };
        }
        return country;
      });

      const mexicoIndex = countriesList.findIndex((country) => country.value === "México");
      if (mexicoIndex > -1) {
        const mexico = countriesList.splice(mexicoIndex, 1)[0];
        countriesList.unshift(mexico);
      }
    }

    return countriesList;
  }

  return _countriesListSpanish;
};

export const getOriginCountry = (setValue: any, categoryKey?: string, countryList?: any[], filterOrigin: any = null, filterTitle: any = null) => {
  let countries = originList;
  const isExistHealth = (filterTitle?.toUpperCase()?.includes(HEALTH_INSURANCE_TITLE.toUpperCase()) || (categoryKey === HEALTH_INSURANCE_CODE ||
    categoryKey === HEALTH_INSURANCE_MJ_CODE));
  if (isExistHealth && countryList) {
    countries = countryList.filter((country) => !country?.value.includes('España'));
    const origin: any = countries.find((country: any) => country?.value === filterOrigin?.value) ?? countries[0];
    setValue('origin', origin);
  }

  const isExistMexico = ((categoryKey === ANNUAL_MEX ||
    categoryKey === SINGLE_TRIP_MEX || categoryKey === STUDENT_MEX));

  if (isExistMexico && countryList) {
    countries = originListMex;
    setValue('origin', originListMex[0]);
  }

  return countries;
};

export const getOriginCountryMex = (setValue: any, categoryKey?: string, countryList?: any[], filterOrigin: any = null, filterTitle: any = null) => {
  let countries = originList;
   const isExistMexico = ((categoryKey === ANNUAL_MEX ||
    categoryKey === SINGLE_TRIP_MEX || categoryKey === STUDENT_MEX));

  if (isExistMexico && countryList) {
    countries = originListMex;
    setValue('origin', originListMex[0]);
  }

  return countries;
};

enum CaserRegion {
  Spain = "spain",
  Europe = "europe",
  // If "caserRegion" field does not exist, it is considered "world" for backend purposes
}

export const _countriesListSpanish = [
  {
    label: "Mundo (Excluido EEUU)",
    value: "Mundo (Excluido EEUU)",
    code: "WR_EX_USA",
    groupTitle: "Si viajas a diferentes países",
  },
  {
    label: "Todo el mundo",
    value: "Todo el mundo",
    code: "WR",
    groupTitle: "Si viajas a diferentes países",
  },
  {
    label: "Europa",
    value: "Europa",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "EU",
    groupTitle: "Si viajas a diferentes países",
  },
  {
    label: "España",
    value: "España",
    region: "europa",
    caserRegion: CaserRegion.Spain,
    code: "ES",
    groupTitle: "Si viajas a diferentes países",
  },
  {
    label: "Afganistán",
    value: "Afganistán",
    code: "AF",
    groupTitle: "Todos los países",
  },
  {
    label: "Albania",
    value: "Albania",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "AL",
    groupTitle: "Todos los países",
  },
  {
    label: "Alemania",
    value: "Alemania",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "DE",
    groupTitle: "Todos los países",
  },
  {
    label: "Andorra",
    value: "Andorra",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "AD",
    groupTitle: "Todos los países",
  },
  {
    label: "Angola",
    value: "Angola",
    code: "AO",
    groupTitle: "Todos los países",
  },
  {
    label: "Anguila",
    value: "Anguila",
    code: "AI",
    groupTitle: "Todos los países",
  },
  {
    label: "Antigua y Barbuda",
    value: "Antigua y Barbuda",
    code: "AG",
    groupTitle: "Todos los países",
  },
  {
    label: "Antártida",
    value: "Antártida",
    code: "AQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Arabia Saudita",
    value: "Arabia Saudita",
    code: "SA",
    groupTitle: "Todos los países",
  },
  {
    label: "Argelia",
    value: "Argelia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "DZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Argentina",
    value: "Argentina",
    code: "AR",
    groupTitle: "Todos los países",
  },
  {
    label: "Armenia",
    value: "Armenia",
    code: "AM",
    groupTitle: "Todos los países",
  },
  {
    label: "Aruba",
    value: "Aruba",
    code: "AW",
    groupTitle: "Todos los países",
  },
  {
    label: "Australia",
    value: "Australia",
    code: "AU",
    groupTitle: "Todos los países",
  },
  {
    label: "Austria",
    value: "Austria",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "AT",
    groupTitle: "Todos los países",
  },
  {
    label: "Azerbaiyán",
    value: "Azerbaiyán",
    code: "AZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Bahamas",
    value: "Bahamas",
    code: "BS",
    groupTitle: "Todos los países",
  },
  {
    label: "Bahrein",
    value: "Bahrein",
    code: "BH",
    groupTitle: "Todos los países",
  },
  {
    label: "Bangladesh",
    value: "Bangladesh",
    code: "BD",
    groupTitle: "Todos los países",
  },
  {
    label: "Barbados",
    value: "Barbados",
    code: "BB",
    groupTitle: "Todos los países",
  },
  {
    label: "Belice",
    value: "Belice",
    code: "BZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Benín",
    value: "Benín",
    code: "BJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Bhután",
    value: "Bhután",
    code: "BT",
    groupTitle: "Todos los países",
  },
  {
    label: "Bielorrusia",
    value: "Bielorrusia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "BY",
    groupTitle: "Todos los países",
  },
  {
    label: "Birmania",
    value: "Birmania",
    code: "MM",
    groupTitle: "Todos los países",
  },
  {
    label: "Bolivia",
    value: "Bolivia",
    code: "BO",
    groupTitle: "Todos los países",
  },
  {
    label: "Bonaire, San Eustaquio y Saba",
    value: "Bonaire, San Eustaquio y Saba",
    code: "BQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Bosnia y Herzegovina",
    value: "Bosnia y Herzegovina",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "BA",
    groupTitle: "Todos los países",
  },
  {
    label: "Botsuana",
    value: "Botsuana",
    code: "BW",
    groupTitle: "Todos los países",
  },
  {
    label: "Brasil",
    value: "Brasil",
    code: "BR",
    groupTitle: "Todos los países",
  },
  {
    label: "Brunéi",
    value: "Brunéi",
    code: "BN",
    groupTitle: "Todos los países",
  },
  {
    label: "Bulgaria",
    value: "Bulgaria",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "BG",
    groupTitle: "Todos los países",
  },
  {
    label: "Burkina Faso",
    value: "Burkina Faso",
    code: "BF",
    groupTitle: "Todos los países",
  },
  {
    label: "Burundi",
    value: "Burundi",
    code: "BI",
    groupTitle: "Todos los países",
  },
  {
    label: "Bélgica",
    value: "Bélgica",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "BE",
    groupTitle: "Todos los países",
  },
  {
    label: "Cabo Verde",
    value: "Cabo Verde",
    code: "CV",
    groupTitle: "Todos los países",
  },
  {
    label: "Camboya",
    value: "Camboya",
    code: "KH",
    groupTitle: "Todos los países",
  },
  {
    label: "Camerún",
    value: "Camerún",
    code: "CM",
    groupTitle: "Todos los países",
  },
  {
    label: "Canadá",
    value: "Canadá",
    code: "CA",
    groupTitle: "Todos los países",
  },
  {
    label: "Ceuta y Melilla",
    value: "Ceuta y Melilla",
    caserRegion: CaserRegion.Spain,
    code: "EA",
    groupTitle: "Todos los países",
  },
  {
    label: "Chad",
    value: "Chad",
    code: "TD",
    groupTitle: "Todos los países",
  },
  {
    label: "Chile",
    value: "Chile",
    code: "CL",
    groupTitle: "Todos los países",
  },
  {
    label: "China",
    value: "China",
    code: "CN",
    groupTitle: "Todos los países",
  },
  {
    label: "Chipre",
    value: "Chipre",
    code: "CY",
    groupTitle: "Todos los países",
  },
  {
    label: "Ciudad del Vaticano",
    value: "Ciudad del Vaticano",
    caserRegion: CaserRegion.Europe,
    code: "VA",
    groupTitle: "Todos los países",
  },
  {
    label: "Colombia",
    value: "Colombia",
    code: "CO",
    groupTitle: "Todos los países",
  },
  {
    label: "Comoras",
    value: "Comoras",
    code: "KM",
    groupTitle: "Todos los países",
  },
  {
    label: "Corea del Norte",
    value: "Corea del Norte",
    code: "KP",
    groupTitle: "Todos los países",
  },
  {
    label: "Corea del Sur",
    value: "Corea del Sur",
    code: "KR",
    groupTitle: "Todos los países",
  },
  {
    label: "Costa de Marfil",
    value: "Costa de Marfil",
    code: "CI",
    groupTitle: "Todos los países",
  },
  {
    label: "Costa Rica",
    value: "Costa Rica",
    code: "CR",
    groupTitle: "Todos los países",
  },
  {
    label: "Croacia",
    value: "Croacia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "HR",
    groupTitle: "Todos los países",
  },
  {
    label: "Cuba",
    value: "Cuba",
    code: "CU",
    groupTitle: "Todos los países",
  },
  {
    label: "Curazao",
    value: "Curazao",
    code: "CW",
    groupTitle: "Todos los países",
  },
  {
    label: "Diego García",
    value: "Diego García",
    code: "DG",
    groupTitle: "Todos los países",
  },
  {
    label: "Dinamarca",
    value: "Dinamarca",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "DK",
    groupTitle: "Todos los países",
  },
  {
    label: "Dominica",
    value: "Dominica",
    code: "DM",
    groupTitle: "Todos los países",
  },
  {
    label: "Ecuador",
    value: "Ecuador",
    code: "EC",
    groupTitle: "Todos los países",
  },
  {
    label: "Egipto",
    value: "Egipto",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "EG",
    groupTitle: "Todos los países",
  },
  {
    label: "El Salvador",
    value: "El Salvador",
    code: "SV",
    groupTitle: "Todos los países",
  },
  {
    label: "Emiratos Árabes Unidos",
    value: "Emiratos Árabes Unidos",
    code: "AE",
    groupTitle: "Todos los países",
  },
  {
    label: "Eritrea",
    value: "Eritrea",
    code: "ER",
    groupTitle: "Todos los países",
  },
  {
    label: "Eslovaquia",
    value: "Eslovaquia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "SK",
    groupTitle: "Todos los países",
  },
  {
    label: "Eslovenia",
    value: "Eslovenia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "SI",
    groupTitle: "Todos los países",
  },
  {
    label: "Estados Unidos de América",
    value: "Estados Unidos de América",
    code: "US",
    groupTitle: "Todos los países",
  },
  {
    label: "Estonia",
    value: "Estonia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "EE",
    groupTitle: "Todos los países",
  },
  {
    label: "Etiopía",
    value: "Etiopía",
    code: "ET",
    groupTitle: "Todos los países",
  },
  {
    label: "Filipinas",
    value: "Filipinas",
    code: "PH",
    groupTitle: "Todos los países",
  },
  {
    label: "Finlandia",
    value: "Finlandia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "FI",
    groupTitle: "Todos los países",
  },
  {
    label: "Fiyi",
    value: "Fiyi",
    code: "FJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Francia",
    value: "Francia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "FR",
    groupTitle: "Todos los países",
  },
  {
    label: "Gabón",
    value: "Gabón",
    code: "GA",
    groupTitle: "Todos los países",
  },
  {
    label: "Gambia",
    value: "Gambia",
    code: "GM",
    groupTitle: "Todos los países",
  },
  {
    label: "Georgia",
    value: "Georgia",
    code: "GE",
    groupTitle: "Todos los países",
  },
  {
    label: "Ghana",
    value: "Ghana",
    code: "GH",
    groupTitle: "Todos los países",
  },
  {
    label: "Gibraltar",
    value: "Gibraltar",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "GI",
    groupTitle: "Todos los países",
  },
  {
    label: "Granada",
    value: "Granada",
    code: "GD",
    groupTitle: "Todos los países",
  },
  {
    label: "Grecia",
    value: "Grecia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "GR",
    groupTitle: "Todos los países",
  },
  {
    label: "Groenlandia",
    value: "Groenlandia",
    code: "GL",
    groupTitle: "Todos los países",
  },
  {
    label: "Guadalupe",
    value: "Guadalupe",
    code: "GP",
    groupTitle: "Todos los países",
  },
  {
    label: "Guam",
    value: "Guam",
    code: "GU",
    groupTitle: "Todos los países",
  },
  {
    label: "Guatemala",
    value: "Guatemala",
    code: "GT",
    groupTitle: "Todos los países",
  },
  {
    label: "Guayana Francesa",
    value: "Guayana Francesa",
    code: "GF",
    groupTitle: "Todos los países",
  },
  {
    label: "Guernsey",
    value: "Guernsey",
    code: "GG",
    groupTitle: "Todos los países",
  },
  {
    label: "Guinea",
    value: "Guinea",
    code: "GN",
    groupTitle: "Todos los países",
  },
  {
    label: "Guinea Ecuatorial",
    value: "Guinea Ecuatorial",
    code: "GQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Guinea-Bissau",
    value: "Guinea-Bissau",
    code: "GW",
    groupTitle: "Todos los países",
  },
  {
    label: "Guyana",
    value: "Guyana",
    code: "GY",
    groupTitle: "Todos los países",
  },
  {
    label: "Haití",
    value: "Haití",
    code: "HT",
    groupTitle: "Todos los países",
  },
  {
    label: "Honduras",
    value: "Honduras",
    code: "HN",
    groupTitle: "Todos los países",
  },
  {
    label: "Hong kong",
    value: "Hong kong",
    code: "HK",
    groupTitle: "Todos los países",
  },
  {
    label: "Hungría",
    value: "Hungría",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "HU",
    groupTitle: "Todos los países",
  },
  {
    label: "India",
    value: "India",
    code: "IN",
    groupTitle: "Todos los países",
  },
  {
    label: "Indonesia",
    value: "Indonesia",
    code: "ID",
    groupTitle: "Todos los países",
  },
  {
    label: "Irak",
    value: "Irak",
    code: "IQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Irlanda",
    value: "Irlanda",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "IE",
    groupTitle: "Todos los países",
  },
  {
    label: "Irán",
    value: "Irán",
    code: "IR",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Ascensión",
    value: "Isla Ascensión",
    code: "AC",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Bouvet",
    value: "Isla Bouvet",
    code: "BV",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Clipperton",
    value: "Isla Clipperton",
    code: "CP",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla de Man",
    value: "Isla de Man",
    code: "IM",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla de Navidad",
    value: "Isla de Navidad",
    code: "CX",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Norfolk",
    value: "Isla Norfolk",
    code: "NF",
    groupTitle: "Todos los países",
  },
  {
    label: "Islandia",
    value: "Islandia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "IS",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Bermudas",
    value: "Islas Bermudas",
    code: "BM",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Caimán",
    value: "Islas Caimán",
    code: "KY",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Canarias",
    value: "Islas Canarias",
    caserRegion: CaserRegion.Spain,
    code: "IC",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Cocos (Keeling)",
    value: "Islas Cocos (Keeling)",
    code: "CC",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Cook",
    value: "Islas Cook",
    code: "CK",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas de Åland",
    value: "Islas de Åland",
    code: "AX",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Feroe",
    value: "Islas Feroe",
    code: "FO",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Georgias del Sur y Sandwich del Sur",
    value: "Islas Georgias del Sur y Sandwich del Sur",
    code: "GS",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Heard y McDonald",
    value: "Islas Heard y McDonald",
    code: "HM",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Maldivas",
    value: "Islas Maldivas",
    code: "MV",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Malvinas",
    value: "Islas Malvinas",
    code: "FK",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Marianas del Norte",
    value: "Islas Marianas del Norte",
    code: "MP",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Marshall",
    value: "Islas Marshall",
    code: "MH",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Pitcairn",
    value: "Islas Pitcairn",
    code: "PN",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Salomón",
    value: "Islas Salomón",
    code: "SB",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Turcas y Caicos",
    value: "Islas Turcas y Caicos",
    code: "TC",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Ultramarinas Menores de Estados Unidos",
    value: "Islas Ultramarinas Menores de Estados Unidos",
    code: "UM",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Vírgenes Británicas",
    value: "Islas Vírgenes Británicas",
    code: "VG",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Vírgenes de los Estados Unidos",
    value: "Islas Vírgenes de los Estados Unidos",
    code: "VI",
    groupTitle: "Todos los países",
  },
  {
    label: "Israel",
    value: "Israel",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "IL",
    groupTitle: "Todos los países",
  },
  {
    label: "Italia",
    value: "Italia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "IT",
    groupTitle: "Todos los países",
  },
  {
    label: "Jamaica",
    value: "Jamaica",
    code: "JM",
    groupTitle: "Todos los países",
  },
  {
    label: "Japón",
    value: "Japón",
    code: "JP",
    groupTitle: "Todos los países",
  },
  {
    label: "Jersey",
    value: "Jersey",
    code: "JE",
    groupTitle: "Todos los países",
  },
  {
    label: "Jordania",
    value: "Jordania",
    code: "JO",
    groupTitle: "Todos los países",
  },
  {
    label: "Kazajistán",
    value: "Kazajistán",
    code: "KZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Kenia",
    value: "Kenia",
    code: "KE",
    groupTitle: "Todos los países",
  },
  {
    label: "Kirguistán",
    value: "Kirguistán",
    code: "KG",
    groupTitle: "Todos los países",
  },
  {
    label: "Kiribati",
    value: "Kiribati",
    code: "KI",
    groupTitle: "Todos los países",
  },
  {
    label: "Kosovo",
    value: "Kosovo",
    code: "XK",
    groupTitle: "Todos los países",
  },
  {
    label: "Kuwait",
    value: "Kuwait",
    code: "KW",
    groupTitle: "Todos los países",
  },
  {
    label: "Laos",
    value: "Laos",
    code: "LA",
    groupTitle: "Todos los países",
  },
  {
    label: "Lesoto",
    value: "Lesoto",
    code: "LS",
    groupTitle: "Todos los países",
  },
  {
    label: "Letonia",
    value: "Letonia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "LV",
    groupTitle: "Todos los países",
  },
  {
    label: "Liberia",
    value: "Liberia",
    code: "LR",
    groupTitle: "Todos los países",
  },
  {
    label: "Libia",
    value: "Libia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "LY",
    groupTitle: "Todos los países",
  },
  {
    label: "Liechtenstein",
    value: "Liechtenstein",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "LI",
    groupTitle: "Todos los países",
  },
  {
    label: "Lituania",
    value: "Lituania",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "LT",
    groupTitle: "Todos los países",
  },
  {
    label: "Luxemburgo",
    value: "Luxemburgo",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "LU",
    groupTitle: "Todos los países",
  },
  {
    label: "Líbano",
    value: "Líbano",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "LB",
    groupTitle: "Todos los países",
  },
  {
    label: "Macao",
    value: "Macao",
    code: "MO",
    groupTitle: "Todos los países",
  },
  {
    label: "Macedônia",
    value: "Macedônia",
    code: "MK",
    groupTitle: "Todos los países",
  },
  {
    label: "Madagascar",
    value: "Madagascar",
    code: "MG",
    groupTitle: "Todos los países",
  },
  {
    label: "Malasia",
    value: "Malasia",
    code: "MY",
    groupTitle: "Todos los países",
  },
  {
    label: "Malawi",
    value: "Malawi",
    code: "MW",
    groupTitle: "Todos los países",
  },
  {
    label: "Mali",
    value: "Mali",
    code: "ML",
    groupTitle: "Todos los países",
  },
  {
    label: "Malta",
    value: "Malta",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "MT",
    groupTitle: "Todos los países",
  },
  {
    label: "Marruecos",
    value: "Marruecos",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "MA",
    groupTitle: "Todos los países",
  },
  {
    label: "Martinica",
    value: "Martinica",
    code: "MQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Mauricio",
    value: "Mauricio",
    code: "MU",
    groupTitle: "Todos los países",
  },
  {
    label: "Mauritania",
    value: "Mauritania",
    code: "MR",
    groupTitle: "Todos los países",
  },
  {
    label: "Mayotte",
    value: "Mayotte",
    code: "YT",
    groupTitle: "Todos los países",
  },
  {
    label: "Micronesia",
    value: "Micronesia",
    code: "FM",
    groupTitle: "Todos los países",
  },
  {
    label: "Moldavia",
    value: "Moldavia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "MD",
    groupTitle: "Todos los países",
  },
  {
    label: "Mongolia",
    value: "Mongolia",
    code: "MN",
    groupTitle: "Todos los países",
  },
  {
    label: "Montenegro",
    value: "Montenegro",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "ME",
    groupTitle: "Todos los países",
  },
  {
    label: "Montserrat",
    value: "Montserrat",
    code: "MS",
    groupTitle: "Todos los países",
  },
  {
    label: "Mozambique",
    value: "Mozambique",
    code: "MZ",
    groupTitle: "Todos los países",
  },
  {
    label: "México",
    value: "México",
    code: "MX",
    groupTitle: "Todos los países",
  },
  {
    label: "Mónaco",
    value: "Mónaco",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "MC",
    groupTitle: "Todos los países",
  },
  {
    label: "Naciones Unidas",
    value: "Naciones Unidas",
    code: "UN",
    groupTitle: "Todos los países",
  },
  {
    label: "Namibia",
    value: "Namibia",
    code: "NA",
    groupTitle: "Todos los países",
  },
  {
    label: "Nauru",
    value: "Nauru",
    code: "NR",
    groupTitle: "Todos los países",
  },
  {
    label: "Nepal",
    value: "Nepal",
    code: "NP",
    groupTitle: "Todos los países",
  },
  {
    label: "Nicaragua",
    value: "Nicaragua",
    code: "NI",
    groupTitle: "Todos los países",
  },
  {
    label: "Niger",
    value: "Niger",
    code: "NE",
    groupTitle: "Todos los países",
  },
  {
    label: "Nigeria",
    value: "Nigeria",
    code: "NG",
    groupTitle: "Todos los países",
  },
  {
    label: "Niue",
    value: "Niue",
    code: "NU",
    groupTitle: "Todos los países",
  },
  {
    label: "Noruega",
    value: "Noruega",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "NO",
    groupTitle: "Todos los países",
  },
  {
    label: "Nueva Caledonia",
    value: "Nueva Caledonia",
    code: "NC",
    groupTitle: "Todos los países",
  },
  {
    label: "Nueva Zelanda",
    value: "Nueva Zelanda",
    code: "NZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Omán",
    value: "Omán",
    code: "OM",
    groupTitle: "Todos los países",
  },
  {
    label: "Pakistán",
    value: "Pakistán",
    code: "PK",
    groupTitle: "Todos los países",
  },
  {
    label: "Palau",
    value: "Palau",
    code: "PW",
    groupTitle: "Todos los países",
  },
  {
    label: "Palestina",
    value: "Palestina",
    code: "PS",
    groupTitle: "Todos los países",
  },
  {
    label: "Panamá",
    value: "Panamá",
    code: "PA",
    groupTitle: "Todos los países",
  },
  {
    label: "Papúa Nueva Guinea",
    value: "Papúa Nueva Guinea",
    code: "PG",
    groupTitle: "Todos los países",
  },
  {
    label: "Paraguay",
    value: "Paraguay",
    code: "PY",
    groupTitle: "Todos los países",
  },
  {
    label: "Países Bajos",
    value: "Países Bajos",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "NL",
    groupTitle: "Todos los países",
  },
  {
    label: "Perú",
    value: "Perú",
    code: "PE",
    groupTitle: "Todos los países",
  },
  {
    label: "Polinesia Francesa",
    value: "Polinesia Francesa",
    code: "PF",
    groupTitle: "Todos los países",
  },
  {
    label: "Polonia",
    value: "Polonia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "PL",
    groupTitle: "Todos los países",
  },
  {
    label: "Portugal",
    value: "Portugal",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "PT",
    groupTitle: "Todos los países",
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
    code: "PR",
    groupTitle: "Todos los países",
  },
  {
    label: "Qatar",
    value: "Qatar",
    code: "QA",
    groupTitle: "Todos los países",
  },
  {
    label: "Reino Unido",
    value: "Reino Unido",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "UK",
    groupTitle: "Todos los países",
  },
  {
    label: "Reino Unido de Gran Bretaña e Irlanda del Norte",
    value: "Reino Unido de Gran Bretaña e Irlanda del Norte",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "GB",
    groupTitle: "Todos los países",
  },
  {
    label: "República Centroafricana",
    value: "República Centroafricana",
    code: "CF",
    groupTitle: "Todos los países",
  },
  {
    label: "República Checa",
    value: "República Checa",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "CZ",
    groupTitle: "Todos los países",
  },
  {
    label: "República de Sudán del Sur",
    value: "República de Sudán del Sur",
    code: "SS",
    groupTitle: "Todos los países",
  },
  {
    label: "República del Congo",
    value: "República del Congo",
    code: "CG",
    groupTitle: "Todos los países",
  },
  {
    label: "República Democrática del Congo",
    value: "República Democrática del Congo",
    code: "CD",
    groupTitle: "Todos los países",
  },
  {
    label: "República Dominicana",
    value: "República Dominicana",
    code: "DO",
    groupTitle: "Todos los países",
  },
  {
    label: "Reunión",
    value: "Reunión",
    code: "RE",
    groupTitle: "Todos los países",
  },
  {
    label: "Ruanda",
    value: "Ruanda",
    code: "RW",
    groupTitle: "Todos los países",
  },
  {
    label: "Rumanía",
    value: "Rumanía",
    code: "RO",
    groupTitle: "Todos los países",
  },
  {
    label: "Rusia",
    value: "Rusia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "RU",
    groupTitle: "Todos los países",
  },
  {
    label: "Sahara Occidental",
    value: "Sahara Occidental",
    code: "EH",
    groupTitle: "Todos los países",
  },
  {
    label: "Samoa",
    value: "Samoa",
    code: "WS",
    groupTitle: "Todos los países",
  },
  {
    label: "Samoa Americana",
    value: "Samoa Americana",
    code: "AS",
    groupTitle: "Todos los países",
  },
  {
    label: "San Bartolomé",
    value: "San Bartolomé",
    code: "BL",
    groupTitle: "Todos los países",
  },
  {
    label: "San Cristóbal y Nieves",
    value: "San Cristóbal y Nieves",
    code: "KN",
    groupTitle: "Todos los países",
  },
  {
    label: "San Marino",
    value: "San Marino",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "SM",
    groupTitle: "Todos los países",
  },
  {
    label: "San Martín (Francia)",
    value: "San Martín (Francia)",
    region: "europa",
    code: "MF",
    groupTitle: "Todos los países",
  },
  {
    label: "San Pedro y Miquelón",
    value: "San Pedro y Miquelón",
    code: "PM",
    groupTitle: "Todos los países",
  },
  {
    label: "San Vicente y las Granadinas",
    value: "San Vicente y las Granadinas",
    code: "VC",
    groupTitle: "Todos los países",
  },
  {
    label: "Santa Elena",
    value: "Santa Elena",
    code: "SH",
    groupTitle: "Todos los países",
  },
  {
    label: "Santa Lucía",
    value: "Santa Lucía",
    code: "LC",
    groupTitle: "Todos los países",
  },
  {
    label: "Santo Tomé y Príncipe",
    value: "Santo Tomé y Príncipe",
    code: "ST",
    groupTitle: "Todos los países",
  },
  {
    label: "Senegal",
    value: "Senegal",
    code: "SN",
    groupTitle: "Todos los países",
  },
  {
    label: "Serbia",
    value: "Serbia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "RS",
    groupTitle: "Todos los países",
  },
  {
    label: "Seychelles",
    value: "Seychelles",
    code: "SC",
    groupTitle: "Todos los países",
  },
  {
    label: "Sierra Leona",
    value: "Sierra Leona",
    code: "SL",
    groupTitle: "Todos los países",
  },
  {
    label: "Singapur",
    value: "Singapur",
    code: "SG",
    groupTitle: "Todos los países",
  },
  {
    label: "Sint Maarten",
    value: "Sint Maarten",
    code: "SX",
    groupTitle: "Todos los países",
  },
  {
    label: "Siria",
    value: "Siria",
    caserRegion: CaserRegion.Europe,
    code: "SY",
    groupTitle: "Todos los países",
  },
  {
    label: "Somalia",
    value: "Somalia",
    code: "SO",
    groupTitle: "Todos los países",
  },
  {
    label: "Sri lanka",
    value: "Sri lanka",
    code: "LK",
    groupTitle: "Todos los países",
  },
  {
    label: "Sudáfrica",
    value: "Sudáfrica",
    code: "ZA",
    groupTitle: "Todos los países",
  },
  {
    label: "Sudán",
    value: "Sudán",
    code: "SD",
    groupTitle: "Todos los países",
  },
  {
    label: "Suecia",
    value: "Suecia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "SE",
    groupTitle: "Todos los países",
  },
  {
    label: "Suiza",
    value: "Suiza",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "CH",
    groupTitle: "Todos los países",
  },
  {
    label: "Surinám",
    value: "Surinám",
    code: "SR",
    groupTitle: "Todos los países",
  },
  {
    label: "Svalbard y Jan Mayen",
    value: "Svalbard y Jan Mayen",
    code: "SJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Swazilandia",
    value: "Swazilandia",
    code: "SZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Tailandia",
    value: "Tailandia",
    code: "TH",
    groupTitle: "Todos los países",
  },
  {
    label: "Taiwán",
    value: "Taiwán",
    code: "TW",
    groupTitle: "Todos los países",
  },
  {
    label: "Tanzania",
    value: "Tanzania",
    code: "TZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Tayikistán",
    value: "Tayikistán",
    code: "TJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Territorio Británico del Océano Índico",
    value: "Territorio Británico del Océano Índico",
    code: "IO",
    groupTitle: "Todos los países",
  },
  {
    label: "Territorios Australes y Antárticas Franceses",
    value: "Territorios Australes y Antárticas Franceses",
    code: "TF",
    groupTitle: "Todos los países",
  },
  {
    label: "Timor Oriental",
    value: "Timor Oriental",
    code: "TL",
    groupTitle: "Todos los países",
  },
  {
    label: "Togo",
    value: "Togo",
    code: "TG",
    groupTitle: "Todos los países",
  },
  {
    label: "Tokelau",
    value: "Tokelau",
    code: "TK",
    groupTitle: "Todos los países",
  },
  {
    label: "Tonga",
    value: "Tonga",
    code: "TO",
    groupTitle: "Todos los países",
  },
  {
    label: "Trinidad y Tobago",
    value: "Trinidad y Tobago",
    code: "TT",
    groupTitle: "Todos los países",
  },
  {
    label: "Tristán de Acuña",
    value: "Tristán de Acuña",
    code: "TA",
    groupTitle: "Todos los países",
  },
  {
    label: "Tunez",
    value: "Tunez",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "TN",
    groupTitle: "Todos los países",
  },
  {
    label: "Turkmenistán",
    value: "Turkmenistán",
    code: "TM",
    groupTitle: "Todos los países",
  },
  {
    label: "Turquía",
    value: "Turquía",
    code: "TR",
    caserRegion: CaserRegion.Europe,
    groupTitle: "Todos los países",
  },
  {
    label: "Tuvalu",
    value: "Tuvalu",
    code: "TV",
    groupTitle: "Todos los países",
  },
  {
    label: "Ucrania",
    value: "Ucrania",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "UA",
    groupTitle: "Todos los países",
  },
  {
    label: "Uganda",
    value: "Uganda",
    code: "UG",
    groupTitle: "Todos los países",
  },
  {
    label: "Unión Soviética",
    value: "Unión Soviética",
    code: "SU",
    groupTitle: "Todos los países",
  },
  {
    label: "Uruguay",
    value: "Uruguay",
    code: "UY",
    groupTitle: "Todos los países",
  },
  {
    label: "Uzbekistán",
    value: "Uzbekistán",
    code: "UZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Vanuatu",
    value: "Vanuatu",
    code: "VU",
    groupTitle: "Todos los países",
  },
  {
    label: "Venezuela",
    value: "Venezuela",
    code: "VE",
    groupTitle: "Todos los países",
  },
  {
    label: "Vietnam",
    value: "Vietnam",
    code: "VN",
    groupTitle: "Todos los países",
  },
  {
    label: "Wallis y Futuna",
    value: "Wallis y Futuna",
    code: "WF",
    groupTitle: "Todos los países",
  },
  {
    label: "Yemen",
    value: "Yemen",
    code: "YE",
    groupTitle: "Todos los países",
  },
  {
    label: "Yibuti",
    value: "Yibuti",
    code: "DJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Zambia",
    value: "Zambia",
    code: "ZM",
    groupTitle: "Todos los países",
  },
  {
    label: "Zimbabue",
    value: "Zimbabue",
    code: "ZW",
    groupTitle: "Todos los países",
  },
];

export const userCountriesListSpanish = [
  { label: "España", value: "España", isoCode: "ES" },
  { label: "Afganistán", value: "Afganistán", isoCode: "AF" },
  { label: "Albania", value: "Albania", isoCode: "AL" },
  { label: "Alemania", value: "Alemania", isoCode: "DE" },
  { label: "Andorra", value: "Andorra", isoCode: "AD" },
  { label: "Angola", value: "Angola", isoCode: "AO" },
  { label: "Anguila", value: "Anguila", isoCode: "AI" },
  { label: "Antigua y Barbuda", value: "Antigua y Barbuda", isoCode: "AG" },
  { label: "Antártida", value: "Antártida", isoCode: "AQ" },
  { label: "Arabia Saudita", value: "Arabia Saudita", isoCode: "SA" },
  { label: "Argelia", value: "Argelia", isoCode: "DZ" },
  { label: "Argentina", value: "Argentina", isoCode: "AR" },
  { label: "Armenia", value: "Armenia", isoCode: "AM" },
  { label: "Aruba", value: "Aruba", isoCode: "AW" },
  { label: "Australia", value: "Australia", isoCode: "AU" },
  { label: "Austria", value: "Austria", isoCode: "AT" },
  { label: "Azerbaiyán", value: "Azerbaiyán", isoCode: "AZ" },
  { label: "Bahamas", value: "Bahamas", isoCode: "BS" },
  { label: "Bahrein", value: "Bahrein", isoCode: "BH" },
  { label: "Bangladesh", value: "Bangladesh", isoCode: "BD" },
  { label: "Barbados", value: "Barbados", isoCode: "BB" },
  { label: "Belice", value: "Belice", isoCode: "BZ" },
  { label: "Benín", value: "Benín", isoCode: "BJ" },
  { label: "Bhután", value: "Bhután", isoCode: "BT" },
  { label: "Bielorrusia", value: "Bielorrusia", isoCode: "BY" },
  { label: "Birmania", value: "Birmania", isoCode: "MM" },
  { label: "Bolivia", value: "Bolivia", isoCode: "BO" },
  { label: "Bonaire, San Eustaquio y Saba", value: "Bonaire, San Eustaquio y Saba", isoCode: "BQ" },
  { label: "Bosnia y Herzegovina", value: "Bosnia y Herzegovina", isoCode: "BA" },
  { label: "Botsuana", value: "Botsuana", isoCode: "BW" },
  { label: "Brasil", value: "Brasil", isoCode: "BR" },
  { label: "Brunéi", value: "Brunéi", isoCode: "BN" },
  { label: "Bulgaria", value: "Bulgaria", isoCode: "BG" },
  { label: "Burkina Faso", value: "Burkina Faso", isoCode: "BF" },
  { label: "Burundi", value: "Burundi", isoCode: "BI" },
  { label: "Bélgica", value: "Bélgica", isoCode: "BE" },
  { label: "Cabo Verde", value: "Cabo Verde", isoCode: "CV" },
  { label: "Camboya", value: "Camboya", isoCode: "KH" },
  { label: "Camerún", value: "Camerún", isoCode: "CM" },
  { label: "Canadá", value: "Canadá", isoCode: "CA" },
  { label: "Ceuta y Melilla", value: "Ceuta y Melilla", isoCode: "EA" },
  { label: "Chad", value: "Chad", isoCode: "TD" },
  { label: "Chile", value: "Chile", isoCode: "CL" },
  { label: "China", value: "China", isoCode: "CN" },
  { label: "Chipre", value: "Chipre", isoCode: "CY" },
  { label: "Ciudad del Vaticano", value: "Ciudad del Vaticano", isoCode: "VA" },
  { label: "Colombia", value: "Colombia", isoCode: "CO" },
  { label: "Comoras", value: "Comoras", isoCode: "KM" },
  { label: "Corea del Norte", value: "Corea del Norte", isoCode: "KP" },
  { label: "Corea del Sur", value: "Corea del Sur", isoCode: "KR" },
  { label: "Costa de Marfil", value: "Costa de Marfil", isoCode: "CI" },
  { label: "Costa Rica", value: "Costa Rica", isoCode: "CR" },
  { label: "Croacia", value: "Croacia", isoCode: "HR" },
  { label: "Cuba", value: "Cuba", isoCode: "CU" },
  { label: "Curazao", value: "Curazao", isoCode: "CW" },
  { label: "Diego García", value: "Diego García", isoCode: "DG" },
  { label: "Dinamarca", value: "Dinamarca", isoCode: "DK" },
  { label: "Dominica", value: "Dominica", isoCode: "DM" },
  { label: "Ecuador", value: "Ecuador", isoCode: "EC" },
  { label: "Egipto", value: "Egipto", isoCode: "EG" },
  { label: "El Salvador", value: "El Salvador", isoCode: "SV" },
  { label: "Emiratos Árabes Unidos", value: "Emiratos Árabes Unidos", isoCode: "AE" },
  { label: "Eritrea", value: "Eritrea", isoCode: "ER" },
  { label: "Eslovaquia", value: "Eslovaquia", isoCode: "SK" },
  { label: "Eslovenia", value: "Eslovenia", isoCode: "SI" },
  { label: "Estados Unidos de América", value: "Estados Unidos de América", isoCode: "US" },
  { label: "Estonia", value: "Estonia", isoCode: "EE" },
  { label: "Etiopía", value: "Etiopía", isoCode: "ET" },
  { label: "Filipinas", value: "Filipinas", isoCode: "PH" },
  { label: "Finlandia", value: "Finlandia", isoCode: "FI" },
  { label: "Fiyi", value: "Fiyi", isoCode: "FJ" },
  { label: "Francia", value: "Francia", isoCode: "FR" },
  { label: "Gabón", value: "Gabón", isoCode: "GA" },
  { label: "Gambia", value: "Gambia", isoCode: "GM" },
  { label: "Georgia", value: "Georgia", isoCode: "GE" },
  { label: "Ghana", value: "Ghana", isoCode: "GH" },
  { label: "Gibraltar", value: "Gibraltar", isoCode: "GI" },
  { label: "Granada", value: "Granada", isoCode: "GD" },
  { label: "Grecia", value: "Grecia", isoCode: "GR" },
  { label: "Groenlandia", value: "Groenlandia", isoCode: "GL" },
  { label: "Guadalupe", value: "Guadalupe", isoCode: "GP" },
  { label: "Guam", value: "Guam", isoCode: "GU" },
  { label: "Guatemala", value: "Guatemala", isoCode: "GT" },
  { label: "Guayana Francesa", value: "Guayana Francesa", isoCode: "GF" },
  { label: "Guernsey", value: "Guernsey", isoCode: "GG" },
  { label: "Guinea", value: "Guinea", isoCode: "GN" },
  { label: "Guinea Ecuatorial", value: "Guinea Ecuatorial", isoCode: "GQ" },
  { label: "Guinea-Bissau", value: "Guinea-Bissau", isoCode: "GW" },
  { label: "Haití", value: "Haití", isoCode: "HT" },
  { label: "Guyana", value: "Guyana", isoCode: "GY" },
  { label: "Honduras", value: "Honduras", isoCode: "HN" },
  { label: "Hong kong", value: "Hong kong", isoCode: "HK" },
  { label: "Hungría", value: "Hungría", isoCode: "HU" },
  { label: "India", value: "India", isoCode: "IN" },
  { label: "Indonesia", value: "Indonesia", isoCode: "ID" },
  { label: "Irak", value: "Irak", isoCode: "IQ" },
  { label: "Irlanda", value: "Irlanda", isoCode: "IE" },
  { label: "Irán", value: "Irán", isoCode: "IR" },
  { label: "Isla Ascensión", value: "Isla Ascensión", isoCode: "AC" },
  { label: "Isla Bouvet", value: "Isla Bouvet", isoCode: "BV" },
  { label: "Isla Clipperton", value: "Isla Clipperton", isoCode: "CP" },
  { label: "Isla de Man", value: "Isla de Man", isoCode: "IM" },
  { label: "Isla de Navidad", value: "Isla de Navidad", isoCode: "CX" },
  { label: "Isla Norfolk", value: "Isla Norfolk", isoCode: "NF" },
  { label: "Islandia", value: "Islandia", isoCode: "IS" },
  { label: "Islas Bermudas", value: "Islas Bermudas", isoCode: "BM" },
  { label: "Islas Caimán", value: "Islas Caimán", isoCode: "KY" },
  { label: "Islas Canarias", value: "Islas Canarias", isoCode: "ES" },
  { label: "Islas Cocos (Keeling)", value: "Islas Cocos (Keeling)", isoCode: "CC" },
  { label: "Islas Cook", value: "Islas Cook", isoCode: "CK" },
  { label: "Islas de Åland", value: "Islas de Åland", isoCode: "AX" },
  { label: "Islas Feroe", value: "Islas Feroe", isoCode: "FO" },
  { label: "Islas Georgias del Sur y Sandwich del Sur", value: "Islas Georgias del Sur y Sandwich del Sur", isoCode: "GS" },
  { label: "Islas Heard y McDonald", value: "Islas Heard y McDonald", isoCode: "HM" },
  { label: "Islas Maldivas", value: "Islas Maldivas", isoCode: "MV" },
  { label: "Islas Malvinas", value: "Islas Malvinas", isoCode: "FK" },
  { label: "Islas Marianas del Norte", value: "Islas Marianas del Norte", isoCode: "MP" },
  { label: "Islas Marshall", value: "Islas Marshall", isoCode: "MH" },
  { label: "Islas Pitcairn", value: "Islas Pitcairn", isoCode: "PN" },
  { label: "Islas Salomón", value: "Islas Salomón", isoCode: "SB" },
  { label: "Islas Turcas y Caicos", value: "Islas Turcas y Caicos", isoCode: "TC" },
  { label: "Islas Ultramarinas Menores de Estados Unidos", value: "Islas Ultramarinas Menores de Estados Unidos", isoCode: "UM" },
  { label: "Islas Vírgenes Británicas", value: "Islas Vírgenes Británicas", isoCode: "VG" },
  { label: "Islas Vírgenes de los Estados Unidos", value: "Islas Vírgenes de los Estados Unidos", isoCode: "VI" },
  { label: "Israel", value: "Israel", isoCode: "IL" },
  { label: "Italia", value: "Italia", isoCode: "IT" },
  { label: "Jamaica", value: "Jamaica", isoCode: "JM" },
  { label: "Japón", value: "Japón", isoCode: "JP" },
  { label: "Jersey", value: "Jersey", isoCode: "JE" },
  { label: "Jordania", value: "Jordania", isoCode: "JO" },
  { label: "Kazajistán", value: "Kazajistán", isoCode: "KZ" },
  { label: "Kenia", value: "Kenia", isoCode: "KE" },
  { label: "Kirguistán", value: "Kirguistán", isoCode: "KG" },
  { label: "Kiribati", value: "Kiribati", isoCode: "KI" },
  { label: "Kosovo", value: "Kosovo", isoCode: "XK" },
  { label: "Kuwait", value: "Kuwait", isoCode: "KW" },
  { label: "Laos", value: "Laos", isoCode: "LA" },
  { label: "Lesoto", value: "Lesoto", isoCode: "LS" },
  { label: "Letonia", value: "Letonia", isoCode: "LV" },
  { label: "Liberia", value: "Liberia", isoCode: "LR" },
  { label: "Libia", value: "Libia", isoCode: "LY" },
  { label: "Liechtenstein", value: "Liechtenstein", isoCode: "LI" },
  { label: "Lituania", value: "Lituania", isoCode: "LT" },
  { label: "Luxemburgo", value: "Luxemburgo", isoCode: "LU" },
  { label: "Líbano", value: "Líbano", isoCode: "LB" },
  { label: "Macao", value: "Macao", isoCode: "MO" },
  { label: "Macedônia", value: "Macedônia", isoCode: "MK" },
  { label: "Madagascar", value: "Madagascar", isoCode: "MG" },
  { label: "Malasia", value: "Malasia", isoCode: "MY" },
  { label: "Malawi", value: "Malawi", isoCode: "MW" },
  { label: "Mali", value: "Mali", isoCode: "ML" },
  { label: "Malta", value: "Malta", isoCode: "MT" },
  { label: "Marruecos", value: "Marruecos", isoCode: "MA" },
  { label: "Martinica", value: "Martinica", isoCode: "MQ" },
  { label: "Mauricio", value: "Mauricio", isoCode: "MU" },
  { label: "Mauritania", value: "Mauritania", isoCode: "MR" },
  { label: "Mayotte", value: "Mayotte", isoCode: "YT" },
  { label: "Micronesia", value: "Micronesia", isoCode: "FM" },
  { label: "Moldavia", value: "Moldavia", isoCode: "MD" },
  { label: "Mongolia", value: "Mongolia", isoCode: "MN" },
  { label: "Montenegro", value: "Montenegro", isoCode: "ME" },
  { label: "Montserrat", value: "Montserrat", isoCode: "MS" },
  { label: "Mozambique", value: "Mozambique", isoCode: "MZ" },
  { label: "México", value: "México", isoCode: "MX" },
  { label: "Mónaco", value: "Mónaco", isoCode: "MC" },
  { label: "Naciones Unidas", value: "Naciones Unidas", isoCode: "UN" },
  { label: "Namibia", value: "Namibia", isoCode: "NA" },
  { label: "Nauru", value: "Nauru", isoCode: "NR" },
  { label: "Nepal", value: "Nepal", isoCode: "NP" },
  { label: "Nicaragua", value: "Nicaragua", isoCode: "NI" },
  { label: "Niger", value: "Niger", isoCode: "NE" },
  { label: "Nigeria", value: "Nigeria", isoCode: "NG" },
  { label: "Niue", value: "Niue", isoCode: "NU" },
  { label: "Noruega", value: "Noruega", isoCode: "NO" },
  { label: "Nueva Caledonia", value: "Nueva Caledonia", isoCode: "NC" },
  { label: "Nueva Zelanda", value: "Nueva Zelanda", isoCode: "NZ" },
  { label: "Omán", value: "Omán", isoCode: "OM" },
  { label: "Pakistán", value: "Pakistán", isoCode: "PK" },
  { label: "Palau", value: "Palau", isoCode: "PW" },
  { label: "Palestina", value: "Palestina", isoCode: "PS" },
  { label: "Panamá", value: "Panamá", isoCode: "PA" },
  { label: "Papúa Nueva Guinea", value: "Papúa Nueva Guinea", isoCode: "PG" },
  { label: "Paraguay", value: "Paraguay", isoCode: "PY" },
  { label: "Países Bajos", value: "Países Bajos", isoCode: "NL" },
  { label: "Perú", value: "Perú", isoCode: "PE" },
  { label: "Polinesia Francesa", value: "Polinesia Francesa", isoCode: "PF" },
  { label: "Polonia", value: "Polonia", isoCode: "PL" },
  { label: "Portugal", value: "Portugal", isoCode: "PT" },
  { label: "Puerto Rico", value: "Puerto Rico", isoCode: "PR" },
  { label: "Qatar", value: "Qatar", isoCode: "QA" },
  { label: "Reino Unido", value: "Reino Unido", isoCode: "GB" },
  { label: "Reino Unido de Gran Bretaña e Irlanda del Norte", value: "Reino Unido de Gran Bretaña e Irlanda del Norte", isoCode: "GB" },
  { label: "República Centroafricana", value: "República Centroafricana", isoCode: "CF" },
  { label: "República Checa", value: "República Checa", isoCode: "CZ" },
  { label: "República de Sudán del Sur", value: "República de Sudán del Sur", isoCode: "SS" },
  { label: "República del Congo", value: "República del Congo", isoCode: "CG" },
  { label: "República Democrática del Congo", value: "República Democrática del Congo", isoCode: "CD" },
  { label: "República Dominicana", value: "República Dominicana", isoCode: "DO" },
  { label: "Reunión", value: "Reunión", isoCode: "RE" },
  { label: "Ruanda", value: "Ruanda", isoCode: "RW" },
  { label: "Rumanía", value: "Rumanía", isoCode: "RO" },
  { label: "Rusia", value: "Rusia", isoCode: "RU" },
  { label: "Sahara Occidental", value: "Sahara Occidental", isoCode: "EH" },
  { label: "Samoa", value: "Samoa", isoCode: "WS" },
  { label: "Samoa Americana", value: "Samoa Americana", isoCode: "AS" },
  { label: "San Bartolomé", value: "San Bartolomé", isoCode: "BL" },
  { label: "San Cristóbal y Nieves", value: "San Cristóbal y Nieves", isoCode: "KN" },
  { label: "San Marino", value: "San Marino", isoCode: "SM" },
  { label: "San Martín (Francia)", value: "San Martín (Francia)", isoCode: "MF" },
  { label: "San Pedro y Miquelón", value: "San Pedro y Miquelón", isoCode: "PM" },
  { label: "San Vicente y las Granadinas", value: "San Vicente y las Granadinas", isoCode: "VC" },
  { label: "Santa Elena", value: "Santa Elena", isoCode: "SH" },
  { label: "Santa Lucía", value: "Santa Lucía", isoCode: "LC" },
  { label: "Santo Tomé y Príncipe", value: "Santo Tomé y Príncipe", isoCode: "ST" },
  { label: "Senegal", value: "Senegal", isoCode: "SN" },
  { label: "Serbia", value: "Serbia", isoCode: "RS" },
  { label: "Seychelles", value: "Seychelles", isoCode: "SC" },
  { label: "Sierra Leona", value: "Sierra Leona", isoCode: "SL" },
  { label: "Singapur", value: "Singapur", isoCode: "SG" },
  { label: "Sint Maarten", value: "Sint Maarten", isoCode: "SX" },
  { label: "Siria", value: "Siria", isoCode: "SY" },
  { label: "Somalia", value: "Somalia", isoCode: "SO" },
  { label: "Sri lanka", value: "Sri lanka", isoCode: "LK" },
  { label: "Sudáfrica", value: "Sudáfrica", isoCode: "ZA" },
  { label: "Sudán", value: "Sudán", isoCode: "SD" },
  { label: "Suecia", value: "Suecia", isoCode: "SE" },
  { label: "Suiza", value: "Suiza", isoCode: "CH" },
  { label: "Surinám", value: "Surinám", isoCode: "SR" },
  { label: "Svalbard y Jan Mayen", value: "Svalbard y Jan Mayen", isoCode: "SJ" },
  { label: "Swazilandia", value: "Swazilandia", isoCode: "SZ" },
  { label: "Tailandia", value: "Tailandia", isoCode: "TH" },
  { label: "Taiwán", value: "Taiwán", isoCode: "TW" },
  { label: "Tanzania", value: "Tanzania", isoCode: "TZ" },
  { label: "Tayikistán", value: "Tayikistán", isoCode: "TJ" },
  { label: "Territorio Británico del Océano Índico", value: "Territorio Británico del Océano Índico", isoCode: "IO" },
  { label: "Territorios Australes y Antárticas Franceses", value: "Territorios Australes y Antárticas Franceses", isoCode: "TF" },
  { label: "Timor Oriental", value: "Timor Oriental", isoCode: "TL" },
  { label: "Togo", value: "Togo", isoCode: "TG" },
  { label: "Tokelau", value: "Tokelau", isoCode: "TK" },
  { label: "Tonga", value: "Tonga", isoCode: "TO" },
  { label: "Trinidad y Tobago", value: "Trinidad y Tobago", isoCode: "TT" },
  { label: "Túnez", value: "Túnez", isoCode: "TN" },
  { label: "Turkmenistán", value: "Turkmenistán", isoCode: "TM" },
  { label: "Turquía", value: "Turquía", isoCode: "TR" },
  { label: "Tuvalu", value: "Tuvalu", isoCode: "TV" },
  { label: "Uganda", value: "Uganda", isoCode: "UG" },
  { label: "Ucrania", value: "Ucrania", isoCode: "UA" },
  { label: "Uruguay", value: "Uruguay", isoCode: "UY" },
  { label: "Uzbekistán", value: "Uzbekistán", isoCode: "UZ" },
  { label: "Vanuatu", value: "Vanuatu", isoCode: "VU" },
  { label: "Vaticano", value: "Vaticano", isoCode: "VA" },
  { label: "Venezuela", value: "Venezuela", isoCode: "VE" },
  { label: "Vietnam", value: "Vietnam", isoCode: "VN" },
  { label: "Wallis y Futuna", value: "Wallis y Futuna", isoCode: "WF" },
  { label: "Yemen", value: "Yemen", isoCode: "YE" },
  { label: "Yibuti", value: "Yibuti", isoCode: "DJ" },
  { label: "Zambia", value: "Zambia", isoCode: "ZM" },
  { label: "Zimbabue", value: "Zimbabue", isoCode: "ZW" }
];

export const userCountrySpanish = [
  { label: "España", value: "España", isoCode: "ES" },
];

export const documentTypeList = [
  { label: "DNI", value: "DNI" },
  { label: "Pasaporte", value: "Pasaporte" },
  { label: "CIF", value: "CIF" },
  { label: "NIE", value: "NIE" },
  { label: "Cédula ", value: "Cedula" },
];

export const documentTypeListAXA = [
  { label: "INE", value: "INE" },
  { label: "RFC", value: "RFC" },
  { label: "Pasaporte", value: "Pasaporte" },
  { label: "DNI", value: "DNI" },
];

export const documentTypeListAIG = [
  { label: "DNI", value: "DNI" },
  { label: "CIF", value: "CIF" },
  { label: "NIE", value: "NIE" },
];

export const documentTypeListCASER = [
  { label: "DNI", value: "DNI" },
  { label: "CIF", value: "CIF" },
  { label: "NIE", value: "NIE" },
];

export const documentTypeListTerrawind = [
  { label: "DNI", value: "DNI" },
  { label: "Pasaporte", value: "Pasaporte" },
  { label: "Cédula ", value: "Cedula" },
];

export const PAYMENT_STATUS = {
  ERROR_PAYMENT: "Error en el Pago",
  PAID: "Pagado",
  PENDING: "Pendiente de pago",
  ERROR_EMISSION: "Error Emisión",
  PAYMENT_DEFFERED: "Pago Diferido",
  COMPLETED: "Completada",
  CANCELLED: "Cancelada",
};

export const ANNUAL_CATEGOIRES = [
  "LARGAESTANCIA",
  "ANUALMULTIVIAJE",
  "PROFESORESVISITANTES",
  "REPATRIACION",
  "AVIRISAnualMultiviaje",
  "MultiviajeAnual",
  'MultiviajeAnualCO'
];

export const MID_CATEGOIRES = [
  "EstudiantesMx",
  "SingleTrip",
];

export const COLLABORATOR_PANEL_URL_PARAMETER_NAME = "fromCollaboratorPanel";

export const GENDER = [
  { label: 'Hombre', value: 'Hombre' },
  { label: 'Mujer', value: 'Mujer' },
]

export const ValidateRFC = (rfc2: any) => {

  const _rfc_pattern_pm = "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";

  const _rfc_pattern_pf = "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";

  const rfc = rfc2;

  return rfc.match(_rfc_pattern_pm) || rfc.match(_rfc_pattern_pf);
}

export const ValidatePasaporte = (numeroPasaporte: any) => {

  const pasaporteEspRegex = /^[a-z]{3}[0-9]{6}[a-z]?$/i;
  const pasaporteMexRegex = /^[A-Z0-9]{9}$/;

  return pasaporteEspRegex.test(numeroPasaporte) || pasaporteMexRegex.test(numeroPasaporte);
}

//GetLoalization
export function success(pos: any) {
return pos.coords;
}

export function errors(err: any) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}

export const timezoneToCountryMap: Record<string, string> = {
  "Africa/Abidjan": "CI",
  "Africa/Accra": "GH",
  "Africa/Addis_Ababa": "ET",
  "Africa/Algiers": "DZ",
  "Africa/Bamako": "ML",
  "Africa/Bangui": "CF",
  "Africa/Banjul": "GM",
  "Africa/Bissau": "GW",
  "Africa/Blantyre": "MW",
  "Africa/Brazzaville": "CG",
  "Africa/Bujumbura": "BI",
  "Africa/Cairo": "EG",
  "Africa/Casablanca": "MA",
  "Africa/Ceuta": "ES",
  "Africa/Conakry": "GN",
  "Africa/Dakar": "SN",
  "Africa/Dar_es_Salaam": "TZ",
  "Africa/Djibouti": "DJ",
  "Africa/Douala": "CM",
  "Africa/El_Aaiun": "EH",
  "Africa/Freetown": "SL",
  "Africa/Gaborone": "BW",
  "Africa/Harare": "ZW",
  "Africa/Johannesburg": "ZA",
  "Africa/Juba": "SS",
  "Africa/Kampala": "UG",
  "Africa/Khartoum": "SD",
  "Africa/Kigali": "RW",
  "Africa/Kinshasa": "CD",
  "Africa/Lagos": "NG",
  "Africa/Luanda": "AO",
  "Africa/Lubumbashi": "CD",
  "Africa/Malabo": "GQ",
  "Africa/Maputo": "MZ",
  "Africa/Maseru": "LS",
  "Africa/Mbabane": "SZ",
  "Africa/Mogadishu": "SO",
  "Africa/Nairobi": "KE",
  "Africa/Niamey": "NE",
  "Africa/Nouakchott": "MR",
  "Africa/Ouagadougou": "BF",
  "Africa/Porto-Novo": "BJ",
  "Africa/Sao_Tome": "ST",
  "Africa/Tunis": "TN",
  "Africa/Windhoek": "NA",
  "America/Adak": "US",
  "America/Anchorage": "US",
  "America/Anguilla": "AI",
  "America/Antigua": "AG",
  "America/Araguaina": "BR",
  "America/Argentina/Buenos_Aires": "AR",
  "America/Argentina/Catamarca": "AR",
  "America/Argentina/ComodRivadavia": "AR",
  "America/Argentina/Cordoba": "AR",
  "America/Argentina/Jujuy": "AR",
  "America/Argentina/La_Rioja": "AR",
  "America/Argentina/Mendoza": "AR",
  "America/Argentina/Rosario": "AR",
  "America/Argentina/Salta": "AR",
  "America/Argentina/Tucuman": "AR",
  "America/Asuncion": "PY",
  "America/Atikokan": "CA",
  "America/Bahia": "BR",
  "America/Bahia_Banderas": "MX",
  "America/Barbados": "BB",
  "America/Belem": "BR",
  "America/Belize": "BZ",
  "America/Blanc-Sablon": "CA",
  "America/Boa_Vista": "BR",
  "America/Bogota": "CO",
  "America/Boise": "US",
  "America/Cambridge_Bay": "CA",
  "America/Campo_Grande": "BR",
  "America/Cancun": "MX",
  "America/Caracas": "VE",
  "America/Cayenne": "GF",
  "America/Cayman": "KY",
  "America/Chicago": "US",
  "America/Chihuahua": "MX",
  "America/Colombia": "CO",
  "America/Costa_Rica": "CR",
  "America/Creston": "CA",
  "America/Cuiaba": "BR",
  "America/Curacao": "CW",
  "America/Dawson": "CA",
  "America/Dawson_Creek": "CA",
  "America/Denver": "US",
  "America/Detroit": "US",
  "America/Dominica": "DM",
  "America/Edmonton": "CA",
  "America/Eirunepe": "BR",
  "America/El_Salvador": "SV",
  "America/Fortaleza": "BR",
  "America/Glace_Bay": "CA",
  "America/Goose_Bay": "CA",
  "America/Grand_Turk": "TC",
  "America/Grenada": "GD",
  "America/Guadeloupe": "GP",
  "America/Guatemala": "GT",
  "America/Guayaquil": "EC",
  "America/Guyana": "GY",
  "America/Halifax": "CA",
  "America/Havana": "CU",
  "America/Hermosillo": "MX",
  "America/Indiana/Indianapolis": "US",
  "America/Indiana/Knox": "US",
  "America/Indiana/Marengo": "US",
  "America/Indiana/Petersburg": "US",
  "America/Indiana/Tell_City": "US",
  "America/Indiana/Vevay": "US",
  "America/Indianapolis": "US",
  "America/Inuvik": "CA",
  "America/Iqaluit": "CA",
  "America/Jamaica": "JM",
  "America/Japan": "JP",
  "America/Juarez": "MX",
  "America/Kentucky/Louisville": "US",
  "America/Kentucky/Monticello": "US",
  "America/Kralendijk": "BQ",
  "America/La_Paz": "BO",
  "America/Lima": "PE",
  "America/Los_Angeles": "US",
  "America/Louisville": "US",
  "America/Maceio": "BR",
  "America/Managua": "NI",
  "America/Manaus": "BR",
  "America/Marigot": "MF",
  "America/Martinique": "MQ",
  "America/Matamoros": "MX",
  "America/Mazatlan": "MX",
  "America/Menominee": "US",
  "America/Merida": "MX",
  "America/Mexico_City": "MX",
  "America/Miquelon": "PM",
  "America/Moncton": "CA",
  "America/Montevideo": "UY",
  "America/Montreal": "CA",
  "America/Nassau": "BS",
  "America/New_York": "US",
  "America/Nipigon": "CA",
  "America/Nome": "US",
  "America/Noronha": "BR",
  "America/North_Dakota/Beulah": "US",
  "America/North_Dakota/Center": "US",
  "America/North_Dakota/New_Salem": "US",
  "America/Ojinaga": "MX",
  "America/Panama": "PA",
  "America/Paramaribo": "SR",
  "America/Phoenix": "US",
  "America/Port-au-Prince": "HT",
  "America/Porto_Velho": "BR",
  "America/Puerto_Rico": "PR",
  "America/Punta_Arenas": "CL",
  "America/Rainy_River": "CA",
  "America/Rankin_Inlet": "CA",
  "America/Recife": "BR",
  "America/Regina": "CA",
  "America/Resolute": "CA",
  "America/Rio_Branco": "BR",
  "America/Santa_Isabel": "MX",
  "America/Santarem": "BR",
  "America/Santo_Domingo": "DO",
  "America/Sao_Paulo": "BR",
  "America/Scoresbysund": "GL",
  "America/Sitka": "US",
  "America/St_Johns": "CA",
  "America/Swift_Current": "CA",
  "America/Tegucigalpa": "HN",
  "America/Thule": "GL",
  "America/Thunder_Bay": "CA",
  "America/Toronto": "CA",
  "America/Tortola": "VG",
  "America/Vancouver": "CA",
  "America/Winnipeg": "CA",
  "America/Yakutat": "US",
  "America/Yellowknife": "CA",
  "Antarctica/Casey": "AQ",
  "Antarctica/Davis": "AQ",
  "Antarctica/DumontDUrville": "AQ",
  "Antarctica/McMurdo": "AQ",
  "Antarctica/Palmer": "AQ",
  "Antarctica/Syowa": "AQ",
  "Antarctica/Troll": "AQ",
  "Antarctica/Union": "AQ",
  "Arctic/Longyearbyen": "SJ",
  "Asia/Aden": "YE",
  "Asia/Almaty": "KZ",
  "Asia/Amman": "JO",
  "Asia/Aqtau": "KZ",
  "Asia/Aqtobe": "KZ",
  "Asia/Ashgabat": "TM",
  "Asia/Ashkhabad": "TM",
  "Asia/Baghdad": "IQ",
  "Asia/Baku": "AZ",
  "Asia/Bangkok": "TH",
  "Asia/Bishkek": "KG",
  "Asia/Brunei": "BN",
  "Asia/Calcutta": "IN",
  "Asia/Chita": "RU",
  "Asia/Choibalsan": "MN",
  "Asia/Colombo": "LK",
  "Asia/Damascus": "SY",
  "Asia/Dhaka": "BD",
  "Asia/Dili": "TL",
  "Asia/Dubai": "AE",
  "Asia/Dushanbe": "TJ",
  "Asia/Gaza": "PS",
  "Asia/Harbin": "CN",
  "Asia/Ho_Chi_Minh": "VN",
  "Asia/Hong_Kong": "HK",
  "Asia/Hovd": "MN",
  "Asia/Irkutsk": "RU",
  "Asia/Jakarta": "ID",
  "Asia/Jayapura": "ID",
  "Asia/Jerusalem": "IL",
  "Asia/Kabul": "AF",
  "Asia/Kamchatka": "RU",
  "Asia/Karachi": "PK",
  "Asia/Kathmandu": "NP",
  "Asia/Kolkata": "IN",
  "Asia/Krasnoyarsk": "RU",
  "Asia/Kuala_Lumpur": "MY",
  "Asia/Kuwait": "KW",
  "Asia/Magadan": "RU",
  "Asia/Makassar": "ID",
  "Asia/Manila": "PH",
  "Asia/Muscat": "OM",
  "Asia/Nicosia": "CY",
  "Asia/Novokuznetsk": "RU",
  "Asia/Novosibirsk": "RU",
  "Asia/Omsk": "RU",
  "Asia/Oral": "KZ",
  "Asia/Phnom_Penh": "KH",
  "Asia/Pontianak": "ID",
  "Asia/Qatar": "QA",
  "Asia/Qyzylorda": "KZ",
  "Asia/Riyadh": "SA",
  "Asia/Sakhalin": "RU",
  "Asia/Samarkand": "UZ",
  "Asia/Seoul": "KR",
  "Asia/Shanghai": "CN",
  "Asia/Singapore": "SG",
  "Asia/Taipei": "TW",
  "Asia/Tashkent": "UZ",
  "Asia/Tbilisi": "GE",
  "Asia/Tehran": "IR",
  "Asia/Thimphu": "BT",
  "Asia/Tokyo": "JP",
  "Asia/Ulaanbaatar": "MN",
  "Asia/Urumqi": "CN",
  "Asia/Vientiane": "LA",
  "Asia/Vladivostok": "RU",
  "Asia/Yakutsk": "RU",
  "Asia/Yangon": "MM",
  "Asia/Yekaterinburg": "RU",
  "Asia/Yerevan": "AM",
  "Atlantic/Azores": "PT",
  "Atlantic/Bermuda": "BM",
  "Atlantic/Canary": "ES",
  "Atlantic/Cape_Verde": "CV",
  "Atlantic/Faeroe": "FO",
  "Atlantic/Faroe": "FO",
  "Atlantic/Jan_Mayen": "NO",
  "Atlantic/Madeira": "PT",
  "Atlantic/Reykjavik": "IS",
  "Atlantic/South_Georgia": "GS",
  "Atlantic/St_Helena": "SH",
  "Australia/Adelaide": "AU",
  "Australia/Brisbane": "AU",
  "Australia/Broken_Hill": "AU",
  "Australia/Currie": "AU",
  "Australia/Darwin": "AU",
  "Australia/Eucla": "AU",
  "Australia/Hobart": "AU",
  "Australia/Lindeman": "AU",
  "Australia/Lord_Howe": "AU",
  "Australia/Melbourne": "AU",
  "Australia/Perth": "AU",
  "Australia/Sydney": "AU",
  "Etc/GMT": "ZZ",
  "Etc/GMT+0": "ZZ",
  "Etc/GMT+1": "ZZ",
  "Etc/GMT+2": "ZZ",
  "Etc/GMT+3": "ZZ",
  "Etc/GMT+4": "ZZ",
  "Etc/GMT+5": "ZZ",
  "Etc/GMT+6": "ZZ",
  "Etc/GMT+7": "ZZ",
  "Etc/GMT+8": "ZZ",
  "Etc/GMT+9": "ZZ",
  "Etc/GMT+10": "ZZ",
  "Etc/GMT+11": "ZZ",
  "Etc/GMT+12": "ZZ",
  "Etc/GMT-0": "ZZ",
  "Etc/GMT-1": "ZZ",
  "Etc/GMT-2": "ZZ",
  "Etc/GMT-3": "ZZ",
  "Etc/GMT-4": "ZZ",
  "Etc/GMT-5": "ZZ",
  "Etc/GMT-6": "ZZ",
  "Etc/GMT-7": "ZZ",
  "Etc/GMT-8": "ZZ",
  "Etc/GMT-9": "ZZ",
  "Etc/GMT-10": "ZZ",
  "Etc/GMT-11": "ZZ",
  "Etc/GMT-12": "ZZ",
  "Etc/UTC": "ZZ",
  "Europe/Amsterdam": "NL",
  "Europe/Andorra": "AD",
  "Europe/Athens": "GR",
  "Europe/Belfast": "GB",
  "Europe/Belgrade": "RS",
  "Europe/Berlin": "DE",
  "Europe/Bratislava": "SK",
  "Europe/Brussels": "BE",
  "Europe/Bucharest": "RO",
  "Europe/Budapest": "HU",
  "Europe/Busingen": "DE",
  "Europe/Chisinau": "MD",
  "Europe/Copenhagen": "DK",
  "Europe/Dublin": "IE",
  "Europe/Gibraltar": "GI",
  "Europe/Guernsey": "GG",
  "Europe/Helsinki": "FI",
  "Europe/Isle_of_Man": "IM",
  "Europe/Istanbul": "TR",
  "Europe/Jersey": "JE",
  "Europe/Kaliningrad": "RU",
  "Europe/Kiev": "UA",
  "Europe/Lisbon": "PT",
  "Europe/Ljubljana": "SI",
  "Europe/London": "GB",
  "Europe/Luxembourg": "LU",
  "Europe/Madrid": "ES",
  "Europe/Malta": "MT",
  "Europe/Minsk": "BY",
  "Europe/Monaco": "MC",
  "Europe/Moscow": "RU",
  "Europe/Nicosia": "CY",
  "Europe/Oslo": "NO",
  "Europe/Paris": "FR",
  "Europe/Podgorica": "ME",
  "Europe/Prague": "CZ",
  "Europe/Riga": "LV",
  "Europe/Rome": "IT",
  "Europe/Samara": "RU",
  "Europe/San_Marino": "SM",
  "Europe/Sarajevo": "BA",
  "Europe/Simferopol": "RU",
  "Europe/Skopje": "MK",
  "Europe/Sofia": "BG",
  "Europe/Stockholm": "SE",
  "Europe/Tallinn": "EE",
  "Europe/Tirane": "AL",
  "Europe/Tiraspol": "MD",
  "Europe/Uzhgorod": "UA",
  "Europe/Vaduz": "LI",
  "Europe/Vatican": "VA",
  "Europe/Vienna": "AT",
  "Europe/Vilnius": "LT",
  "Europe/Warsaw": "PL",
  "Europe/Zagreb": "HR",
  "Europe/Zaporozhye": "UA",
  "Indian/Antananarivo": "MG",
  "Indian/Chagos": "IO",
  "Indian/Christmas": "CX",
  "Indian/Cocos": "CC",
  "Indian/Comoro": "KM",
  "Indian/Mahe": "SC",
  "Indian/Maldives": "MV",
  "Indian/Mauritius": "MU",
  "Indian/Mayotte": "YT",
  "Indian/Reunion": "RE",
  "Pacific/Apia": "WS",
  "Pacific/Auckland": "NZ",
  "Pacific/Bougainville": "PG",
  "Pacific/Chatham": "NZ",
  "Pacific/Chuuk": "FM",
  "Pacific/Easter": "CL",
  "Pacific/Efate": "VU",
  "Pacific/Enderbury": "CK",
  "Pacific/Fakaofo": "TK",
  "Pacific/Fiji": "FJ",
  "Pacific/Galapagos": "EC",
  "Pacific/Gambier": "PF",
  "Pacific/Guadalcanal": "SB",
  "Pacific/Guam": "GU",
  "Pacific/Honolulu": "US",
  "Pacific/Kiritimati": "KI",
  "Pacific/Kosrae": "FM",
  "Pacific/Majuro": "MH",
  "Pacific/Midway": "US",
  "Pacific/Nauru": "NR",
  "Pacific/Niue": "NU",
  "Pacific/Norfolk": "AU",
  "Pacific/Noumea": "NC",
  "Pacific/Pago_Pago": "AS",
  "Pacific/Palau": "PW",
  "Pacific/Pitcairn": "PN",
  "Pacific/Pohnpei": "FM",
  "Pacific/Rarotonga": "CK",
  "Pacific/Saipan": "MP",
  "Pacific/Tahiti": "PF",
  "Pacific/Tarawa": "KI",
  "Pacific/Tongatapu": "TO",
  "Pacific/Wallis": "WF",
  "Pacific/Whangarei": "NZ",
  "UTC": "ZZ",
};

