import { call, put, takeLatest } from "redux-saga/effects";

import { axiosInstance } from "@api/axios";
import {
  getCodeByKeyUrl,
  createVisitUrl,
  getCollaboratorByCodeUrl,
  getCollaboratorCodesUrl,
} from "@api/Endpoint";
import { CodeActionTypes } from "../redux/actionTypes";
import {
  getCodeByKeySuccess,
  getCollaboratorByCodeSuccess,
  getCollaboratorCodesSuccess,
  setDefaultBrandColor,
} from "./CodeActions";

function* getCodeByKeySaga(action: any) {
  try {
    const { codeKey, visitData, modality } = action.payload;
    const url = getCodeByKeyUrl(codeKey, visitData, modality);

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response.status === 200) {
      const payload = response.data.data;
      yield put(getCodeByKeySuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      //Incase the code is not found, the page should still render
      yield put(setDefaultBrandColor("#fdc413"));
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    //Incase the code is not found, the page should still render
    yield put(setDefaultBrandColor("#fdc413"));
    console.log("err: ", err);
  }
}

function* getCollaboratorByCodeSaga(action: any) {
  try {
    const { codeKey, modality } = action.payload;
    const url = getCollaboratorByCodeUrl(codeKey, modality);

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response.status === 200) {
      const payload = response.data.data;
      yield put(getCollaboratorByCodeSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    console.log("err: ", err);
  }
}

function* createVisitSaga(action: any) {
  try {
    const requestPayload = action.payload;
    const url = createVisitUrl();

    const response: ResponseGenerator = yield call(
      axiosInstance.post,
      url,
      requestPayload
    );

    if (response.status === 200) {
      const successMessage = response.data.message;
      console.log("successMessage", successMessage);
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err: any) {
    console.log("err: ", err.response);
  }
}

function* getCollaboratorCodesSaga(action: any) {
  try {
    const { codeKey, modality=null, visitData = null, isNew = false } = action.payload;
    if(isNew){
      const codeUrl = getCodeByKeyUrl(codeKey, visitData, modality);
      const codeResponse: ResponseGenerator = yield call(axiosInstance.get, codeUrl);
      const codeData = codeResponse.data.data;
      yield put(getCodeByKeySuccess(codeData));
    }

    const collaboratorCodesUrl = getCollaboratorCodesUrl(codeKey, modality);
    const collaboratorCodesResponse: ResponseGenerator = yield call(axiosInstance.get, collaboratorCodesUrl);

    if (collaboratorCodesResponse.status === 200) {
      yield put(getCollaboratorCodesSuccess(collaboratorCodesResponse.data.data));
    } else {
      console.log("Error obteniendo códigos de referencia:", collaboratorCodesResponse.response.data.message);
    }
  } catch (err) {
    console.log("Error en getCollaboratorCodesSaga:", err);
  }
}


export function* codeSagas() {
  yield takeLatest(CodeActionTypes.GET_CODE_BY_KEY, getCodeByKeySaga);
  yield takeLatest(CodeActionTypes.CREATE_VISIT, createVisitSaga);
  yield takeLatest(
    CodeActionTypes.GET_COLLABORATOR_BY_CODE,
    getCollaboratorByCodeSaga
  );
  yield takeLatest(CodeActionTypes.GET_COLLABORATOR_CODES, getCollaboratorCodesSaga);
}
