const baseUrl = process.env.REACT_APP_API_BASE_URL;
const CODE_RASTREATOR:string= 'rastreator';
const CODE_GLOBAL_FINE_PRE: string = 'globalFineInvest';
const CODE_GLOBAL_FINE_PRO: string = 'globalfineInvest';
const CODE_GLOBAL_FINE_HOME_PRO: string = 'globalfinehome';
const CODE_TRAVEL_FINE_HOME_PRO: string = 'travelfinehome';

const CODE_HOME_MX = {
  codeGlabalFine: 'globalfinehomeMex',
  codeTravelfine: 'travelfinehomeMex'
}
const COLLABORATOR_TRAVEL = '6362b2ce14b718e49ddfeaad';
const GLOBAL_FINE: TravelType ={
    code:'globalfine',
    image:'https://travelfine.s3.eu-west-3.amazonaws.com/assets/budget-email-img7.png'
  }; 

const COOKIE_EXPIRE = 40
const HEALTH_INSURANCE_CODE ='HealthInsurance'
const HEALTH_INSURANCE_MJ_CODE ='Mundojoven'
const HEALTH_INSURANCE_TITLE = 'Health Insurance'

const IRIS_DEROGATION_70_80 = 'DER_80años2023';
const IRIS_DEROGATION_70_80_CRUCERO = 'travelfineO_c+8';
const IRIS_DEROGATION_CRUCERO = 'travelfineO_c';
const IRIS_DEROGATION_DEPORTE = 'travelfine_depo';
const IRIS_DEROGATION_DEPORTE_CRUCERO = 'travelfineO_ctravelfineO_d+c';

const WORKANDHOLIDAY = 'WORKANDHOLIDAY'
const HEALTHINSURANCE = 'HealthInsurance'
const HEALTH_INSURANCE_RETURN_DAY_MAXIMUM_RANGE = 396;
const MID_MAXIMUM_RANGE = 179;

const STUDENT_MEX= 'EstudiantesMx';
const SINGLE_TRIP_MEX = 'SingleTrip';
const ANNUAL_MEX = 'MultiviajeAnual';

const ORIGIN_ESP = {
  label: "España",
  value: "España y Andorra",
  code: "ES",
  region: "europa",
}

const CODE_MEXICO = 'MX';

const TYPE_CODE = {
  collaborator: 'collaborator',
  category: 'productCategory',
}
const PRODUCT_CURRENCY_DEFAULT = {
  label: 'Euro',
  rate: 1,
  value: 'EUR',
  symbol: '€'
};
const isNotVisableCountry=[
  "WORKANDHOLIDAY",
  "CANCELACIONINTERRUPCION",
  "HealthInsurance",
  "Mundojoven",
  "HI36"
]

const CATEGOIRES_TERRAWIND = [
  "EstudiantesCo",
  "LargaEstanciaCo",
  "MultiviajeAnualCO",
  "SegurodeviajeCO",
];
export {
    CODE_RASTREATOR,
    GLOBAL_FINE,
    CODE_GLOBAL_FINE_PRO,
    CODE_GLOBAL_FINE_PRE,
    COOKIE_EXPIRE,
    IRIS_DEROGATION_CRUCERO,
    IRIS_DEROGATION_70_80,
    IRIS_DEROGATION_70_80_CRUCERO,
    HEALTH_INSURANCE_CODE,
    HEALTH_INSURANCE_MJ_CODE,
    HEALTH_INSURANCE_TITLE,
    CODE_GLOBAL_FINE_HOME_PRO,
    WORKANDHOLIDAY,
    HEALTHINSURANCE,
    HEALTH_INSURANCE_RETURN_DAY_MAXIMUM_RANGE,
    IRIS_DEROGATION_DEPORTE,
    IRIS_DEROGATION_DEPORTE_CRUCERO,
    STUDENT_MEX,
    SINGLE_TRIP_MEX,
    ANNUAL_MEX,
    MID_MAXIMUM_RANGE,
    CODE_HOME_MX,
    ORIGIN_ESP,
    CODE_MEXICO,
    CODE_TRAVEL_FINE_HOME_PRO,
    TYPE_CODE,
    baseUrl,
    PRODUCT_CURRENCY_DEFAULT,
    isNotVisableCountry,
    CATEGOIRES_TERRAWIND,
    COLLABORATOR_TRAVEL
}