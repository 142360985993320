import { PolicyActionTypes } from "../redux/actionTypes";

export const createPolicy = (payload: any, navigate: any, formStripe:any) => ({
  type: PolicyActionTypes.CREATE_POLICY,
  payload,
  navigate,
  formStripe
});

export const setUpdatePasswordModal = (payload: any) => ({
  type: PolicyActionTypes.SET_UPDATE_PASSWORD_MODAL,
  payload,
});

export const getPolicies = (payload: any) => ({
  type: PolicyActionTypes.GET_POLICIES,
  payload,
});

export const getPoliciesSuccess = (payload: any) => ({
  type: PolicyActionTypes.GET_POLICIES_SUCCESS,
  payload,
});

export const getPolicyById = (payload: any) => ({
  type: PolicyActionTypes.GET_POLICY_BY_ID,
  payload,
});

export const getPolicyByIdSuccess = (payload: any) => ({
  type: PolicyActionTypes.GET_POLICY_BY_ID_SUCCESS,
  payload,
});

export const setPolicyLoader = (payload: any) => ({
  type: PolicyActionTypes.SET_POLICY_LOADER,
  payload,
});

export const getGeneratedPolicyPDF = (payload: any) => ({
  type: PolicyActionTypes.GET_GENERATED_POLICY_PDF,
  payload,
});

export const getGeneratedPolicyPDFSuccess = (payload: any) => ({
  type: PolicyActionTypes.GET_GENERATED_POLICY_PDF_SUCCESS,
  payload,
});
