import { DatePicker, Input, SelectAutoComplete } from "@components";
import { HEALTH_INSURANCE_TITLE } from "@constants/constants";
import { documentTypeList, documentTypeListAIG, documentTypeListAXA, documentTypeListCASER, documentTypeListTerrawind, GENDER, PRODUCT_PROVIDERS, userCountriesListSpanish, userCountrySpanish} from "@helpers/data";
import { useFormContext, useWatch } from "react-hook-form";
import { useLocation } from "react-router-dom";
import clsx from 'clsx';

interface TravellerFormProps {
  index: any;
  productProvider?: any;
  filter?: any;
}

interface ProductState {
  type: string;
  price: number;
  filter: {
    modality: {
      label: string;
      value: string;
      key: string;
      isHidden: boolean;
    };
    origin: {
      label: string;
      value: string;
      region: string;
      code: string;
      groupTitle: string;
    };
    destination: {
      label: string;
      value: string;
      code: string;
      groupTitle: string;
    };
    departureDate: string;
    returnDate: string;
    totalTravellers: number;
    codeKey: string;
    userInDestination: boolean;
    pricing: boolean;
    dontCallAPI: boolean;
  };
  productId: string;
  productOverCosts: {
    _id: string;
    id: string;
    title: string;
    overcost: number;
    key: string;
    limit: string;
    overcostAdded: number;
    overcostType: string;
    axaId?: number;
  }[];
  productProvider: string;
  coveragePdfFile: string;
  emailPdfFiles: string[];
  budgetId: string | null;
  irisExtraSelected: string | null;
  variantAllowBulkXlsxUpload: boolean;
}

const TravellerForm: React.FC<TravellerFormProps> = (props) => {
  const { state }: { state: ProductState } = useLocation();
  const {
    productProvider
  }: ProductState = state;

  const { index, filter } = props;
  const { control, setValue, formState } = useFormContext();
  const { errors } = formState;

  const name = useWatch({
    control,
    name: `travellers.${index}.name`,
  });
  const surname = useWatch({
    control,
    name: `travellers.${index}.surname`,
  });
  const secondSurname = useWatch({
    control,
    name: `travellers.${index}.secondSurname`,
  });
  const dob = useWatch({
    control,
    name: `travellers.${index}.dob`,
  });
  const documentType = useWatch({
    control,
    name: `travellers.${index}.documentType`,
  });
  const documentNumber = useWatch({
    control,
    name: `travellers.${index}.documentNumber`,
  });
  const gender = useWatch({
    control,
    name: `travellers.${index}.gender`,
  });
  const email = useWatch({
    control,
    name: `travellers.${index}.email`,
  });
  const phone = useWatch({
    control,
    name: `travellers.${index}.phone`,
  });
  const city = useWatch({
    control,
    name: `travellers.${index}.city`,
  });
  const country = useWatch({
    control,
    name: `travellers.${index}.country`,
  });
  const address = useWatch({
    control,
    name: `travellers.${index}.address`,
  });
  const postalCode = useWatch({
    control,
    name: `travellers.${index}.postalCode`,
  });
  const documentTypeListFinal =
    productProvider === PRODUCT_PROVIDERS.CASER
      ? documentTypeListCASER
      : productProvider === PRODUCT_PROVIDERS.AIG
        ? documentTypeListAIG
        : productProvider === PRODUCT_PROVIDERS.AXA
          ? documentTypeListAXA :
          productProvider === PRODUCT_PROVIDERS.TERRAWIND
            ? documentTypeListTerrawind : documentTypeList;

  const isExistHealth = (filter?.modality?.label?.toUpperCase()?.includes(HEALTH_INSURANCE_TITLE.toUpperCase()));
  return (
    <div className="flex flex-col p-4">
      <p className="mb-2 font-bold">Viajero {index + 1}</p>
      <div className="flex flex-wrap items-start">
        <div className={clsx(
          "w-full p-2",
          isExistHealth ? "sm:w-1/3 md:w-1/5" : "sm:w-1/2 md:w-1/3"
        )}>
          <Input
            label="Nombre"
            name={`travellers.${index}.name`}
            control={control}
            fullWidth
            error={!!errors?.travellers?.[index]?.name}
            errorMessage={errors?.travellers?.[index]?.name?.message}
            className=""
            size="small"
            required
            value={name}
            labelOutside
          />
        </div>

        <div className={clsx(
          "w-full p-2",
          isExistHealth ? "sm:w-1/3 md:w-1/5" : "sm:w-1/2 md:w-1/3"
        )}>
          <Input
            label={(productProvider === PRODUCT_PROVIDERS.CASER || productProvider === PRODUCT_PROVIDERS.AXA) ? 'Primer apellido' : 'Apellidos'}
            name={`travellers.${index}.surname`}
            control={control}
            fullWidth
            error={!!errors?.travellers?.[index]?.surname}
            errorMessage={errors?.travellers?.[index]?.surname?.message}
            className="w-full"
            size="small"
            required
            value={surname}
            labelOutside
          />
        </div>
        {(productProvider === PRODUCT_PROVIDERS.CASER || productProvider === PRODUCT_PROVIDERS.AXA) && (
          <div className={clsx(
            "w-full p-2",
            isExistHealth ? "sm:w-1/3 md:w-1/5" : "sm:w-1/2 md:w-1/3"
          )}>
            <Input
              label="Segundo apellido"
              name={`travellers.${index}.secondSurname`}
              control={control}
              fullWidth
              error={!!errors?.travellers?.[index]?.secondSurname}
              errorMessage={errors?.travellers?.[index]?.secondSurname?.message}
              className="w-full"
              size="small"
              value={secondSurname}
              required
              labelOutside
            />
          </div>
        )}
        {(isExistHealth) && (
          <>
            <div className={clsx(
              "w-full p-2",
              isExistHealth ? "sm:w-1/3 md:w-1/5" : "sm:w-1/2 md:w-1/3"
            )}>
              <Input
                label="Email"
                name={`travellers.${index}.email`}
                control={control}
                fullWidth
                error={!!errors?.travellers?.[index]?.email}
                errorMessage={errors?.travellers?.[index]?.email?.message}
                className="w-full"
                size="small"
                value={email}
                required
                labelOutside
              />
            </div>
            <div className={clsx(
              "w-full p-2",
              isExistHealth ? "sm:w-1/3 md:w-1/5" : "sm:w-1/2 md:w-1/3"
            )}>
              <SelectAutoComplete
                label="Pais"
                name={`travellers.${index}.country`}
                control={control}
                fullWidth
                error={!!errors?.travellers?.[index]?.country}
                errorMessage={errors?.travellers?.[index]?.country?.message}
                className="w-full"
                list={!isExistHealth ? userCountriesListSpanish : userCountrySpanish}
                size="small"
                disableClearable
                required
                labelOutside
                value={country ?? { value: null, label: 'Selecciona país' }}
                onChange={(newValue: any, field: any) => {
                  field.onChange(newValue.value);
                }}
              />
            </div>
            <div className={clsx(
              "w-full p-2",
              isExistHealth ? "sm:w-1/3 md:w-1/5" : "sm:w-1/2 md:w-1/3"
            )}>
              <Input
                label="Codigo Postal"
                name={`travellers.${index}.postalCode`}
                control={control}
                fullWidth
                error={!!errors?.travellers?.[index]?.postalCode}
                errorMessage={errors?.travellers?.[index]?.postalCode?.message}
                className="w-full numberInputWithoutArrows"
                size="small"
                value={postalCode}
                required
                labelOutside
              />
            </div>
            <div className={clsx(
              "w-full p-2",
              isExistHealth ? "sm:w-1/3 md:w-1/5" : "sm:w-1/2 md:w-1/3"
            )}>
              <Input
                label="Ciudad"
                name={`travellers.${index}.city`}
                control={control}
                fullWidth
                error={!!errors?.travellers?.[index]?.city}
                errorMessage={errors?.travellers?.[index]?.city?.message}
                className="w-full"
                size="small"
                required
                labelOutside
              />
            </div>
            <div className={clsx(
              "w-full p-2",
              isExistHealth ? "sm:w-1/3 md:w-1/5" : "sm:w-1/2 md:w-1/3"
            )}>
              <Input
                label="Dirección"
                name={`travellers.${index}.address`}
                control={control}
                fullWidth
                error={!!errors?.travellers?.[index]?.address}
                errorMessage={errors?.travellers?.[index]?.address?.message}
                className="w-full"
                size="small"
                value={address}
                required
                labelOutside
              />
            </div>
          </>
        )}
        {productProvider === PRODUCT_PROVIDERS.AXA && (<div className={clsx(
          "w-full p-2",
          isExistHealth ? "sm:w-1/3 md:w-1/5" : "sm:w-1/2 md:w-1/3"
        )}>
          <SelectAutoComplete
            label="Género"
            name={`travellers.${index}.gender`}
            control={control}
            fullWidth
            error={!!errors?.travellers?.[index]?.gender}
            errorMessage={errors?.travellers?.[index]?.gender?.message}
            className="w-full"
            list={GENDER}
            size="small"
            value={gender}
            disableClearable
            required={productProvider === PRODUCT_PROVIDERS.AXA}
            labelOutside
            onChange={(newValue: any, field: any) => {
              field.onChange(newValue.value);
            }} />
        </div>)}
        <div className={clsx(
          "w-full p-2",
          isExistHealth ? "sm:w-1/3 md:w-1/5" : "sm:w-1/2 md:w-1/3"
        )}>
          <DatePicker
            label="Fecha Nacimiento"
            name={`travellers.${index}.dob`}
            control={control}
            fullWidth
            error={!!errors?.travellers?.[index]?.dob}
            errorMessage={errors?.travellers?.[index]?.dob?.message}
            className="w-full"
            size="small"
            required
            value={dob}
            labelOutside
            disableTextField
          />
        </div>
        <div className={clsx(
          "w-full p-2",
          isExistHealth ? "sm:w-1/3 md:w-1/5" : "sm:w-1/2 md:w-1/3"
        )}>
          <SelectAutoComplete
            label="Tipo documento"
            name={`travellers.${index}.documentType`}
            control={control}
            fullWidth
            error={!!errors?.travellers?.[index]?.documentType}
            errorMessage={errors?.travellers?.[index]?.documentType?.message}
            className="w-full"
            list={documentTypeListFinal}
            size="small"
            disableClearable
            required
            labelOutside
            value={documentType}
            onChange={(newValue: any, field: any) => {
              field.onChange(newValue.value);
              setValue(`travellers.${index}.documentNumber`, "");
            }}
          />
        </div>
        <div className={clsx(
          "w-full p-2",
          isExistHealth ? "sm:w-1/3 md:w-1/5" : "sm:w-1/2 md:w-1/3"
        )}>
          <Input
            label="Nº documento"
            name={`travellers.${index}.documentNumber`}
            control={control}
            fullWidth
            error={!!errors?.travellers?.[index]?.documentNumber}
            errorMessage={errors?.travellers?.[index]?.documentNumber?.message}
            className="w-full"
            size="small"
            required
            labelOutside
            value={documentNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default TravellerForm;
