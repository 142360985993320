import { IRIS_DEROGATION_CRUCERO } from "@constants/constants";
import { differenceInYears } from "date-fns";

const MAXIMUN_AGE = 80;
const MINIMUN_AGE = 70;

const useIrisEldersExtra = (irisExtraSelected: any, irisExtraAllProduct: any[], departureDate: any) => {
  let usingIrisEldersExtra: any = false

  if (irisExtraSelected?.derogation === IRIS_DEROGATION_CRUCERO || irisExtraAllProduct?.length > 0) {
    usingIrisEldersExtra = true;
  }

  const irisMaxAge = usingIrisEldersExtra ? MAXIMUN_AGE : MINIMUN_AGE;
  const irisMinimunAge = usingIrisEldersExtra ? MINIMUN_AGE : 0;

  function checkMinimunAge(value: any) {
    if (!usingIrisEldersExtra) return true;

    const birthDate = new Date(value);
    const endOfYearCurrent = new Date();

    const ageCurrent = differenceInYears(endOfYearCurrent, birthDate);
    if (ageCurrent >= irisMinimunAge) {
      return ageCurrent <= irisMaxAge;
    } else {
      const dateOrigin = new Date(departureDate);
      const ageAtDeparture = differenceInYears(dateOrigin, birthDate);
      return ageAtDeparture <= irisMaxAge;
    }

  }

  const minimunAgeErrorText = `Con sobreprima de entre ${irisMinimunAge} y ${irisMaxAge} años, el asegurado no puede ser menor de ${irisMinimunAge} ni mayor de ${irisMaxAge} años`;

  return {
    irisMaxAge,
    irisMinimunAge,
    usingIrisEldersExtra,
    checkMinimunAge,
    minimunAgeErrorText,
  };
};

export { useIrisEldersExtra };
