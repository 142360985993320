import { useSelector } from "react-redux";
import { checkMinimunAge } from "@helpers/utils";
import { IRIS_DEROGATION_70_80, IRIS_DEROGATION_70_80_CRUCERO } from "@constants/constants";

const usePriceAndAdditionalFeesCalculatorIris = ({
  totalTravellers,
  watch,
  variant,
  isInsuredPolicyHolder,
  priceWithoutAdditionalFees,
  discountPercentage,
  currency,
  departureDate,
  productPriceOriginal,
  currencyProductOriginal
}: {
  totalTravellers: number;
  isInsuredPolicyHolder: boolean;
  watch: Function;
  variant?: string;
  priceWithoutAdditionalFees?: any,
  discountPercentage?: any,
  currency?: any,
  departureDate?: any,
  productPriceOriginal?: any,
  currencyProductOriginal?: any
}) => {

  const travellers = watch("travellers");
  const policyHolderBirthdate = watch("dob");
  const { irisExtraAll }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );
  const extra = irisExtraAll?.filter((extra: any) => extra?.derogation === IRIS_DEROGATION_70_80 || extra?.derogation === IRIS_DEROGATION_70_80_CRUCERO)[0];
  const infoExtra = extra?.title ?? '';

  const priceOriginal = Number.parseFloat(priceWithoutAdditionalFees) / totalTravellers;
  const priceExtra = extra?.productPricesCurrencies?.find((currencyObj: any) => currencyObj[currency?.value] !== undefined)?.[currency?.value] ?? 0;

  const priceOriginalPerPerson = Number.parseFloat(productPriceOriginal) / totalTravellers;
  const priceExtraOriginal = extra?.productPricesCurrencies?.find((currencyObj: any) => currencyObj[currencyProductOriginal] !== undefined)?.[currencyProductOriginal] ?? 0;
  let precioNetoExtraDivisa = extra?.productPricesCurrenciesNeto?.find(
    (currencyObj: any) => currencyObj[currency?.value] !== undefined
  );

  if (precioNetoExtraDivisa) {
    precioNetoExtraDivisa = precioNetoExtraDivisa[currency?.value];
  } else {
    precioNetoExtraDivisa = extra?.irisPriceObj?.Precio_Neto;
  }

  const priceNetoExtra = Number(((precioNetoExtraDivisa / totalTravellers) * getNumberOfElderIris())?.toFixed(2));
  
  let precioNetoExtraDivisaOriginal = extra?.productPricesCurrenciesNeto?.find(
    (currencyObj: any) => currencyObj[currencyProductOriginal] !== undefined
  );

  if (precioNetoExtraDivisaOriginal) {
    precioNetoExtraDivisaOriginal = precioNetoExtraDivisaOriginal[currencyProductOriginal];
  } else {
    precioNetoExtraDivisaOriginal = extra?.irisPriceObj?.Precio_Neto;
  }
  const priceNetoExtraOriginal = Number(((precioNetoExtraDivisaOriginal  / totalTravellers) * getNumberOfElderIris())?.toFixed(2));
  const elderCount = getNumberOfElderIris();

  function calculatePricing(pricePerPerson:any, priceExtra:any, basePrice:any) {
    let totalTraveller = totalTravellers - elderCount;
    const totalPrice = (elderCount * priceExtra) + (totalTraveller * pricePerPerson);
    const totalPriceBeforeDiscount = Number(totalPrice.toFixed(2));
    const totalPriceAfterDiscount = Number(getFinalPriceAfterDiscount(totalPrice).toFixed(2));
    const additionalFeeDueElders = totalPriceBeforeDiscount - basePrice;

    return {
      totalPriceBeforeDiscount,
      totalPriceAfterDiscount,
      additionalFeeDueElders
    };
  }
  const currencyPricing = calculatePricing(priceOriginal, priceExtra, priceWithoutAdditionalFees);
  const originalPricing = calculatePricing(priceOriginalPerPerson, priceExtraOriginal, productPriceOriginal);

  function getNumberOfElderIris() {
    let output = 0;
    if (irisExtraAll.length > 0) {
      if (isInsuredPolicyHolder && checkMinimunAge(policyHolderBirthdate, departureDate)) {
        output += 1;
      }
      travellers.forEach((traveller: any) => {
        if (checkMinimunAge(traveller.dob, departureDate)) {
          output += 1;
        }
      });
    }
    return output;
  }

  function getFinalPriceAfterDiscount(total:any, isWithoutDiscount = false) {
    let output = total;
    if (discountPercentage && !isWithoutDiscount) {
      output -= (discountPercentage * output) / 100;
    }
    return output;
  }

  return {
    elderCount,
    totalPriceBeforeDiscount: currencyPricing.totalPriceBeforeDiscount,
    totalPriceAfterDiscount: currencyPricing.totalPriceAfterDiscount,
    additionalFeeDueElders: currencyPricing.additionalFeeDueElders,
    totalPriceBeforeDiscountOriginal: originalPricing.totalPriceBeforeDiscount,
    totalPriceAfterDiscountOriginal: originalPricing.totalPriceAfterDiscount,
    additionalFeeDueEldersOriginal: originalPricing.additionalFeeDueElders,
    priceNetoExtra,
    priceNetoExtraOriginal,
    infoExtra,
    isExtraDerogation: elderCount > 0,
    sobrePrimaExtraIris: extra ? { ...extra?.extra } : null
  };
};

export default  usePriceAndAdditionalFeesCalculatorIris ;
