import { CategoryActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: CategoryState = {
  loading: false,
  categories: [],
  categoryMaximumRange: 364,
};

const CategoryReducer = (
  state: CategoryState = INITIAL_STATE,
  action: Action
): CategoryState => {
  switch (action.type) {
    case CategoryActionTypes.GET_CATEGORIES_SUCCESS:
      const categories = action.payload.categories
        ? action.payload.categories.map((category: any) => {
            return {
              label: category.title,
              value: category._id,
              key: category.key,
              isHidden: category.isHidden,
              index: category.index,
              regions: category.regions,
              isNotNational:category.isNotNational
            };
          })
        : [];
      return {
        ...state,
        categories: categories,
      };

    case CategoryActionTypes.GET_CATEGORY_MAXIMUM_RANGE_SUCCESS:
      return {
        ...state,
        categoryMaximumRange: action.payload.maximumEndRange,
      };

    case CategoryActionTypes.SET_CATEGORY_LOADER:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default CategoryReducer;
