import { SelectAutoComplete, DatePicker, Button, Input } from "@components";
import {
  originList,
  travellerList,
  ANNUAL_CATEGOIRES,
  getCountriesListSpanish,
  getOriginCountry,
  MID_CATEGOIRES,
  _countriesListSpanish,
} from "@helpers/data";
import { useEffect, useState } from "react";
import { add, differenceInDays, differenceInYears } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryMaximumRange } from "@store/category/CategoryActions";
import { CATEGOIRES_TERRAWIND, CODE_MEXICO, COLLABORATOR_TRAVEL, HEALTH_INSURANCE_RETURN_DAY_MAXIMUM_RANGE, HEALTHINSURANCE, isNotVisableCountry, MID_MAXIMUM_RANGE, STUDENT_MEX, WORKANDHOLIDAY } from "@constants/constants";
import iso31661 from "iso-3166-1";
import { useSearchParams } from "react-router-dom";
import RedirectModal from "./RedirectModal";
// import { sub } from "date-fns/esm";

interface FilterFormProps {
  control: any;
  handleSubmit: any;
  onSubmit: any;
  isValid: any;
  errors: any;
  watch: any;
  setValue: any;
  categoriesList: any;
  getValues: any;
  applyCollaboratorPanelStyles: boolean;
  countries?: any;
  isCountry?: any;
}

const WORK_AND_HOLIDAY_RETURN_DAY_MAXIMUN_RANGE = 364;

const FilterForm: React.FC<FilterFormProps> = (props) => {
  const {
    control,
    handleSubmit,
    onSubmit,
    errors,
    watch,
    setValue,
    categoriesList,
    getValues,
    applyCollaboratorPanelStyles,
    countries,
    isCountry,
  } = props;
  const filterDepartureDate = watch("departureDate");
  const filterCategory = watch("modality");
  const origin = watch("origin");
  const destination = watch("destination");
  const dispatch = useDispatch();
  const [listOrigin, setOriginList] = useState<any[]>(originList)
  const [listDestination, setDestinationList] = useState<any[]>([])
  const [filteredCategories, setFilteredCategories] = useState<any[]>(categoriesList);
  const [openModal, setOpenModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<any>("");
  const [searchParams] = useSearchParams();
  const categoryParams = searchParams.get("category");
  const { code }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);

  const { categoryMaximumRange }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.category
  );
  const { product }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );

  useEffect(() => {
    if (filterCategory.value) {
      const filterTitle = filterCategory?.label ?? null;
      setOriginList(getOriginCountry(setValue, filterCategory?.key, originList, watch('origin'), filterTitle));
      setDestinationList(getCountriesListSpanish(filterCategory?.key, watch('destination'), setValue, filterTitle));
      dispatch(getCategoryMaximumRange({ id: filterCategory.value }));

      if (ANNUAL_CATEGOIRES.includes((filterCategory.key))) {
        const returnDate = add(filterDepartureDate, {
          days: 364,
        });
        setValue('returnDate', returnDate);
      }
      if (filterCategory?.key === STUDENT_MEX) {
        if (watch('destination')?.code === CODE_MEXICO) {
          setValue("destination", { label: "Todo el mundo", value: "Todo el mundo", code: "WR" });
        }
      }
    }
  }, [dispatch, filterCategory.key, filterCategory?.label, filterCategory.value, filterDepartureDate, setValue, watch]);

  useEffect(() => {
    const returnDate = getValues("returnDate");

    if (filterCategory?.key === "AVIRISAnualMultiviaje") {
      let returnDate = add(filterDepartureDate, {
        days: 365,
      });
      setValue("returnDate", returnDate);
      return;
    }

    if (ANNUAL_CATEGOIRES.includes(filterCategory?.key)) {
      let returnDate = add(filterDepartureDate, {
        days: 364,
      });
      setValue("returnDate", returnDate);
      return;
    }

    if (MID_CATEGOIRES.includes(filterCategory?.key)) {
      setValue("returnDate", filterDepartureDate);
      return;
    }

    if (differenceInYears(returnDate, filterDepartureDate) >= 1) {
      let returnDate = add(filterDepartureDate, {
        days: 364,
      });

      setValue("returnDate", returnDate);
      return;
    }
    if (filterDepartureDate > returnDate) {
      setValue("returnDate", filterDepartureDate);
      return;
    }
  }, [filterCategory, setValue, filterDepartureDate, getValues]);

  useEffect(() => {
    const returnDate = getValues("returnDate");
    let rangeMax = categoryMaximumRange === 0 ? product?.maximumEndRange ?? 0 : categoryMaximumRange
    if (
      differenceInDays(returnDate, filterDepartureDate) + 1 >
      rangeMax
    ) {
      const newReturnDate = add(filterDepartureDate, {
        days: rangeMax,
      });
      setValue("returnDate", newReturnDate);
    }
  }, [filterCategory, filterDepartureDate, categoryMaximumRange, setValue, getValues, product?.maximumEndRange]);


  const handleCountryChange = (selectedCountry: any) => {
    if (!selectedCountry) return;
    setValue("origin", originList.find((country) => iso31661.whereAlpha2(country.code)?.alpha3 === selectedCountry.value) || origin);
    if (selectedCountry && selectedCountry?.codes && code) {
      const isExistRedirect = selectedCountry?.codes?.some((c: any) => c.label === code.codeKey);
      if (isExistRedirect && selectedCountry?.redirectUrl) {
        setRedirectUrl(selectedCountry.redirectUrl);
        setOpenModal(true);
      }
    }
  };

  const normalizeText = (text: string) => {
    return text
      .normalize("NFD")
      .replace(/[\u0301\u0308]/g, "")
      .toLowerCase();
  };

  const isSpain = (country: string) => {
    return country?.includes('espa') || country?.includes('esp');
  };

  const getRegion = (country: string, region?: string) => {
    if (isSpain(country)) return "españa";
    return normalizeText(region || 'mundo');
  };


  useEffect(() => {
    if (!categoriesList) return;
    const residence = getValues("country");
    const residenceValue =
      originList.find((country) => country.code === residence?.code) || residence;

    const originValue =
      originList.find((country) => country.code === getValues("origin")) || origin;

    const destinationValue =
      originList.find((country) => country.value === getValues("destination")) ||
      destination;

    let residenceCountry = (residenceValue?.value)?.toLowerCase();
    let originCountry = (originValue?.value)?.toLowerCase();
    let destinationCountry = (destinationValue?.value)?.toLowerCase();

    let residenceRegion = getRegion(residenceCountry, residenceValue?.region);
    let originRegion = getRegion(originCountry, originValue.region);
    let destinationRegion = getRegion(destinationCountry, destinationValue.region);

    const regionKeysOrigin = new Set();
    const regionKeysResidence = new Set();

    regionKeysOrigin.add(`${originRegion}-${destinationRegion}`);
    regionKeysOrigin.add(`${originRegion}-${destinationCountry}`);
    regionKeysOrigin.add(`${isSpain(originCountry) ? 'españa' : originCountry}-${destinationRegion}`);

    if (residenceValue) {
      regionKeysResidence.add(`${residenceRegion}-${destinationRegion}`);
    }

    let isNational = (originCountry.toLowerCase().includes(destinationCountry.toLowerCase()));
    let filtered = categoriesList.filter((category: any) => {
      return category.regions?.some((regionArray: any) => {
        return (regionKeysOrigin.has(regionArray.value))
      }
      )
    }
    );

    let filteredResidenve = categoriesList.filter((category: any) => {
      return category.regions?.some((regionArray: any) => {
        return (regionKeysResidence.has(regionArray.value))
      }
      )
    }
    )

    if (isNational) {
      filteredResidenve = categoriesList.filter((category: any) => {
        return category.regions?.some((regionArray: any) => {
          return (!category.isNotNational)
        }
        )
      }
      )
    }

    if (filteredResidenve.length > 0) {
      filtered = filteredResidenve ?? filtered;
    }

    if (code?.collaborator?._id?.includes(COLLABORATOR_TRAVEL)) {
      let filteredTerrawind = filtered?.filter((category: any) => (
        CATEGOIRES_TERRAWIND.includes(category.key)
      ));

      if (filteredTerrawind?.length > 0) {
        filtered = filteredTerrawind;
      }
    }

    if (filtered?.length > 0) {
      setFilteredCategories(filtered);
      if (filtered.length > 0) {
        const isCategory = filtered.find((category: any) => category.key === getValues('modality')?.key);
        if (isCategory) {
          setValue("modality", isCategory);
        }
        else {
          setValue("modality", filtered[0]);
        }
      }
    }

  }, [origin, destination, categoriesList, getValues("country"), getValues("origin")]);

  useEffect(() => {
    let countryParams = searchParams.get("country") ? searchParams.get("country")?.toLocaleUpperCase() : null;
    if (countryParams && countries) {
      const countryData = countries?.find(
        (c: any) => c.isoCode === countryParams
      );

      if (countryData && countryData?.codes && code) {
        const isExistRedirect = countryData?.codes?.some((c: any) => c.label === code.codeKey);
        if (isExistRedirect && countryData?.redirectUrl) {
          setRedirectUrl(countryData.redirectUrl);
          setOpenModal(true);
        }
      }
    }
  }, [code, countries, searchParams]);

  const filteredCountries = _countriesListSpanish
    ?.filter((country: any) => !["WR_EX_USA", "WR", "EU"]?.includes(country.code))
    ?.map(country => ({
      code: country.code,
      label: country.label
    }));

  const finalCountries = countries
    ?.map((country: any) => {
      const matchingCountry = filteredCountries?.find(
        (filtered) => iso31661.whereAlpha2(filtered.code)?.alpha3 === country.isoCode
      );
      return matchingCountry
        ? { value: country.isoCode, label: matchingCountry.label, ...country }
        : null;
    })
    ?.filter(Boolean);

  let maxDate = add(filterDepartureDate, {
    days:
      filterCategory?.key === WORKANDHOLIDAY || ANNUAL_CATEGOIRES.includes(filterCategory?.key)
        ? WORK_AND_HOLIDAY_RETURN_DAY_MAXIMUN_RANGE
        : filterCategory?.key === HEALTHINSURANCE ? HEALTH_INSURANCE_RETURN_DAY_MAXIMUM_RANGE
          : MID_CATEGOIRES.includes(filterCategory?.key) ? MID_MAXIMUM_RANGE : (categoryMaximumRange === 0 ? product?.maximumEndRange ?? 0 : categoryMaximumRange),
  });

  const modalityLabel = (MID_CATEGOIRES.includes(filterCategory?.key) || filterCategory?.key === 'MultiviajeAnual') ? 'Tipo de Plan' : 'modalidad';
  const modalityIsNotVisableCountry = (getValues('modality')) ? (isNotVisableCountry.includes(getValues('modality')?.key)) :
    (categoryParams) ? (isNotVisableCountry.includes(categoryParams)) : false;

  return (
    <form
      name="productForm"
      noValidate
      className="flex flex-col items-center justify-around w-full lg:items-start lg:flex-row"
      onSubmit={handleSubmit(onSubmit)}
    >
      <RedirectModal open={openModal} onClose={() => setOpenModal(false)} url={redirectUrl} country={getValues("country")} setOpenModal={setOpenModal} />
      <div className="flex flex-col w-full">
        <div className="flex flex-col items-center justify-around w-full lg:items-start lg:flex-row">
          {(isCountry && !modalityIsNotVisableCountry) && (<SelectAutoComplete
            name="country"
            label="Pais de residencia"
            control={control}
            variant="filled"
            className="w-full mb-2 lg:mb-0 lg:ml-2"
            list={finalCountries}
            size="small"
            disableClearable
            value={getValues('country') || originList.find((country: any) => iso31661.whereAlpha2(country.code)?.alpha3 === isCountry)}
            saveObject
            fullWidth
            onChangeCustom={handleCountryChange}
          />)
          }
          <SelectAutoComplete
            smallTextsInBigScreens={applyCollaboratorPanelStyles}
            saveObject
            label="origen"
            name="origin"
            control={control}
            variant="filled"
            fullWidth
            className="w-full mb-2 lg:mb-0 lg:ml-2"
            list={listOrigin}
            size="small"
            disableClearable
            groupBy={(option: any) => option.groupTitle}
          />
          <SelectAutoComplete
            smallTextsInBigScreens={applyCollaboratorPanelStyles}
            saveObject
            label="destino"
            name="destination"
            control={control}
            variant="filled"
            fullWidth
            className="w-full mb-2 lg:mb-0 lg:ml-2"
            list={listDestination}
            size="small"
            disableClearable
            groupBy={(option: any) => option.groupTitle}
          />
          <SelectAutoComplete
            smallTextsInBigScreens={applyCollaboratorPanelStyles}
            label={modalityLabel}
            name="modality"
            control={control}
            variant="filled"
            fullWidth
            className="w-full mb-2 lg:mb-0 lg:ml-2"
            list={filteredCategories}
            size="small"
            disableClearable
            saveObject
          />
          <DatePicker
            smallTextsInBigScreens={applyCollaboratorPanelStyles}
            name="departureDate"
            control={control}
            variant="filled"
            fullWidth={true}
            className="w-full mb-2 lg:mb-0 lg:ml-2"
            size="small"
            label="Fecha de salida"
            minDate={new Date()}
            disableTextField
          />
          <DatePicker
            smallTextsInBigScreens={applyCollaboratorPanelStyles}
            name="returnDate"
            control={control}
            variant="filled"
            fullWidth={true}
            error={!!errors.returnDate}
            errorMessage={errors?.returnDate?.message}
            className="w-full mb-2 lg:mb-0 lg:ml-2"
            size="small"
            label="Fecha de retorno"
            minDate={filterDepartureDate}
            maxDate={maxDate}
            disabled={
              ANNUAL_CATEGOIRES.includes(filterCategory?.key) ||
              filterCategory?.key === "AVIRISAnualMultiviaje"
            }
            disableTextField
          />
          <SelectAutoComplete
            smallTextsInBigScreens={applyCollaboratorPanelStyles}
            label="viajeros"
            name="totalTravellers"
            control={control}
            variant="filled"
            fullWidth
            className="w-full mb-2 lg:mb-0 lg:ml-2"
            list={travellerList}
            size="small"
            disableClearable
            labelCenter
          />
        </div>
        <div className="flex justify-center mx-auto my-2">
          <Input
            label="Viajero en destino"
            name="userInDestination"
            control={control}
            fullWidth
            className="w-full mb-2 text-sm lg:mb-0 lg:ml-2"
            error={!!errors.userInDestination}
            errorMessage={errors?.userInDestination?.message}
            size="small"
            type="checkbox"
          />
        </div>
      </div>
      <Button
        className="w-40 mt-6 lg:ml-2 whitespace-nowrap lg:min-w-max lg:mt-0 lg:py-3"
        type="submit"
        style={{ height: applyCollaboratorPanelStyles ? "38.8px" : "auto" }} //Normal className is not working, rem magnitude isn't working neither
      >
        Dame el mejor precio
      </Button>
    </form>
  );
};

export default FilterForm;
