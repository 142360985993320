
import { useEffect } from "react";
import { Outlet } from "react-router";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Theme from "Theme";
import { ToastContainer } from "react-toastify";

const App = () => { 
  if (process.env.REACT_APP_NODE_ENV === "production") {
    ReactGA.initialize("G-10JJ2GXWXR");
    ReactGA.send("pageview");
  }
  
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === "production") {
      (function (c: any, l: any, a: any, r: any, i: any, t: any, y: any) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(
        window,
        document,
        "clarity",
        "script",
        "g5cgkk1sxs",
        undefined,
        undefined
      );
    }
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Theme>
       <Outlet />
      </Theme>
    </div>
  );
};

export default App;
